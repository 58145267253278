import { Divider, Grid, MenuItem, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  COMPANY_CATEGORY_CORPORATION,
  COMPANY_CATEGORY_NON_PROFIT,
} from "../../assets/constant";
import {
  CompanyTypeAry,
  CompanyTypeList,
} from "../../assets/Data/CompanyTypeList";
import _ from "lodash";

export default function CompanyTypeSection({
  category = COMPANY_CATEGORY_CORPORATION,
  stock = 0,
  setStock = false,
  sales = 0,
  setSales = false,
  capital = 0,
  setCapital = false,
  employee = 0,
  setEmployee = false,
  establish = 0,
  setEstablish = false,
  readOnly = false,
}) {
  const [typeList, setTypeList] = useState([]);

  useEffect(() => {
    let tempList = [];
    if (category === COMPANY_CATEGORY_CORPORATION) {
      tempList = [
        {
          category_id: 2,
          category: "上場",
          selectedVal: CompanyTypeAry[stock],
        },
        {
          category_id: 3,
          category:
            category === COMPANY_CATEGORY_NON_PROFIT ? "収入額/年" : "売上高（概算）",
          selectedVal: CompanyTypeAry[sales],
        },
       
      ];
    } else {
      //個人事業主 和 公的法人・非営利法人 無 上場、資本金兩項
      //setStock(-1);
      //setCapital(-1);

      // Kevin tempList 移除了 "資本金"  "職員数","従業員数","設立年", 因為這三項已改成非下拉式選單
      tempList = [
        {
          category_id: 2,
          category: "上場",
          selectedVal: CompanyTypeAry[stock],
        },
        {
          category_id: 3,
          category:
            category === COMPANY_CATEGORY_NON_PROFIT ? "売上高（概算）" : "売上高（概算）",
          selectedVal: CompanyTypeAry[sales],
        },
      ];
    }
    setTypeList([...tempList]);
  }, [category]);

  const setSelectedValue = (categoryId, selectedVal) => {
    const tempList = typeList.map((type) =>
      type.category_id === categoryId ? { ...type, selectedVal } : type
    );
    setTypeList([...tempList]);
    switch (categoryId) {
      case 2:
        setStock(CompanyTypeAry.indexOf(selectedVal));
        break;
      case 3:
        setSales(CompanyTypeAry.indexOf(selectedVal));
        break;
      case 4:
        setCapital(CompanyTypeAry.indexOf(selectedVal));
        break;
      case 5:
        setEmployee(CompanyTypeAry.indexOf(selectedVal));
        break;
      case 6:
        setEstablish(CompanyTypeAry.indexOf(selectedVal));
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Divider variant="middle" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <p style={{ fontSize: 22 }}>企業情報詳細</p>
      </Grid>

      {typeList.map((type) => (
        <Grid item xs={12} sm={6} key={type.category_id}>
          <TextField
            disabled={readOnly}
            select
            label={type.category}
            value={type.selectedVal}
            defaultValue={type.selectedVal}
            onChange={(e) => setSelectedValue(type.category_id, e.target.value)}
            variant="outlined"
            size="small"
            fullWidth
          >
            {_.filter(CompanyTypeList, { category_id: type.category_id }).map(
              (option) => (
                <MenuItem key={option.id} value={option.title}>
                  {option.title}
                </MenuItem>
              )
            )}
          </TextField>
        </Grid>
      ))}

     
    </>
  );
}
