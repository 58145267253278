import React from "react";
import { SvgIcon, ListSubheader, List, ListItem, ListItemIcon, ListItemText, styled } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const SideMenu = styled(List)(({ theme }) => ({
  width: "100%",
  maxWidth: 208,
  [theme.breakpoints.down(1005)]: {
    width: 45,
  },
}));

const SideMenuListSubheader = styled(ListSubheader)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 700,
  paddingLeft: 0,
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  transition: "0.3s",
  background: "transparent",
  [theme.breakpoints.down(1005)]: {
    textAlign: "center",
  },
}));

const SideMenuListItem = styled(ListItem)(({ theme }) => ({
  marginBottom: 16,
  padding: 8,
  borderRadius: 30,
  transition: "0.3s",
  height: 40,
  "&:hover": {
    backgroundColor: "white",
    padding: "8px 0px 8px 12px",
    boxShadow: " 0 1px 16px 0 rgba(30, 120, 230, 0.08), 0 2px 4px 0 rgba(51, 51, 51, 0.12)",
  },
  "& .MuiListItemIcon-root": {
    minWidth: 24,
    marginRight: 8,
  },
  "& .MuiListItemText-root  .MuiTypography-root": {
    fontSize: 14,
  },
  "&.Mui-selected": {
    padding: "8px 0px 8px 12px",

    backgroundImage: "linear-gradient(101deg,#ffc363,#ffa540 50%,#ff9e46)",
    boxShadow: " 0 1px 16px 0 rgba(30, 120, 230, 0.08), 0 2px 4px 0 rgba(51, 51, 51, 0.12)",
    "& .MuiListItemIcon-root > svg": {
      fill: "white",
    },
    "& .MuiListItemText-root": {
      transition: "0.3s",
      color: "white",
    },
  },

  [theme.breakpoints.down(1005)]: {
    width: 45,
    "& .MuiListItemText-root": {
      display: "none",
    },
  },
}));
export default function BizApoSideMenuList({ menuList, defalutIndex = 0 }) {
  // const [selectedItem, setSelectedItem] = useState(defalutIndex);
  const history = useHistory();
  const location = useLocation();

  const handleClick = ({ id, url }) => {
    //如果是一般連結，就另外開啟
    if (url.startsWith("https")) window.open(url);
    else history.push(url);
  };

  return menuList.map((menu, index) => (
    <SideMenu
      key={index}
      component="nav"
      subheader={
        <SideMenuListSubheader key={menu.title} component="div">
          {menu.title}
        </SideMenuListSubheader>
      }
    >
      {menu.item.map((menuItem) => (
        <SideMenuListItem key={menuItem.id} button onClick={() => handleClick(menuItem)} selected={menuItem.url === location.pathname}>
          <ListItemIcon>
            <SvgIcon component={menuItem.icon} style={{ fontSize: 24, color: "#333333" }} />
          </ListItemIcon>
          <ListItemText primary={menuItem.text} />
        </SideMenuListItem>
      ))}
    </SideMenu>
  ));
}
