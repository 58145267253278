import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  openSnackbar: false,
  infoText: "",
  alertType: "success", // error, warning, info, success
};

const { actions, reducer: AlertInfoReducer } = createSlice({
  name: "alertInfo",
  initialState,
  reducers: {
    setOpenSnackbar(state, action) {
      state.openSnackbar = action.payload;
    },
    showAlert(state, action) {
      state.openSnackbar = true;
      state.infoText = action.payload.infoText;
      state.alertType = action.payload.alertType;
    },
  },
});

export const { setOpenSnackbar, showAlert } = actions;
export const alertInfo = (state) => state.alertInfo;
export default AlertInfoReducer;
