import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import { useDispatch } from "react-redux";
import { setServiceCompletedFlag } from "../../redux/slice/userInfo.slice";

export default function FirstTimeSaveConfirmDialog({ open, setOpen }) {
  const history = useHistory();
  const { serviceCompletedFlag } = useGetUserInfo();
  const dispatch = useDispatch();
  const handleColose = () => {
    setOpen(false);
    dispatch(setServiceCompletedFlag);
    //倘若 事業內容  尚未填寫 、而且在 自社prfile功能中 則引導至該頁面
    if (
      history.location.pathname === "/company_profile/info" &&
      !serviceCompletedFlag
    )
      history.push("/company_profile/service");
  };
  return (
    <Dialog open={open} onClose={handleColose}>
      <DialogContent>
        BizApoはあなたの会社を見つけたい企業にアピールすることができます。
        <br />
        事業内容を登録してみましょう。
        <Box sx={{ mt: 1, mb: 1 }}>
          事業内容を入力の上、保存ボタンをクリックして下さい。
        </Box>
        <Typography variant="h6" sx={{ color: "red", mt: 2, mb: 1 }}>
          ポイント！
        </Typography>
        上限4ページのPowerPoint(PPTX)が添付可能です。
        <br />
        あなたの会社を上手にアピールしましょう。
      </DialogContent>
      <DialogActions>
        <Button onClick={handleColose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
