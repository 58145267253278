import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Box,
  IconButton,
  Grid,
} from "@mui/material";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

import BizApoInfoCard from "../../../../components/BizApoInfoCard";

import { Storage } from "aws-amplify";
import { format } from "date-fns";
import { OccupationAry } from "../../../../assets/Data/OccupationList";
import { IndustryAry } from "../../../../assets/Data/IndustryList";
import _ from "lodash";
import { useGetUserInfo } from "../../../../hooks/useGetUserInfo";
import ApoPublishInfo from "../../../ApoRelease/step3/ApoPublishInfo";
import { BizApoDialog1 } from "../../../../components/biz-apo-dialog/BizApoDialog1";
import FollowButton from "../../../../components/biz-apo-button/FollowButton";

export default function ApplyInfoDialog({
  reapply = false,
  open = false,
  setOpen,
  showFavoriteButton = false,
  apoData = {},
}) {
  const [selectedDateId, setselectedDateId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const history = useHistory();
  const [avatarUrl, setAvatarUrl] = useState();
  const [apoInfo, setApoInfo] = useState([]);
  const [contactInfo, setContactInfo] = useState([]);
  const { userToken, managerId } = useGetUserInfo();
  const [scheduleDatetimeList, setScheduleDatetimeList] = useState([]);
  const [favoriteApoId, setFavoriteApoId] = useState("");

  useEffect(() => {
    if (!_.isEmpty(apoData)) {
      const tempApoInfo = [
        { id: 1, title: "会社名", value: apoData.companyName || "" },
        {
          id: 3,
          title: "業種分類",
          value: IndustryAry[apoData.manager.corpProfile.industryId || 0],
        },
        {
          id: 4,
          title: "職種分類",
          value: OccupationAry[apoData.manager.jobNameId || 0],
        },
      ];
      const tempContactInfo = [
        {
          id: 1,
          title: "ご担当者様",
          value: apoData.manager.name || "",
        },
        {
          id: 2,
          title: "部署名",
          value: apoData.manager.departmentName || "",
        },
      ];
      const tempSchedule = [];
      const scheduleStartList = [
        "scheduleStart1",
        "scheduleStart2",
        "scheduleStart3",
        "scheduleStart4",
        "scheduleStart5",
      ];
      const scheduleEndList = [
        "scheduleEnd1",
        "scheduleEnd2",
        "scheduleEnd3",
        "scheduleEnd4",
        "scheduleEnd5",
      ];
      scheduleStartList.map(
        (item, index) =>
          apoData[scheduleStartList[index]] &&
          tempSchedule.push({
            id: index,
            scheduleStart: apoData[scheduleStartList[index]],
            scheduleEnd: apoData[scheduleEndList[index]],
            text: `${format(
              new Date(apoData[scheduleStartList[index]]),
              "yyyy/MM/dd HH:mm"
            )} ~ ${format(new Date(apoData[scheduleEndList[index]]), "HH:mm")}`,
            expired: new Date() > new Date(apoData[scheduleStartList[index]]),
          })
      );
      setApoInfo([...tempApoInfo]);
      setContactInfo([...tempContactInfo]);
      setScheduleDatetimeList([...tempSchedule]);
      apoData.favorite?.length > 0
        ? apoData.favorite.forEach((favorite) =>
            favorite.type === "appointment"
              ? setFavoriteApoId(favorite.id)
              : setFavoriteApoId("")
          )
        : setFavoriteApoId("");
      apoData.manager.imgUrl && getAvatar();
    }
  }, [apoData]);

  const handleClose = () => {
    setOpen(false);
  };

  const selectDate = (date, id) => {
    setselectedDateId(id);
    setSelectedDate(date);
  };

  const getAvatar = async () => {
    await Storage.get(apoData.manager.imgUrl).then((res) => {
      setAvatarUrl(res);
    });
  };
  return (
    <BizApoDialog1 open={open} onClose={handleClose} maxWidth="lg">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: 3,
          paddingBottom: 0,
        }}
      >
        <Typography variant="h3">
          {reapply ? "アポ再申請" : "アポ情報"}
        </Typography>
        <IconButton onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogTitle>
        申請先の要望をご確認の上、日時を一つ選択し、アポ申請をお願いします。
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="flex-start"
          alignContent="center"
        >
          <Grid item xs={12} md={6}>
            <BizApoInfoCard
              title={"アポ申請先"}
              infoList={apoInfo}
              // img={avatarUrl}
            />
            <br />

            <BizApoInfoCard title={"担当者"} infoList={contactInfo} />

            <br />
            <BizApoInfoCard title={"日付時間"}>
              {scheduleDatetimeList.map((schedule, index) => (
                <Button
                  key={index}
                  onClick={() => selectDate(schedule, index)}
                  variant="green"
                  className={index === selectedDateId ? "active" : null}
                  sx={{ display: "block", margin: "6px 0px" }}
                  disabled={schedule.expired}
                >
                  {schedule.text}
                </Button>
              ))}
            </BizApoInfoCard>
          </Grid>
          <Grid item xs={12} md={6}>
            {/* 因為此ui 與APO公開 共用 ，以傳值的方式呈現內容 */}
            <ApoPublishInfo
              industry={apoData.industry || []}
              jobName={apoData.jobName || []}
              contactMethod={apoData.contactMethod || 0}
              contactDetail={apoData.contactDetail || ""}
              preferStock={apoData.preferStock || []}
              preferSales={apoData.preferSales || []}
              preferCapital={apoData.preferCapital || []}
              preferEmployee={apoData.preferEmployee || []}
              preferEstablish={apoData.preferEstablish || []}
              preferCategory={apoData.preferCategory || []}
              target={apoData.target || []}
              targetOther={apoData.targetOther || ""}
              keyword={apoData.keyword || ""}
              title={"アポ申請先の要望"}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        {showFavoriteButton && (
          <FollowButton
            managerId={managerId}
            userToken={userToken}
            customerManagerId={apoData.managerId}
            followId={apoData.follow || ""}
          />
        )}

        <Button
          variant="primary"
          disabled={!selectedDate}
          onClick={
            () =>
              history.push("/apply_form", {
                selectedApoData: apoData,
                selectedDate: selectedDate,
              }) //傳送要申請的這筆apo資料
          }
        >
          {reapply ? "アポ再申請" : " アポ申請"}
        </Button>

        <Button variant="third" onClick={handleClose}>
          キャンセル
        </Button>
      </DialogActions>
    </BizApoDialog1>
  );
}
