import {
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material";
import { Button, Grid, TextField } from "@mui/material";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
/* Kevin remove mobiscroll*/
import { Datepicker, localeJa, setOptions } from "@mobiscroll/react";
import "@mobiscroll/react/dist/css/mobiscroll.min.css";


import { add, differenceInMinutes, format } from "date-fns";
/* Kevin remove mobiscroll*/
setOptions({
  locale: localeJa,
  theme: "ios",
});

export default function BizApoDatetimeSelecting({
  title = false,
  scheduleList,
  setScheduleList,
}) {
  //現在開啟 的組數
  const [enabledCount, setEnabledCount] = useState(
    scheduleList.filter((item) => item.enable).length - 1
  );

  useEffect(() => {
    //指定的apo時間，要設定在 現在時間之後，
    // 不過，如果以往設定的apo時間（從後端取回）後早於現在時間，
    // 在操作datepicker時就會異常，也不符合實際需求，
    // 因此，先判斷過去設定的apo時間是否晚於現在時間（加1hr 以避免異常發生）
    let plusOneHourDatetime = new Date(); //for startDatetime
    plusOneHourDatetime.setHours(plusOneHourDatetime.getHours() + 1);
    let plusTwoHoursDatetime = new Date(); // for endDatetime
    plusTwoHoursDatetime.setHours(plusOneHourDatetime.getHours() + 2);

    let tempScheduleLIst = scheduleList.map((item) => {
      if (plusOneHourDatetime > item.startDatetime)
        return {
          ...item,
          startDatetime: plusOneHourDatetime,
          endDatetime: plusTwoHoursDatetime,
          period: "01:00",
        };
      else return { ...item };
    });

    setScheduleList([...tempScheduleLIst]);
  }, []);

  const handleSetSchedule = (_index, item, val) => {
    let tempList = scheduleList;
    tempList[_index][item] = val;
    tempList[_index]["endDatetime"] = add(tempList[_index]["startDatetime"], {
      hours: tempList[_index]["period"]?.split(":")[0],
      minutes: tempList[_index]["period"]?.split(":")[1],
    });
    setScheduleList([...tempList]);
  };

  const removeSchedule = (_index) => {
    let tempList = scheduleList;
    tempList[_index]["startDatetime"] = "";
    tempList[_index]["period"] = "";
    tempList[_index]["enable"] = false;
    setScheduleList([...tempList]);
  };

  //新增一組時間，設定period預設值
  const addSchedule = (_index) => {
    let tempDate = new Date();
    tempDate.setHours(tempDate.getHours() + 1);
    let tempList = scheduleList;
    tempList[_index]["startDatetime"] = tempDate;
    tempList[_index]["period"] = "00:30";
    tempList[_index]["enable"] = true;
    setScheduleList([...tempList]);
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {title && (
          <Grid item xs={12}>
            {title}
          </Grid>
        )}

        <Grid item xs={12} sm={6}>
          <Typography variant="h5">日付時間：</Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {enabledCount !== 4 ? (
            <Button
              variant="text"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => {
                setEnabledCount(enabledCount + 1);
                addSchedule(enabledCount + 1);
              }}
              size="large"
            >
              日時を追加
            </Button>
          ) : (
            <span style={{ marginRight: 133.5 }}></span>
          )}

          {enabledCount > 0 && (
            <Button
              variant="text"
              color="primary"
              startIcon={<RemoveIcon />}
              onClick={() => {
                setEnabledCount(enabledCount - 1);
                removeSchedule(enabledCount);
              }}
              size="large"
            >
              日時を削除
            </Button>
          )}
        </Grid>

        {Array.from(Array(enabledCount + 1), (e, _index) => (
          <Fragment key={_index}>
            <Grid item xs={12} sm={2} sx={{ textAlign: "center" }}>
              <Typography variant="h6">
                {scheduleList[_index]["title"]}
              </Typography>
            </Grid>
            <Grid item xs={8} sm={6}>
              <Datepicker
                theme="ios"
                label="開始日時"
                controls={["calendar", "time"]}
                touchUi={false}
                dateFormat="YYYY/MM/DD"
                stepMinute={15}
                value={scheduleList[_index]["startDatetime"]}
                onChange={(e) =>
                  handleSetSchedule(_index, "startDatetime", e.value)
                }
                themeVariant="light"
                min={new Date().toISOString()}
              />
              
            </Grid>

            <Grid item xs={4} sm={4}>
              <TextField
                label="面談時間"
                value={scheduleList[_index].period}
                onChange={(e) =>
                  handleSetSchedule(_index, "period", e.target.value)
                }
                select
                size="small"
                sx={{ width: 108 }}
              >
                {periodList.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Fragment>
        ))}
      </Grid>
    </div>
  );
}

// let x = 0;
// const intervalList = Array.from(Array(33), (e, i) => {
//   return parseInt((x + i * 15) / 60) + ":" + ((x + i * 15) % 60);
// });
// console.log("intervalList:", intervalList);
const periodList = [
  "00:15",
  "00:30",
  "00:45",
  "01:00",
  // "1:15",
  // "1:30",
  // "1:45",
  // "2:00",
  // "2:15",
  // "2:30",
  // "2:45",
  // "3:00",
  // "3:15",
  // "3:30",
  // "3:45",
  // "4:00",
  // "4:15",
  // "4:30",
  // "4:45",
  // "5:00",
  // "5:15",
  // "5:30",
  // "5:45",
  // "6:00",
  // "6:15",
  // "6:30",
  // "6:45",
  // "7:00",
  // "7:15",
  // "7:30",
  // "7:45",
  // "8:00",
];

export const periodObjs = {
  15: "00:15",
  30: "00:30",
  45: "00:45",
  60: "01:00",
};

//資料庫取出的datetime為iso格式，需用 new Date()進行轉換
export const getScheduleDatetimeList = ({
  scheduleStart1,
  scheduleEnd1,
  scheduleStart2,
  scheduleEnd2,
  scheduleStart3,
  scheduleEnd3,
  scheduleStart4,
  scheduleEnd4,
  scheduleStart5,
  scheduleEnd5,
}) => [
  {
    id: 1,
    title: "第一希望",
    startDatetime: scheduleStart1 ? new Date(scheduleStart1) : new Date(),
    endDatetime: scheduleEnd1 ? new Date(scheduleEnd1) : new Date(),
    period: scheduleEnd1
      ? periodObjs[
          differenceInMinutes(new Date(scheduleEnd1), new Date(scheduleStart1))
        ]
      : "00:30",
    enable: true,
  },
  {
    id: 2,
    title: "第二希望",
    startDatetime: scheduleStart2 ? new Date(scheduleStart2) : new Date(),
    endDatetime: scheduleEnd2 ? new Date(scheduleEnd2) : new Date(),
    period: scheduleStart2
      ? periodObjs[
          differenceInMinutes(new Date(scheduleEnd2), new Date(scheduleStart2))
        ]
      : "00:30",
    enable: Boolean(scheduleStart2) || Boolean(scheduleEnd2),
  },
  {
    id: 3,
    title: "第三希望",
    startDatetime: new Date(scheduleStart3 || ""),
    endDatetime: new Date(scheduleEnd3 || ""),
    period:
      periodObjs[
        differenceInMinutes(
          new Date(scheduleEnd3 || ""),
          new Date(scheduleStart3 || "")
        )
      ],
    enable: Boolean(scheduleStart3) || Boolean(scheduleEnd3),
  },
  {
    id: 4,
    title: "第四希望",
    startDatetime: new Date(scheduleStart4 || ""),
    endDatetime: new Date(scheduleEnd4 || ""),
    period:
      periodObjs[
        differenceInMinutes(
          new Date(scheduleEnd4 || ""),
          new Date(scheduleStart4 || "")
        )
      ],
    enable: Boolean(scheduleStart4) || Boolean(scheduleEnd4),
  },
  {
    id: 5,
    title: "第五希望",
    startDatetime: new Date(scheduleStart5 || ""),
    endDatetime: new Date(scheduleEnd5 || ""),
    period:
      periodObjs[
        differenceInMinutes(
          new Date(scheduleEnd5 || ""),
          new Date(scheduleStart5 || "")
        )
      ],
    enable: Boolean(scheduleStart5) || Boolean(scheduleEnd5),
  },
];

export const initScheduleDatetimeList = [
  {
    id: 1,
    title: "第一希望",
    startDatetime: new Date(),
    endDatetime: new Date(),
    period: "00:30",
    enable: true,
  },
  {
    id: 2,
    title: "第二希望",
    startDatetime: new Date(),
    endDatetime: new Date(),
    period: "00:30",
    enable: false,
  },
  {
    id: 3,
    title: "第三希望",
    startDatetime: new Date(),
    endDatetime: new Date(),
    period: "00:30",
    enable: false,
  },
  {
    id: 4,
    title: "第四希望",
    startDatetime: new Date(),
    endDatetime: new Date(),
    period: "00:30",
    enable: false,
  },
  {
    id: 5,
    title: "第五希望",
    startDatetime: new Date(),
    endDatetime: new Date(),
    period: "00:30",
    enable: false,
  },
];

export const initScheduleDatetimeList2 = [
  {
    id: 1,
    title: "第一希望",
    startDatetime: "",
    endDatetime: "",
    period: "00:00",
    enable: false,
  },
  {
    id: 2,
    title: "第二希望",
    startDatetime: "",
    endDatetime: "",
    period: "00:00",
    enable: false,
  },
  {
    id: 3,
    title: "第三希望",
    startDatetime: "",
    endDatetime: "",
    period: "00:00",
    enable: false,
  },
  {
    id: 4,
    title: "第四希望",
    startDatetime: "",
    endDatetime: "",
    period: "00:00",
    enable: false,
  },
  {
    id: 5,
    title: "第五希望",
    startDatetime: "",
    endDatetime: "",
    period: "00:00",
    enable: false,
  },
];

export const DisplaySchedule = ({ datetimeList, title = "日付時間：" }) => {
  return (
    <List>
      {title && (
        <ListItem>
          <Typography variant="h5">{title}</Typography>
        </ListItem>
      )}

      {datetimeList.map(
        (schedule, index) =>
          schedule.enable && (
            <ListItem key={index}>
              <ListItemText
                primary={schedule["title"]}
                secondary={`${format(
                  schedule["startDatetime"],
                  "yyyy/MM/dd HH:mm"
                )} ~ ${format(schedule["endDatetime"], "HH:mm")}`}
                primaryTypographyProps={{
                  fontSize: 16,
                  lineHeight: "24px",
                  color: "rgba(51, 51, 51, 0.5)",
                }}
                secondaryTypographyProps={{
                  fontSize: 16,
                  fontWeight: "600",
                  lineHeight: "24px",
                  color: "#333333",
                }}
              />
            </ListItem>
          )
      )}
    </List>
  );
};

export const DisplaySchedule2 = ({ datetimeList }) => {
  return (
    <List>
      {datetimeList &&
        datetimeList.map((schedule, index) => (
          <ListItem key={index}>
            <ListItemText
              primary={schedule["title"]}
              secondary={schedule["datetime"]}
              primaryTypographyProps={{
                fontSize: 16,
                lineHeight: "24px",
                color: "rgba(51, 51, 51, 0.5)",
              }}
              secondaryTypographyProps={{
                fontSize: 16,
                fontWeight: "600",
                lineHeight: "24px",
                color: "#333333",
              }}
            />
          </ListItem>
        ))}
    </List>
  );
};

const scheduleTitleList = [
  "第一希望",
  "第二希望",
  "第三希望",
  "第四希望",
  "第五希望",
];

export const getScheduleList = (_data) => {
  const scheduleList = [];
  const scheduleStartList = [
    "scheduleStart1",
    "scheduleStart2",
    "scheduleStart3",
    "scheduleStart4",
    "scheduleStart5",
  ];
  const scheduleEndList = [
    "scheduleEnd1",
    "scheduleEnd2",
    "scheduleEnd3",
    "scheduleEnd4",
    "scheduleEnd5",
  ];
  scheduleStartList.map(
    (item, index) =>
      _data[scheduleStartList[index]] &&
      scheduleList.push({
        id: index,
        title: scheduleTitleList[index],
        scheduleStart: _data[scheduleStartList[index]],
        scheduleEnd: _data[scheduleEndList[index]],
        datetime: `${format(
          new Date(_data[scheduleStartList[index]]),
          "yyyy/MM/dd HH:mm"
        )} ~ ${format(new Date(_data[scheduleEndList[index]]), "HH:mm")}`,
      })
  );
  return scheduleList;
};

export const getRescheduleList = (_data) => {
  const rescheduleList = [];
  const rescheduleStartList = [
    "rescheduleStart1",
    "rescheduleStart2",
    "rescheduleStart3",
    "rescheduleStart4",
    "rescheduleStart5",
  ];
  const rescheduleEndList = [
    "rescheduleEnd1",
    "rescheduleEnd2",
    "rescheduleEnd3",
    "rescheduleEnd4",
    "rescheduleEnd5",
  ];
  rescheduleStartList.map(
    (item, index) =>
      _data[rescheduleStartList[index]] &&
      rescheduleList.push({
        id: index,
        title: scheduleTitleList[index],
        rescheduleStart: _data[rescheduleStartList[index]],
        rescheduleEnd: _data[rescheduleEndList[index]],

        datetime: `${format(
          new Date(_data[rescheduleStartList[index]] || ""),
          "yyyy/MM/dd HH:mm"
        )} ~ ${format(
          new Date(_data[rescheduleEndList[index]] || ""),
          "HH:mm"
        )}`,
      })
  );
  return rescheduleList;
};
