import { createTheme } from "@mui/material";
import { jaJP } from "@mui/material/locale";
import { jaJP as dgJaJP } from "@mui/x-data-grid-pro";

export const BizApoMuiTheme = createTheme(
  {
    palette: {
      primary: { main: "#1e78e6" },
      secondary: { main: "#ff9e46" },
      info: { main: "#e91e63" },
    },
    typography: {
      fontFamily: ["YakuHanJP", "Hiragino Sans", "YuGothic", " Hiragino Kaku Gothic ProN", "ryo", "sans-serif"].join(","),
      fontSize: 16,
      h1: {
        fontSize: 13,
        fontWeight: 600,
        lineHeight: "24px",
        color: "rgba(51, 51, 51, 0.5)",
        marginLeft: "1vw",
      },
      h3: {
        fontSize: 22,
        lineHeight: "40px",
        color: "#1e78e6",
        fontWeight: 600,
      },
      h4: {
        fontSize: 18,
        color: "#1e78e6",
        fontWeight: 600,
        lineHeight: "30px",
      },
      h5: {
        fontSize: 16,
        lineHeight: "24px",
        color: "rgba(51, 51, 51, 0.5)",
      },
      h6: {
        fontSize: 16,
        fontWeight: "600",
        lineHeight: "24px",
        color: "#333333",
      },
      overrides: {},
    },
    components: {
      MuiButton: {
        variants: [
          { props: { variant: "text" }, style: { textTransform: "none" } },
          {
            props: { variant: "primary" },
            style: {
              textTransform: "none",
              color: "white",
              fontSize: 14,
              fontWeight: "bold",
              letterSpacing: 0.2,
              background: "#008BE8",
              borderRadius: 100,
              padding: "4px 16px",
              "&:hover": {
                background: "#0068C3",
              },
              "&:disabled": {
                background: "#DBDDE0",
                color: "white",
              },
            },
          },
          {
            props: { variant: "secondary" },
            style: {
              textTransform: "none",
              color: "#ff9e46",
              border: "1px solid #ff9e46",

              fontSize: 14,
              fontWeight: "bold",
              letterSpacing: 0.2,
              background: "white",
              borderRadius: 100,
              padding: "3px 16px",
              "&:hover": {
                background: "#ff9e46",
                color: "white",
              },
              "&:disabled": {
                background: "#DBDDE0",
                color: "white",
                border: "none",
              },
            },
          },
          {
            props: { variant: "third" },
            style: {
              textTransform: "none",
              color: "#008BE8",
              fontSize: 14,
              fontWeight: "bold",
              letterSpacing: 0.2,
              background: "#E3F2FD",
              borderRadius: 100,
              padding: "4px 16px",
              "&:hover": {
                background: "#BADFFC",
              },
              "&:disabled": {
                background: "#DBDDE0",
                color: "white",
              },
            },
          },
          {
            props: { variant: "fourth" },
            style: {
              textTransform: "none",
              color: "#008BE8",
              fontSize: 14,
              fontWeight: "bold",
              letterSpacing: 0.2,
              background: "white",
              borderRadius: 100,
              padding: "4px 16px",
              border: `1px solid ${"#008BE8"}`,
              "&:hover": {
                background: "#E3F2FD",
              },
              "&:disabled": {
                background: "#DBDDE0",
                color: "white",
              },
            },
          },
          {
            props: { variant: "green" },
            style: {
              textTransform: "none",
              color: "#008BE8",
              fontSize: 14,
              fontWeight: "bold",
              letterSpacing: 0.2,
              background: "white",
              borderRadius: 100,
              padding: "4px 16px",
              "&:hover": {
                background: "white",
                color: "rgba(51, 51, 51, 0.75)",
                boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 2px 4px 0 rgba(0, 0, 0, 0.2) ",
              },
              "&:disabled": {
                background: "#DBDDE0",
                color: "white",
              },
              "&:active, &.active": {
                backgroundColor: "#2a9d8f !important",
                color: "white !important",
              },
            },
          },
          {
            props: { variant: "gray" },
            style: {
              textTransform: "none",
              color: "white",
              fontSize: 14,
              fontWeight: "bold",
              letterSpacing: 0.2,
              background: "#A0A4A8",
              borderRadius: 100,
              padding: "4px 16px",
              "&:hover": {
                background: "#52575C",
              },
              "&:disabled": {
                color: "white",
              },
            },
          },
          {
            props: { variant: "orange" },
            style: {
              textTransform: "none",
              background: "#ff9e46",

              border: "1px solid white",

              fontSize: 14,
              fontWeight: "bold",
              letterSpacing: 0.2,
              color: "white",
              borderRadius: 100,
              padding: "3px 16px",
              "&:hover": {
                background: "white",
                color: "#ff9e46",
                border: "1px solid #ff9e46",
              },
              "&:disabled": {
                background: "#DBDDE0",
                color: "white",
                border: "none",
              },
            },
          },
          {
            props: { variant: "error" },
            style: {
              textTransform: "none",
              background: "#ef9a9a",

              border: "1px solid white",

              fontSize: 14,
              fontWeight: "bold",
              letterSpacing: 0.2,
              color: "white",
              borderRadius: 100,
              padding: "3px 16px",
              "&:hover": {
                background: "#ba000d",
                color: "white",
                border: "1px solid #ba000d",
              },
              "&:disabled": {
                background: "#DBDDE0",
                color: "white",
                border: "none",
              },
            },
          },
        ],
      },
    },
  },
  jaJP,
  dgJaJP
);
