import React, { useEffect, useState } from "react";
import { Typography, Paper, Avatar, styled } from "@mui/material";
import { Storage } from "aws-amplify";

const InfoPaper = styled(Paper)(({ theme }) => ({
  position: "relative",
  width: "100%",
  maxWidth: 600,
  // padding: "8px 26px",
  overflow: "hidden",
  transition: "0.3s",
  padding: "3%",
  margin: "0px auto",
  borderRadius: 8,
  boxShadow:
    "0 1px 16px 0 rgba(30, 120, 230, 0.08), 0 2px 4px 0 rgba(51, 51, 51, 0.12)",
  // [theme.breakpoints.down("xs")]: {
  //   padding: "2px 12px",
  // },
}));

const BizApoListItem = styled("div")(({ theme }) => ({
  display: "flex",
  margin: "16px auto",
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));

export default function BizApoInfoCard({
  title,
  infoList = null,
  img = null,
  children = null,
  dispalyShadow = true,
}) {
  const [avatarImg, setAvatarImg] = useState("");
  useEffect(() => {
    if (img) getImg();
  }, [img]);
  const getImg = async () => {
    await Storage.get(img)
      .then((resImg) =>
        setAvatarImg(
          <Avatar
            src={resImg}
            sx={{
              position: "absolute",
              right: "5%",
              top: "7%",
              height: "60px",
              width: "60px",
            }}
          />
        )
      )
      .catch(() => console.log("Can't get img"));
  };
  return (
    <InfoPaper style={!dispalyShadow ? { boxShadow: "none" } : {}}>
      <Typography variant="h4" style={{ margin: "12px 0" }}>
        {title}
      </Typography>
      {infoList &&
        infoList.map((item) => (
          <BizApoListItem key={item.id}>
            <Typography variant="h5">{item.title} :　</Typography>
            <Typography variant="h6" color="initial">
              {item.value}
            </Typography>
          </BizApoListItem>
        ))}
      {children}
      {img && avatarImg}
    </InfoPaper>
  );
}
