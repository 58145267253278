import { Button, Collapse, Grid, Typography } from "@mui/material";

import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { useState } from "react";
import styled from "styled-components";
import { CompanyTypeAry } from "../../../assets/Data/CompanyTypeList";

const Root = styled("div")({
  margin: "10px 0px 8px 0px",
});

export default function DetailOptions({
  preferStock,
  preferSales,
  preferCapital,
  preferEmployee,
  preferEstablish,
  preferCategory,
  keyword,
}) {
  const [openMoreOptions, setOpenMoreOptions] = useState(false);

  return (
    <Root>
      <Button
        startIcon={
          openMoreOptions ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
        }
        onClick={() => setOpenMoreOptions(!openMoreOptions)}
        color="primary"
      >
        詳細オプション
      </Button>
      <Collapse in={openMoreOptions}>
        <Grid
          container
          spacing={1}
          direction="row"
          justify="center"
          alignItems="flex-start"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Typography variant="h5">キーワード</Typography>
            <Typography variant="h6">{keyword}</Typography>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Typography variant="h5">上場</Typography>
            <Typography variant="h6">
              {preferStock.map((item) => CompanyTypeAry[item]).toString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Typography variant="h5">売上高</Typography>
            <Typography variant="h6">
              {preferSales.map((item) => CompanyTypeAry[item]).toString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">資本金</Typography>
            <Typography variant="h6">
              {preferCapital.map((item) => CompanyTypeAry[item]).toString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">従業員数</Typography>
            <Typography variant="h6">
              {preferEmployee.map((item) => CompanyTypeAry[item]).toString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">設立年</Typography>
            <Typography variant="h6">
              {preferEstablish.map((item) => CompanyTypeAry[item]).toString()}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5">カテゴリ</Typography>
            <Typography variant="h6">
              {preferCategory.map((item) => CompanyTypeAry[item]).toString()}
            </Typography>
          </Grid>
        </Grid>
      </Collapse>
    </Root>
  );
}
