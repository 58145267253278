import React from "react";
import { ReactComponent as calendarSVG } from "../../../assets/img/common/icon/calendar-week.svg";
import { ReactComponent as personaSVG } from "../../../assets/img/common/icon/persona.svg";
import { ReactComponent as scriptSVG } from "../../../assets/img/common/icon/script.svg";
import { ReactComponent as standUpSVG } from "../../../assets/img/common/icon/stand-up.svg";
import BizApoSideMenuList from "../../../components/BizApoSideMenuList";
import { ReactComponent as bizapoSVG } from "../../../assets/img/common/icon/fav_bizapo.svg";

import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
const menuList = [
  {
    title: "ホーム",
    item: [
      {
        id: 1,
        text: "企業を探す",
        icon: calendarSVG,
        url: "/company",
      },
      {
        id: 2,
        text: "ファイナンスを探す",
        icon: personaSVG,
        url: "/finance",
      },
      {
        id: 3,
        text: "支援企業を探す",
        icon: scriptSVG,
        url: "/support",
      },
      {
        id: 4,
        text: "新着情報",
        icon: standUpSVG,
        url: "/news",
      },
    ],
  },
  {
    title: "ユーザーガイド",
    item: [
      {
        id: 5,
        text: "BizApoとは",
        icon: bizapoSVG,
        url: "https://www.biz-apo.net/about_bizapo.html",
      },
      {
        id: 6,
        text: "ご利用手順",
        icon: AccountTreeOutlinedIcon,
        url: "https://www.biz-apo.net/userguide.html",
      },
      {
        id: 7,
        text: "よくある質問",
        icon: ContactSupportOutlinedIcon,
        url: "https://www.biz-apo.net/bizapo_qa.html",
      },
      {
        id: 8,
        text: "料金・プラン",
        icon: AttachMoneyIcon,
        url: "https://www.biz-apo.net/bizapo_fee.html",
      },
    ],
  },
];

function SideMenu() {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <BizApoSideMenuList menuList={menuList} />
    </div>
  );
}

export default SideMenu;
