export const MainMenuList = [
  {
    id: 1,
    text: "ホーム",
    url: "/",
    common: true,
  },
  //{
  //  id: 2,
  //  text: "公開情報",
  //  url: "/apo_release",
  //  common: true,
  //},
  {
    id: 3,
    text: "メッセージ",
    url: "/apo_message",
    common: true,
  },
  {
    id: 4,
    text: "案件管理",
    url: "/apo_management",
    common: true,
  },
 
  {
    id: 5,
    text: "企業プロフィール",
    url: "/company_profile",
    common: true,
  },

  {
    id: 6,
    text: "パスワードをリセットする",
    url: "/",
    common: false,
  },
];
