import { $http } from ".";

/* 取得user的channel清單 */
export const ChannelsGetAPI = (params) => $http.get(`/manager/${localStorage.getItem("managerId")}/channels`, params);

/* 取得已公開的apo清單 */
export const AposGetAPI = (params) => $http.get("/apos", params);

/*加入 favorite清單 */
export const FavoritelistPostAPI = (data) => $http.post("/favoritelist", data);

/* 移除 favorite */
export const FavoritelistDeleteAPI = (favoriteId) => $http.delete(`/favoritelist/${favoriteId}`);

/* 向指定的manager 提出聊天請求 
export const ChannelPostAPI = (customerManagerId) => $http.post("/channel", { customerManagerId: customerManagerId }); */

/* 向指定的corp 提出聊天請求 */
export const ChannelPostAPI = (customerCorpId) => $http.post("/channel", { customerCorpId: customerCorpId });

/* 送出 Apo 申請 */
export const ApoApplyPostAPI = (data) => $http.post("/apo/apply", data);

/* 取得 corp 資料 */
export const CorpGetAPI = (corpId) => $http.get(`/corp/${corpId}`);

/* 更新 corp 資料 */
export const CorpPutAPI = ({ corpId, data }) => $http.put(`/corp/${corpId}`, data);

/* 取得 Manager 資料 */
export const ManagerGetAPI = (username) => $http.get(`/manager/amplify/${username}`);

/* 更新 Manager 資料 */
export const ManagerPutAPI = ({ managerId, data }) => $http.put(`/manager/${managerId}`, data);

/* 更新 corp sale plan 資料 */
export const CorpSalePutAPI = ({ corpId, data }) => $http.put(`/corp/${corpId}/sale-plan`, data);

/* 更新 favorite 資料 */
export const FavoritelistPutAPI = ({ favoriteId, data }) => $http.put(`/favoritelist/${favoriteId}`, data);
