//法人
export const COMPANY_CATEGORY_CORPORATION = "0";
//個人事業主
export const COMPANY_CATEGORY_SOLO_PROPRIETORSHIP = "1";
// 公的法人・非営利法人
export const COMPANY_CATEGORY_NON_PROFIT = "2";

//Role
export const ROLE_ADMIN = 1;
export const ROLE_GENERAL = 2;
export const RoleList = ["", "管理者", "一般"];

//アポ方法
export const APO_METHOD_UNSPECIFIED = 0;
export const APO_METHOD_INTERVIEW = 1;
export const APO_METHOD_WEB = 2;
export const APO_METHOD_OTHER = 3;
export const ApoMethodAry = ["指定なし", "対面", "WEB", "その他"];

export const APO_METHOD_WEB_URL_BY_SENDER = 2; // WEB面談のURLを発行する
export const APO_METHOD_WEB_URL_BY_RECEIVER = 1; // WEB面談のURL発行を依頼する

export const SHOW_UPDATE_FOR_SENDER = 1;
export const SHOW_UPDATE_FOR_RECEIVER = 2;

//案件管理  狀態

// ApoReqeust 剛成立，等待收到申請的 Manager 接受或更改時間。
// Sender status =>	承認待ち
// Receiver status => 	審査中(要回答)
export const APO_STATUS_PENDING_RECEIVER = 1;

// 收到申請的 Manager，提出更改時間。提出申請的 Manager 需要接受更改時間的提議。
// Sender status =>	リスケ可否(要回答)
// Recirver status =>	リスケ中(承認待ち)
export const APO_STATUS_RESCHEDULED_RECEIVER = 2;

// 提出申請的 Manager，提出更改時間。收到申請的 Manager 需要接受更改時間的提議。
// Sender status	リスケ中(承認待ち)
// Recirver status	リスケ可否(要回答)
export const APO_STATUS_RESCHEDULED_SENDER = 3;

// ApoRequest 已經被收到申請的 Manager 確認。
// Sender status	面談待ち
// Receiver status	面談待ち
export const APO_STATUS_ACCEPT = 4;

// 收到申請的 Manager 拒絕了此次的申請。
// Sender status	不承認
// Receiver status	不承認
export const APO_STATUS_REJECT_RECEIVER = 5;

// 提出申請的 Manager 取消了此次的申請。
// Sender status	キャンセル済み
// Receiver status	キャンセル済み
export const APO_STATUS_REJECT_SENDER = 6;

// ApoRequest 維持在 PendingReceiver 狀態超過 3天，已經過期。
// Sender status	キャンセル済み
// Receiver status	キャンセル済み
export const APO_STATUS_REJECT_EXPIRED = 7;

// ApoRequest 在 Accept 狀態，且面談日期已經過了，正在等待評分。
// Sender status
// Receiver status	要評価
export const APO_STATUS_WAITING_RATING = 8;

// ApoReques 已經被評分，整筆動作結束。
// Sender status
// Receiver status	評価済
export const APO_STATUS_COMPLETED = 9;

export const apoSenderStatusAry = [
  "",
  "承認待ち",
  "リスケ可否（要回答）",
  "リスケ中（承認待ち）",
  "面談待ち",
  "不承認",
  "キャンセル済み",
  "キャンセル済み",
  "",
  "",
];
export const apoReceiverStatusAry = [
  "",
  "審査中（要回答）",
  "リスケ中（承認待ち）",
  "リスケ可否（要回答）",
  "面談待ち",
  "不承認",
  "キャンセル済み",
  "キャンセル済み",
  "要評価",
  "評価済",
];

export const rejectTypeAry = [
  "",
  "",
  "",
  "",
  "",
  "不承認", // APO_STATUS_REJECT_RECEIVER = 5;
  "取り下げ", // APO_STATUS_REJECT_SENDER = 6;
  "期限切れ", //  APO_STATUS_REJECT_EXPIRED = 7;
  "",
  "",
];

//案件管理 使用共用元件的來源情境(apo或contact)
export const APO_MANAGEMENT_SITUATION_APO = 0;
export const APO_MANAGEMENT_SITUATION_CONTACT = 1;
