import { Box, CircularProgress } from "@mui/material";
import React from "react";
export default function BizApoLoading() {
  return (
    <Box
      sx={{
        margin: "10% 0px",
        width: "100%",
        textAlign: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
}
