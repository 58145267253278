import React, { useRef, useState } from "react";
import Slider from "react-slick";
import * as style from "./ListLayout.module.scss";
import { ReactComponent as arrowLeftSVG } from "../../assets/img/common/icon/chevron-left.svg";
import { ReactComponent as arrowRightSVG } from "../../assets/img/common/icon/chevron-right.svg";
import { SvgIcon } from "@material-ui/core";

function ListLayout(props) {
  const { title, children, listSetting } = props;
  const apoSliderRef = useRef();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: false,
    autoplaySpeed: 1000,
    arrows: false,
    afterChange: () => setSlideIndex(),
    onReInit: () => setSlideIndex(),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    ...listSetting,
  };
  const setSlideIndex = () => {
    let slidesToShow = parseInt(
      apoSliderRef.current.innerSlider.track.props.slidesToShow
    );
    let slideCount = parseInt(
      apoSliderRef.current.innerSlider.track.props.slideCount
    );
    let currentSlide = parseInt(
      apoSliderRef.current.innerSlider.track.props.currentSlide
    );
    setTotalPages(Math.ceil(slideCount / slidesToShow));
    setCurrentPage(Math.ceil((currentSlide + 1) / slidesToShow));
  };
  return (
    <div>
      <div className={style.titleWrap}>
        <span className={style.commonTitle}>{title}</span>
        <div className={style.slideIndexWrap}>
          <SvgIcon
            component={arrowLeftSVG}
            style={{ fontSize: 32, cursor: "pointer" }}
            onClick={() => apoSliderRef.current.slickPrev()}
          />
          <div className={style.slideIndex}>
            {currentPage} / {totalPages}
          </div>

          <SvgIcon
            component={arrowRightSVG}
            style={{ fontSize: 32, cursor: "pointer" }}
            onClick={() => apoSliderRef.current.slickNext()}
          />
        </div>
      </div>

      <Slider {...settings} ref={apoSliderRef} style={{ height: "auto" }}>
        {children}
      </Slider>
    </div>
  );
}

export default ListLayout;
