import React from "react";

import DetailOptions from "./DetailOptions";
import { BizApoPaper3 } from "../../../components/BizApoPaper3";
import { Grid, Typography } from "@mui/material";
import { IndustryAry } from "../../../assets/Data/IndustryList";
import { OccupationAry } from "../../../assets/Data/OccupationList";
import { ApoMethodAry } from "../../../assets/constant";
import { ApoPurposeAry } from "../../../assets/Data/ApoPurposeList";

export default function ApoPublishInfo({
  industry,
  jobName,
  contactMethod,
  contactDetail,
  preferStock,
  preferSales,
  preferCapital,
  preferEmployee,
  preferEstablish,
  preferCategory,
  target,
  targetOther,
  keyword,
  title = "アポ公開情報",
}) {
  return (
    <BizApoPaper3>
      <Typography variant="h3" style={{ marginBottom: 10 }}>
        {title}
      </Typography>

      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="h5">アポ募集業種</Typography>
          <Typography variant="h6">
            {industry.map((item) => IndustryAry[item]).toString()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">アポ募集職種</Typography>
          <Typography variant="h6">
            {jobName.map((item) => OccupationAry[item]).toString()}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">アポイントの目的</Typography>
          <Typography variant="h6">
            {target
              .map((item) => {
                //9為 選擇 "その他"
                if (item !== 9) return ApoPurposeAry[item];
                else {
                  if (targetOther.length > 0)
                    //選"その他" 並有輸入資料
                    return `${ApoPurposeAry[item]}:${targetOther}`;
                  else return ApoPurposeAry[item];
                }
              })
              .toString()}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">アポ方法</Typography>
          <Typography variant="h6">{ApoMethodAry[contactMethod]}</Typography>
        </Grid>
        {contactDetail.length > 0 && (
          <Grid item xs={12}>
            <Typography variant="h5">
              Web会議の情報をペーストします。
            </Typography>
            <Typography variant="h6" sx={{ overflowWrap: "break-word" }}>
              {contactDetail}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <DetailOptions
            preferStock={preferStock}
            preferSales={preferSales}
            preferCapital={preferCapital}
            preferEmployee={preferEmployee}
            preferEstablish={preferEstablish}
            preferCategory={preferCategory}
            target={target}
            targetOther={targetOther}
            keyword={keyword}
          />
        </Grid>
      </Grid>
    </BizApoPaper3>
  );
}
