import { List, ListItem, ListItemText } from "@mui/material";
import React from "react";

export default function BizApoList({ dataList = [] }) {
  return (
    <List>
      {dataList.map((item, index) => (
        <ListItem key={index} sx={{ padding: 0 }}>
          <ListItemText
            primary={item.title}
            secondary={item.value}
            primaryTypographyProps={{
              fontSize: 16,
              lineHeight: "24px",
              color: "rgba(51, 51, 51, 0.5)",
            }}
            secondaryTypographyProps={{
              fontSize: 16,
              fontWeight: "600",
              lineHeight: "24px",
              color: "#333333",
            }}
          />
        </ListItem>
      ))}
    </List>
  );
}
