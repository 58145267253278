import React, { useEffect, useState } from "react";
import { MainMenuList } from "./MenuList";
import { BizApoMenu, BizApoMenuItem } from "../../../components/BizApoMenu";
import { useHistory } from "react-router";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import { Button, Avatar, styled } from "@mui/material";
import { Storage } from "aws-amplify";

const AccoundBtn = styled(Button)(({ theme }) => ({
  marginRight: 20,
  fontSize: 20,
  fontWeight: 700,
  [theme.breakpoints.down("1150")]: {
    display: "none",
  },

  "& .MuiSvgIcon-root": {
    fontSize: 36,
  },
}));

export default function AccountMenu({ signOut, managerData }) {
  const [openAccountMenu, setOpenAccountMenu] = useState(false);
  const history = useHistory();
  const { userToken } = useGetUserInfo();
  const [corpAvatar, setCorpAvatar] = useState(null);

  const openMenu = (event) => {
    setOpenAccountMenu(event.currentTarget);
  };
  useEffect(() => {
    if (managerData.imgUrl) setAvatar(managerData.imgUrl);
  }, [managerData]);
  const setAvatar = async (imgUrl) => {
    await Storage.get(imgUrl)
      .then((res) => setCorpAvatar(<Avatar alt="Bizapo" src={res} />))
      .catch(() => console.log("Can't get Avatar"));
  };

  return (
    <>
      {userToken ? (
        <AccoundBtn
          variant="text"
          size="large"
          startIcon={corpAvatar}
          onClick={openMenu}
        >
          {managerData.corpProfile?.companyName}
        </AccoundBtn>
      ) : (
        <AccoundBtn
          variant="text"
          size="large"
          onClick={() => history.push("/login")}
        >
          ログイン
        </AccoundBtn>
      )}

      <BizApoMenu
        anchorEl={openAccountMenu}
        open={Boolean(openAccountMenu)}
        onClose={() => setOpenAccountMenu(null)}
      >
        {MainMenuList.map(
          (item) =>
            !item.common && (
              <BizApoMenuItem
                key={item.id}
                onClick={() => setOpenAccountMenu(null)}
              >
                {item.text}
              </BizApoMenuItem>
            )
        )}
        <BizApoMenuItem onClick={()=>{signOut();
        setOpenAccountMenu(null)}}>ログアウト</BizApoMenuItem>
      </BizApoMenu>
    </>
  );
}
