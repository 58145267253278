import { BizApoColors } from "../assets/Styles/BizApoColors";
import { Menu, MenuItem, styled } from "@mui/material";

export const BizApoMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    padding: 0,
    fontSize: 20,
    fontWeight: 600,
    color: BizApoColors.primary,
  },
});

export const BizApoMenuItem = styled(MenuItem)({
  padding: "12px 16px",
  fontSize: 20,
  fontWeight: 600,
  color: BizApoColors.primary,
  transition: "0.3s",
  "&:hover": {
    color: BizApoColors.secondary,
  },
});
