import { Dialog, styled } from "@mui/material";

export const BizApoDialog1 = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    borderRadius: 8,
  },
  "& .MuiPaper-root": {
    margin: "1%",
  },
}));
