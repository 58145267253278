// 職種分類
export const OccupationList = [
  {
    category_id: 1,
    category: "事務系",
    title: "",
    id: 0,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "事務全般",
    id: 1,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "商品企画/開発",
    id: 2,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "人事",
    id: 3,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "宣伝/広報",
    id: 4,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "法務",
    id: 5,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "経営企画",
    id: 6,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "総務",
    id: 7,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "調査/マーケティング",
    id: 8,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "会計/経理/財務",
    id: 9,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "カスタマーサービス",
    id: 10,
  },
  {
    category_id: 1,
    category: "事務系",
    title: "その他",
    id: 11,
  },
  {
    category_id: 2,
    category: "営業・販売系",
    title: "",
    id: 12,
  },
  {
    category_id: 2,
    category: "営業・販売系",
    title: "一般営業",
    id: 13,
  },
  {
    category_id: 2,
    category: "営業・販売系",
    title: "技術営業/システム営業",
    id: 14,
  },
  {
    category_id: 2,
    category: "営業・販売系",
    title: "バイヤー",
    id: 15,
  },
  {
    category_id: 2,
    category: "営業・販売系",
    title: "店舗開発",
    id: 16,
  },
  {
    category_id: 2,
    category: "営業・販売系",
    title: "販売/サービススタッフ",
    id: 17,
  },
  {
    category_id: 2,
    category: "営業・販売系",
    title: "その他",
    id: 18,
  },
  {
    category_id: 3,
    category: "IT系",
    title: "",
    id: 19,
  },
  {
    category_id: 3,
    category: "IT系",
    title: "システムアナリスト/コンサルタント",
    id: 20,
  },
  {
    category_id: 3,
    category: "IT系",
    title: "システム運用・保守",
    id: 21,
  },
  {
    category_id: 3,
    category: "IT系",
    title: "システムエンジニア",
    id: 22,
  },
  {
    category_id: 3,
    category: "IT系",
    title: "プログラマー",
    id: 23,
  },
  {
    category_id: 3,
    category: "IT系",
    title: "Web制作者",
    id: 24,
  },
  {
    category_id: 3,
    category: "IT系",
    title: "その他",
    id: 25,
  },
  {
    category_id: 4,
    category: "技術系",
    title: "",
    id: 26,
  },
  {
    category_id: 4,
    category: "技術系",
    title: "基礎研究",
    id: 27,
  },
  {
    category_id: 4,
    category: "技術系",
    title: "応用研究・技術開発",
    id: 28,
  },
  {
    category_id: 4,
    category: "技術系",
    title: "生産・製造技術開発",
    id: 29,
  },
  {
    category_id: 4,
    category: "技術系",
    title: "機械・電機・電子機器設計",
    id: 30,
  },
  {
    category_id: 4,
    category: "技術系",
    title: "その他",
    id: 31,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "",
    id: 32,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "コンサルタント",
    id: 33,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "融資・資産運用",
    id: 34,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "公認会計士",
    id: 35,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "税理士",
    id: 36,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "弁護士",
    id: 37,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "弁理士",
    id: 38,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "社労士",
    id: 39,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "司法書士",
    id: 40,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "行政書士",
    id: 41,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "教員",
    id: 42,
  },
  {
    category_id: 5,
    category: "専門系",
    title: "その他",
    id: 43,
  },
  {
    category_id: 6,
    category: "その他",
    title: "",
    id: 44,
  },
  {
    category_id: 6,
    category: "その他",
    title: "それ以外の職種",
    id: 45,
  },
];

export const OccupationAry = [
  "",
  "事務全般",
  "商品企画/開発",
  "人事",
  "宣伝/広報",
  "法務",
  "経営企画",
  "総務",
  "調査/マーケティング",
  "会計/経理/財務",
  "カスタマーサービス",
  "その他",
  "",
  "一般営業",
  "技術営業/システム営業",
  "バイヤー",
  "店舗開発",
  "販売/サービススタッフ",
  "その他",
  "",
  "システムアナリスト/コンサルタント",
  "システム運用・保守",
  "システムエンジニア",
  "プログラマー",
  "Web制作者",
  "その他",
  "",
  "基礎研究",
  "応用研究・技術開発",
  "生産・製造技術開発",
  "機械・電機・電子機器設計",
  "その他",
  "",
  "コンサルタント",
  "融資・資産運用",
  "公認会計士",
  "税理士",
  "弁護士",
  "弁理士",
  "社労士",
  "司法書士",
  "行政書士",
  "教員",
  "その他",
  "",
  "それ以外の職種",
];
