// 企業の分類
export const CompanyTypeList = [
  {
    category_id: 1,
    category: "カテゴリ",
    title: "法人",
    id: 0,
  },
  {
    category_id: 1,
    category: "カテゴリ",
    title: "個人事業主",
    id: 1,
  },
  {
    category_id: 1,
    category: "カテゴリ",
    title: "公的法人・非営利法人",
    id: 2,
  },
  {
    category_id: 2,
    category: "上場",
    title: "上場",
    id: 3,
  },
  {
    category_id: 2,
    category: "上場",
    title: "非上場",
    id: 4,
  },
  {
    category_id: 3,
    category: "売上高",
    title: "10億円未満",
    id: 5,
  },
  {
    category_id: 3,
    category: "売上高",
    title: "10〜50億円未満",
    id: 6,
  },
  {
    category_id: 3,
    category: "売上高",
    title: "50〜100億円未満",
    id: 7,
  },
  {
    category_id: 3,
    category: "売上高",
    title: "100〜500億円未満",
    id: 8,
  },
  {
    category_id: 3,
    category: "売上高",
    title: "500〜1000億円未満",
    id: 9,
  },
  {
    category_id: 3,
    category: "売上高",
    title: "1000億円〜",
    id: 10,
  },
  {
    category_id: 4,
    category: "資本金",
    title: "1000万円未満",
    id: 11,
  },
  {
    category_id: 4,
    category: "資本金",
    title: "1000〜5000万円未満",
    id: 12,
  },
  {
    category_id: 4,
    category: "資本金",
    title: "5000万円〜１億円未満",
    id: 13,
  },
  {
    category_id: 4,
    category: "資本金",
    title: "１〜5億円未満",
    id: 14,
  },
  {
    category_id: 4,
    category: "資本金",
    title: "5〜10億円未満",
    id: 15,
  },
  {
    category_id: 4,
    category: "資本金",
    title: "10億円〜",
    id: 16,
  },
  {
    category_id: 5,
    category: "従業員数",
    title: "10人未満",
    id: 17,
  },
  {
    category_id: 5,
    category: "従業員数",
    title: "10〜50人未満",
    id: 18,
  },
  {
    category_id: 5,
    category: "従業員数",
    title: "50〜100人未満",
    id: 19,
  },
  {
    category_id: 5,
    category: "従業員数",
    title: "100〜500人未満",
    id: 20,
  },
  {
    category_id: 5,
    category: "従業員数",
    title: "500〜1000人未満",
    id: 21,
  },
  {
    category_id: 5,
    category: "従業員数",
    title: "1000人〜",
    id: 22,
  },
  {
    category_id: 6,
    category: "設立年",
    title: "1989年以前",
    id: 23,
  },
  {
    category_id: 6,
    category: "設立年",
    title: "1990〜1999年",
    id: 24,
  },
  {
    category_id: 6,
    category: "設立年",
    title: "2000〜2009年",
    id: 25,
  },
  {
    category_id: 6,
    category: "設立年",
    title: "2010〜2014年",
    id: 26,
  },
  {
    category_id: 6,
    category: "設立年",
    title: "2015〜2019年",
    id: 27,
  },
  {
    category_id: 6,
    category: "設立年",
    title: "2020年以降",
    id: 28,
  },
];

export const CompanyTypeAry = [
  "企業",
  "投資会社・投資家",
  "支援企業",
  "上場",
  "非上場",
  "10億円未満",
  "10〜50億円未満",
  "50〜100億円未満",
  "100〜500億円未満",
  "500〜1000億円未満",
  "1000億円〜",
  "1000万円未満",
  "1000〜5000万円未満",
  "5000万円〜１億円未満",
  "１〜5億円未満",
  "5〜10億円未満",
  "10億円〜",
  "10人未満",
  "10〜50人未満",
  "50〜100人未満",
  "100〜500人未満",
  "500〜1000人未満",
  "1000人〜",
  "1989年以前",
  "1990〜1999年",
  "2000〜2009年",
  "2010〜2014年",
  "2015〜2019年",
  "2020年以降",
];

export const categoryIndexAry = [0, 1, 2];
export const stockIndexAry = [3, 4];
export const salesIndexAry = [5, 6, 7, 8, 9, 10];
export const capitalIndexAry = [11, 12, 13, 14, 15, 16];
export const employeeIndexAry = [17, 18, 19, 20, 21, 22];
export const establishIndexAry = [23, 24, 25, 26, 27, 28];
