//  此頁 プレゼン公開企業 和 案件管理中的功能共用
import {
  Box,
  Button,
  Step,
  StepLabel,
  Stepper,
  styled,
  Grid,
  Typography,
} from "@mui/material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import axios from "axios";
import { formatISO } from "date-fns";
import React, { Fragment, useEffect } from "react";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { initScheduleDatetimeList } from "../../../components/biz-apo-datetime-selecting/BizApoDatetimeSelecting";
import BizApoDialog from "../../../components/BizApoDialog";
import BizApoLoading from "../../../components/BizApoLoading";
import CompanyProfileForm from "../../../components/CompanyProfileForm/CompanyProfileForm";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import CompanyProfile from "../../ApoRelease/step3/CompanyProfile";
import ConfrimToLeaveDialog from "../../ApoRelease/step3/ConfrimToLeaveDialog";
import ContactInfo from "./ContactInfo";
import ContactInfoConfirm from "./ContactInfoConfirm";

const ButtonWrap = styled("div")(({ theme }) => ({
  margin: "0px auto",
  width: 300,
  padding: "16px 0px",
  display: "flex",
  justifyContent: "space-between",
}));

const steps = ["Step 1", "Step 2", "Step 3"];

export default function ContactIndex() {
  const history = useHistory();
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const contactCorpData = location.state.contactCorpData;
  const { userToken, corpId, managerId } = useGetUserInfo();
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // コンタクトの目的
  const [contactReason, setContactReason] = useState("");
  const [scheduleList, setScheduleList] = useState(initScheduleDatetimeList);
  const [companyProfileList, setCompanyProfileList] = useState([]);
  const [isSelectedApoSchedule, setIsSelectedApoSchedule] = useState(false);
  // Kevin add 
  const [contactMethod, setContactMethod] = useState(0);
  const [contactDetail, setContactDetail] = useState("");
  const [openApplyDialog, setOpenApplyDialog] = useState(false);
  const [receiverManager,setReceiverManager]= useState(0);
  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);
  useEffect(() => {
    if (!contactCorpData) history.push("/company");
    if (!userToken) history.push("/login");
    else if (corpId) fetchCorpData();
  }, [corpId, userToken]);

  const fetchCorpData = async () => {
    setIsLoading(true);
    await axios({
      method: "get",
      url: `/corp/${corpId}`,
      headers: { Authorization: userToken },
    })
      .then((res) =>
        setTimeout(() => {
          const managerName =
            res.data.manager.filter((item) => item.id === managerId)[0][
              "name"
            ] || "";
          setCompanyProfileList([
            { id: 2, title: "会社名", value: res.data.companyName || "" },
            { id: 3, title: "担当者", value: managerName },
          ]);
          setIsLoading(false);
        }, 100)
      )
      .catch(() => console.log("Can't get manager data."));
  };

  const handleContact = async () => {
    setIsLoading(true);
    await axios({
      method: "post",
      url: "/corp/contact-request/",
      headers: { Authorization: userToken },
      data: {
        senderManagerId: managerId,
        //receiverManagerId: contactCorpData.receiverManagerId
        //  ? contactCorpData.receiverManagerId
        //  : contactCorpData.id,
        receiverManagerId: contactCorpData.manager[receiverManager].id,
        reason: contactReason,
        //Database中的格式為iso，存入時須進行轉換
        scheduleStart1: formatISO(new Date(scheduleList[0]["startDatetime"])),
        scheduleEnd1: formatISO(new Date(scheduleList[0]["endDatetime"])),
        scheduleStart2: scheduleList[1]["enable"]
          ? formatISO(new Date(scheduleList[1]["startDatetime"]))
          : "",
        scheduleEnd2: scheduleList[1]["enable"]
          ? formatISO(new Date(scheduleList[1]["endDatetime"]))
          : "",
        scheduleStart3: scheduleList[2]["enable"]
          ? formatISO(new Date(scheduleList[2]["startDatetime"]))
          : "",
        scheduleEnd3: scheduleList[2]["enable"]
          ? formatISO(new Date(scheduleList[2]["endDatetime"]))
          : "",
        scheduleStart4: scheduleList[3]["enable"]
          ? formatISO(new Date(scheduleList[3]["startDatetime"]))
          : "",
        scheduleEnd4: scheduleList[3]["enable"]
          ? formatISO(new Date(scheduleList[3]["endDatetime"]))
          : "",
        scheduleStart5: scheduleList[4]["enable"]
          ? formatISO(new Date(scheduleList[4]["startDatetime"]))
          : "",
        scheduleEnd5: scheduleList[4]["enable"]
          ? formatISO(new Date(scheduleList[4]["endDatetime"]))
          : "",
        contactMethod: contactMethod,
        contactDetail: contactDetail,

      },
    })
      .then(() =>
        setTimeout(() => {
          setIsLoading(false);
          setOpenDialog(true);
        }, 100)
      )
      .catch(() => console.log("Can't send contact request."));
  };

  return (
    <Fragment>
      {/* Kevin remove step 1 ~ step 3
      <Box sx={{ width: "100%", maxWidth: 500, margin: "28px auto" }}>
        <Stepper activeStep={activeStep}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      */}

      {isLoading ? (
        <BizApoLoading />
      ) : (
        <Box>
          {activeStep === 0 && (
            <ContactInfo
              companyProfileList={companyProfileList}
              setCompanyProfileList={setCompanyProfileList}
              contactReason={contactReason}
              setContactReason={setContactReason}
              scheduleList={scheduleList}
              setScheduleList={setScheduleList}
              contactCorpData={contactCorpData}
              isSelectedApoSchedule={isSelectedApoSchedule}
              setIsSelectedApoSchedule={setIsSelectedApoSchedule}
              contactMethod={contactMethod}
              setContactMethod={setContactMethod}
              contactDetail={contactDetail}
              setContactDetail={setContactDetail}
              receiverManager = {receiverManager}
              setReceiverManager = {setReceiverManager}
            />
          )}
          {activeStep === 1 && (
            <CompanyProfileForm
              formTitle="会社プロフィールの確認"
              subTitle="以下情報にお間違えなければ、「次へ」"
            />
          )}
          {activeStep === 2 && (
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid item sm={12}>
                <Typography variant="h6" sx={{ textAlign: "center" }}>
                  この内容でコンタクトします。ご確認をお願い致します。
                </Typography>
              </Grid>

              <Grid item sm={12} md={6}>
                <ContactInfoConfirm
                  companyProfileList={companyProfileList}
                  contactReason={contactReason}
                  scheduleList={scheduleList}
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <CompanyProfile />
              </Grid>
            </Grid>
          )}
        </Box>
      )}

      <ButtonWrap>
        {activeStep > 0 && (
          <Button
            variant="primary"
            onClick={() => {
              let tempStepNum = activeStep - 1;
              setActiveStep(tempStepNum);
            }}
            disabled={isLoading}
          >
            前へ
          </Button>
        )}
        {activeStep < steps.length - 1 && (
          <Button
            variant="primary"
            onClick={() => {
              //let tempStepNum = activeStep + 1;
              //setActiveStep(tempStepNum);
              setOpenApplyDialog(true);
            }}
            disabled={isLoading || scheduleList[0]["enable"] === false}
          >
            申請実行 {/*Kevin 次へ 改成 申請実行*/}
          </Button> 
        )}
        {activeStep === steps.length - 1 && (
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={handleContact}
          >
            提 出
          </Button>
        )}
        <Button
          variant="gray"
          onClick={() => setOpenConfirmDialog(true)}
          disabled={isLoading}
        >
          キャンセル
        </Button>
      </ButtonWrap>

      <BizApoDialog
        openDialog={openDialog}
        content={
          "コンタクトの申し込みが完了しました、「案件管理」で申し込みの内容を確認することができまあす。"
        }
        confirmButton={{ text: "案件管理へ", path: "/apo_management" }}
      />

      <ConfrimToLeaveDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
      />

      <Dialog open={openApplyDialog}>
        {/* <DialogTitle>確認</DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            {"アポ申請をします。よろしいですか？"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              setOpenApplyDialog(false);
              handleContact();
              //setOpenDialog(true);
            }} color="primary">
            実行
          </Button>
          <Button onClick={() => setOpenApplyDialog(false)} color="info">
            戻る
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  );
}
