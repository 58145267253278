import { ChimeSDKMessagingClient, GetMessagingSessionEndpointCommand, ListChannelMessagesCommand } from "@aws-sdk/client-chime-sdk-messaging";
import { Avatar } from "@mui/material";
import { ConsoleLogger, DefaultMessagingSession, LogLevel, MessagingSessionConfiguration } from "amazon-chime-sdk-js";
import { Auth, Storage } from "aws-amplify";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import _ from "lodash";
import { useGetUserInfo } from "./useGetUserInfo";
import { useDispatch, useSelector } from "react-redux";
import { messageInfo, setUnReadchannels } from "../redux/slice/message.slice";

// Call services configuration
export const useGetUnreadChannels = () => {
  const { managerId, userToken, appInstanceUserArn, managerImgUrl } = useGetUserInfo();
  const [channelList, setChannelList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const msgRef = useRef(messageList);
  //set selected channel
  const [currentChannel, setCurrentChannel] = useState(null);

  // set current selected manager'id
  //從其他頁面 點選某個 manager或corp 連結到聊天功能頁 與 該user(contactingManagerId) 進行聊天
  const [currentManagerId, setCurrentManagerId] = useState(0);

  const [awsClient, setAwsClient] = useState(null);
  const [currentChannelArn, setCurrentChannelArn] = useState(null);
  const currentChaanelArnRef = useRef(currentChannelArn);

  const location = useLocation();
  const [isLoadSession, setIsLoadSession] = useState(false);
  const { unreadChannels } = useSelector(messageInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userToken !== "" && managerId !== "") {
      fetchChannels();
      getAwsCredentials();
    }
  }, [userToken, managerId]);

  useEffect(() => {
    if (awsClient && !isLoadSession) {
      handleMsgSession();
      setIsLoadSession(true);
    }
  }, [awsClient]);

  useEffect(() => {
    if (typeof location.state !== "undefined") setCurrentManagerId(location.state.contactingManagerId);
    else setCurrentManagerId(null);
  }, [location]);

  //Get Channel list
  const fetchChannels = async () => {
    await axios({
      method: "get",
      url: `/manager/${managerId}/channels`,
      headers: {
        Authorization: userToken,
      },
      params: {
        take: 50,
      },
    })
      .then((res) => {
        setChannelList(res.data.channels);
      })
      .catch((err) => console.log("Can't get channel list"));
  };

  //Get AWS Credentials
  const getAwsCredentials = async () => {
    await Auth.currentUserCredentials().then((awsCredentials) => {
      const tempClient = new ChimeSDKMessagingClient({
        region: "us-east-1",
        credentials: {
          accessKeyId: awsCredentials.accessKeyId,
          secretAccessKey: awsCredentials.secretAccessKey,
          sessionToken: awsCredentials.sessionToken,
        },
      });
      setAwsClient(tempClient);
    });
  };

  const handleMsgSession = async () => {
    const logger = new ConsoleLogger("SDK", LogLevel.INFO);
    let chime = null;
    let awsCredentialsData = null;
    await Auth.currentUserCredentials().then((awsCredentials) => {
      chime = new ChimeSDKMessagingClient({
        region: "us-east-1",
        credentials: {
          accessKeyId: awsCredentials.accessKeyId,
          secretAccessKey: awsCredentials.secretAccessKey,
          sessionToken: awsCredentials.sessionToken,
        },
      });
      awsCredentialsData = awsCredentials;
    });
    const endpoint = await chime.send(new GetMessagingSessionEndpointCommand());
    const configuration = new MessagingSessionConfiguration(appInstanceUserArn, awsCredentialsData.sessionToken, endpoint.Endpoint.Url, chime);
    const messagingSession = new DefaultMessagingSession(configuration, logger);

    const observer = {
      messagingSessionDidStart: () => {
        console.log("Session started");
      },
      messagingSessionDidStartConnecting: (reconnecting) => {
        if (reconnecting) {
          console.log("Start reconnecting");
        } else {
          console.log("Start connecting");
        }
      },
      messagingSessionDidStop: (event) => {
        console.log(`Closed: ${event.code} ${event.reason}`);
      },
      messagingSessionDidReceiveMessage: (message) => {
        const messageType = message?.headers["x-amz-chime-event-type"];
        const record = JSON.parse(message?.payload);

        switch (messageType) {
          case "CREATE_CHANNEL_MESSAGE":
          case "REDACT_CHANNEL_MESSAGE":
          case "UPDATE_CHANNEL_MESSAGE":
          case "DELETE_CHANNEL_MESSAGE":
          case "DENIED_CREATE_CHANNEL_MESSAGE":
          case "FAILED_CREATE_CHANNEL_MESSAGE":
          case "DENIED_UPDATE_CHANNEL_MESSAGE":
          case "FAILED_UPDATE_CHANNEL_MESSAGE":
          case "PENDING_CREATE_CHANNEL_MESSAGE":
          case "PENDING_UPDATE_CHANNEL_MESSAGE":
            if (location.pathname !== "/apo_message") {
              const newUnreads = [...unreadChannels, record.ChannelArn];
              dispatch(setUnReadchannels(newUnreads));
            }
            break;

          default:
            console.log(`Unexpected message type! ${messageType}`);
        }
      },
    };

    messagingSession.addObserver(observer);
    await messagingSession.start();
  };

  return unreadChannels;
};
