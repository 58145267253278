import {
  ChannelMessagePersistenceType,
  ChannelMessageType,
  ChimeSDKMessagingClient,
  GetMessagingSessionEndpointCommand,
  ListChannelMessagesCommand,
  SendChannelMessageCommand,
} from "@aws-sdk/client-chime-sdk-messaging";
import { Avatar, Box, styled } from "@mui/material";
import { ConsoleLogger, DefaultMessagingSession, LogLevel, MessagingSessionConfiguration } from "amazon-chime-sdk-js";
import { Auth, Storage } from "aws-amplify";
import axios from "axios";
import { createContext, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BizApoPaper3 } from "../components/BizApoPaper3";
import { useGetUserInfo } from "../hooks/useGetUserInfo";
import ChannelIndex from "./channel/ChannelIndex";
import MessagesIndex from "./messages/MessagesIndex";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { messageInfo, setUnReadchannels } from "../redux/slice/message.slice";

export const ChatContext = createContext();

const ChatBox = styled(BizApoPaper3)({
  display: "flex",
  padding: 0,
});

// Call services configuration
export default function ChatIndex() {
  const { managerId, userToken, appInstanceUserArn, managerImgUrl,corpId } = useGetUserInfo();
  const [channelList, setChannelList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  const msgRef = useRef(messageList);
  //set selected channel
  const [currentChannel, setCurrentChannel] = useState(null);

  // set current selected manager'id
  //從其他頁面 點選某個 manager或corp 連結到聊天功能頁 與 該user(contactingManagerId) 進行聊天
  const [currentManagerId, setCurrentManagerId] = useState(0);

  // Kevin
  const [currentCorpId, setCurrentCorpId] = useState(0);

  const [awsClient, setAwsClient] = useState(null);
  const [isChannelLoading, setIsChannelLoading] = useState(true);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [avatarList, setAvatarList] = useState([]);
  // const [unreadChannels, setUnreadChannels] = useState([]);
  const { unreadChannels } = useSelector(messageInfo);
  const dispatch = useDispatch();
  const [currentChannelArn, setCurrentChannelArn] = useState(null);
  const currentChaanelArnRef = useRef(currentChannelArn);

  const history = useHistory();
  const location = useLocation();
  const [isLoadSession, setIsLoadSession] = useState(false);
  //Kevin store all manager's arn for current company
  const [userArn,setUserArn]=useState([]);

  useEffect(() => {
    if (userToken === "") {
      history.push("/login");
    } else if (managerId !== "") {
      fetchChannels();
      getAwsCredentials();
      // Kevin debug
      console.log(avatarList);
      console.log(channelList);
    }
  }, [userToken, managerId]);

  useEffect(() => {
    if (awsClient && !isLoadSession) {
      handleMsgSession();
      setIsLoadSession(true);
    }
  }, [awsClient]);
  useEffect(() => {
    //雙方都同意 status =2 才能顯示message
    // Kevin
    //if (currentChannel?.channel.manager[0].status === 2 && currentChannel?.channel.manager[1].status === 2 && awsClient) {
    if (currentChannel?.status === 2 && awsClient) {
      getMessages();
    } else {
      setMessageList([]);
    }
  }, [currentChannel]);

  useEffect(() => {
    if (typeof location.state !== "undefined") setCurrentManagerId(location.state.contactingManagerId);
    else setCurrentManagerId(null);
  }, [location]);

  //Get Channel list
  const fetchChannels = async () => {
    setIsChannelLoading(true);
    await axios({
      method: "get",
      url: `/manager/${managerId}/channels`,
      headers: {
        Authorization: userToken,
      },
      params: {
        take: 50,
      },
    })
      .then((res) => {
        if(res.data.channels.length !==0) //Kevin 2023-11-2
        {
          setChannelList(res.data.channels);
          handleGetAvatar(res.data.channels);
        }
      })
      .catch((err) => console.log("Can't get channel list"))
      .finally(() => setIsChannelLoading(false));
  };

  //Get AWS Credentials
  const getAwsCredentials = async () => {
    await Auth.currentUserCredentials().then((awsCredentials) => {
      const tempClient = new ChimeSDKMessagingClient({
        region: "us-east-1",
        credentials: {
          accessKeyId: awsCredentials.accessKeyId,
          secretAccessKey: awsCredentials.secretAccessKey,
          sessionToken: awsCredentials.sessionToken,
        },
      });
      setAwsClient(tempClient);
    });
  };

  const handleMsgSession = async () => {
    const logger = new ConsoleLogger("SDK", LogLevel.INFO);
    let chime = null;
    let awsCredentialsData = null;
    await Auth.currentUserCredentials().then((awsCredentials) => {
      chime = new ChimeSDKMessagingClient({
        region: "us-east-1",
        credentials: {
          accessKeyId: awsCredentials.accessKeyId,
          secretAccessKey: awsCredentials.secretAccessKey,
          sessionToken: awsCredentials.sessionToken,
        },
      });
      awsCredentialsData = awsCredentials;
    });
    const endpoint = await chime.send(new GetMessagingSessionEndpointCommand());
    const configuration = new MessagingSessionConfiguration(appInstanceUserArn, awsCredentialsData.sessionToken, endpoint.Endpoint.Url, chime);
    const messagingSession = new DefaultMessagingSession(configuration, logger);

    const observer = {
      messagingSessionDidStart: () => {
        console.log("Session started");
      },
      messagingSessionDidStartConnecting: (reconnecting) => {
        if (reconnecting) {
          console.log("Start reconnecting");
        } else {
          console.log("Start connecting");
        }
      },
      messagingSessionDidStop: (event) => {
        console.log(`Closed: ${event.code} ${event.reason}`);
      },
      messagingSessionDidReceiveMessage: (message) => {
        const messageType = message?.headers["x-amz-chime-event-type"];
        const record = JSON.parse(message?.payload);

        switch (messageType) {
          case "CREATE_CHANNEL_MESSAGE":
          case "REDACT_CHANNEL_MESSAGE":
          case "UPDATE_CHANNEL_MESSAGE":
          case "DELETE_CHANNEL_MESSAGE":
          case "DENIED_CREATE_CHANNEL_MESSAGE":
          case "FAILED_CREATE_CHANNEL_MESSAGE":
          case "DENIED_UPDATE_CHANNEL_MESSAGE":
          case "FAILED_UPDATE_CHANNEL_MESSAGE":
          case "PENDING_CREATE_CHANNEL_MESSAGE":
          case "PENDING_UPDATE_CHANNEL_MESSAGE":
            // console.log("record?.ChannelArn:", record?.ChannelArn);
            // console.log("currentChaanelArnRef.current:", currentChaanelArnRef.current);
            if (record?.ChannelArn === currentChaanelArnRef.current) {
              handleGetMessages(record);
            } else {
              // console.log("record.Sender.Arn:", record.Sender.Arn);
              // console.log("appInstanceUserArn:", appInstanceUserArn);
              if (record.Sender.Arn === appInstanceUserArn) {
                break;
              }
              const newUnreads = [...unreadChannels, record.ChannelArn];
              dispatch(setUnReadchannels(newUnreads));
            }
            break;
          default:
            console.log(`Unexpected message type! ${messageType}`);
        }
      },
    };

    messagingSession.addObserver(observer);
    await messagingSession.start();
  };

  const handleGetAvatar = async (channels) => {
    let tempObjList = [];
    let myCorp;
    //取得聊天對象的img
    await channels.forEach((channel) => {
      //先取得聊天對象清單(managerId 不等於自己)，再取得其img
      // Kevin 2023-10-3
      //const contactedManager = _.filter(channel.channel.manager, (manager) => manager.managerId !== managerId)[0]; 
      //Storage.get(contactedManager.manager.imgUrl) 
      //  .then((res) => (tempObjList[contactedManager.managerId] = <Avatar alt="Biz Apo" src={res} />))
      //  .catch(() => console.log("Can't get contacted manager's img!"));
      const contactedCorp = _.filter(channel.channel.corp, (corp) => corp.CorpProfile.id !== corpId)[0];
      myCorp = _.filter(channel.channel.corp, (corp) => corp.CorpProfile.id == corpId)[0];
     
      Storage.get(contactedCorp.CorpProfile.imgUrl) 
        .then((res) => (tempObjList[contactedCorp.CorpProfile.id] = <Avatar alt="Biz Apo" src={res} />))
        .catch(() => console.log("Can't get contacted corp's img!"));
    });

    //取得user自己所有manager appInstanceUserArn Kevin 
    let tempArn =myCorp.CorpProfile.manager.map((manager) => {
      return manager.appInstanceUserArn;
    })
    setUserArn(tempArn);

    //取得user自己的img CorpProfile.manager[0].appInstanceUserArn
  
    await Storage.get(myCorp.CorpProfile.imgUrl)
      .then((res) => (tempObjList[myCorp.CorpProfile.id] = <Avatar alt="Biz Apo" src={res} />))
      .catch(() => console.log("Can't get manager's img!"))
      .finally(() => {
        setAvatarList([...tempObjList]);
      });
  };

  const handleGetMessages = async (newMsg) => {
    let tempMsg = [...msgRef.current, newMsg];
    setMessageList(tempMsg);
    // msgRef.current = tempMsg;
    handleSetMsgList(tempMsg);
  };
  //Get messages list by channel
  const getMessages = async () => {
    setIsMessageLoading(true);

    // list msg
    await awsClient
      .send(
        new ListChannelMessagesCommand({
          ChannelArn: currentChaanelArnRef.current,
          ChimeBearer: appInstanceUserArn,
        })
      )
      .then((msg) => {
        const tempMsg = msg.ChannelMessages;
        setMessageList(tempMsg);
        msgRef.current = tempMsg;
      })
      .catch((err) => console.log("Can't get messages!", err))
      .finally(() => setIsMessageLoading(false));
  };
  const handleSendMsg = async (newMsg) => {
    await awsClient
      .send(
        new SendChannelMessageCommand({
          ChannelArn: currentChannel.channel.channelArn,
          ChimeBearer: appInstanceUserArn,
          Content: newMsg,
          Persistence: ChannelMessagePersistenceType.PERSISTENT,
          Type: ChannelMessageType.STANDARD,
        })
      )
      .then((res) => {
        // getMessages();
        // handleGetMessages(newMsg);
      })
      .catch((err) => window.location.reload());
  };

  const handleSetSelectedChannelArn = (channelArn) => {
    currentChaanelArnRef.current = channelArn;
  };
  const handleSetMsgList = (msgList) => {
    msgRef.current = msgList;
  };

  const ChatContextValue = {
    isChannelLoading,
    setIsChannelLoading,
    isMessageLoading,
    setIsMessageLoading,
    channelList,
    setChannelList,
    messageList,
    setMessageList,
    currentChannel,
    setCurrentChannel,
    handleSendMsg,
    avatarList,
    setCurrentManagerId,
    currentManagerId,
    unreadChannels,
    getMessages,
    handleSetSelectedChannelArn,
    currentChaanelArnRef,
    setCurrentCorpId,
    currentCorpId,
    userArn,
  };

  return (
    <ChatContext.Provider value={ChatContextValue}>
      <ChatBox sx={{ mt: 18 }}>
        <ChannelIndex />
        <MessagesIndex />
      </ChatBox>
    </ChatContext.Provider>
  );
}
