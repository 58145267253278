import React, { useEffect } from "react";
import {
  Authenticator,
  CheckboxField,
  Image,
  useAuthenticator,
  useTheme,
  View,
  Heading,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import "./Login.css";
import { useHistory } from "react-router";
import { I18n } from "aws-amplify";
import BizApoLoading from "../../components/BizApoLoading";
//Added by Kevin 2022-3-28
import Typography from "@mui/material/Typography";
//import { Amplify } from "aws-amplify";
//import awsExports from "../../aws-exports";
//Amplify.configure(awsExports);

I18n.setLanguage("ja");

I18n.putVocabulariesForLanguage("ja", {
  "Sign In": "ログイン", // Tab header
  "Sign in": "ログイン", // Button label
  "Sign in to your account": "Welcome Back!",
  Username: "Eメールまたは電話番号を入力", // Username label
  Password: "パスワード （8文字以上の英数字）", // Password label
  "Forgot your password? ": "パスワードをお忘れの方",
  "Email or Phone Number": "Eメールまたは電話番号を入力",
});
I18n.putVocabulariesForLanguage("ja", {
  "Create Account": "新規会員登録", // Tab header
  "Create a new account": "New User", // Header text
  "Confirm Password": "パスワード再入力", // Confirm Password label
  Email: "Eメール",
  "Phone Number": "携帯電話番号",
});

I18n.putVocabulariesForLanguage("ja", {
  "Reset your password": "パスワードの再設定",
  "Enter your username": "Eメールまたは電話番号を入力",
  "Send code": "コード送信",
  "Back to Sign In": "ログインに戻る",
  "Enter your email or phone number": "携帯電話番号",
  "Confirm Sign Up":
    "ご登録のEmail宛に認証コードが届きますのでご入力をお願いします。届かない場合はメールアドレスや受信設定を再度ご確認ください。",
  "Enter your code": "認証コードを入力",
  Confirm: "認証",
  Confirming: "認証",
  "Resend Code": "認証コードを再送",
  "We Emailed You": "認証コードをEmailに送信済",
});

export default function Login() {
  const history = useHistory();
  let goBackflag = true;
  return (
    <Authenticator
      // Default to Sign Up screen
      initialState="signIn"
      // Customize `Authenticator.SignUp.FormFields`
      components={{
        Header() {
          const { tokens } = useTheme();

          return (
            <View textAlign="center" padding={tokens.space.large}>
              <a href="https://biz-apo.com">
                <Image alt="Amplify logo" src="/assets/img/common/logo.png" />
              </a>
            </View>
          );
        },
        SignUp: {
          FormFields() {
            const { validationErrors } = useAuthenticator();

            return (
              <>
                {/* Re-use default `Authenticator.SignUp.FormFields` */}
                <Authenticator.SignUp.FormFields />

                {/* Append & require Terms & Conditions field to sign up  */}
                <CheckboxField
                  hasError={!!validationErrors.acknowledgement}
                  name="acknowledgement"
                  value="yes"
                  label={
                    <>
                      <a
                        href="https://www.biz-apo.net/bizapo_terms.html"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        利用規約
                      </a>
                      、
                      <a
                        href="https://www.biz-apo.net/bizapo_privacy_policy.html"
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        個人情報保護方針
                      </a>
                      {"に同意の上、お進み下さい。"}
                    </>
                  }
                />
                <p style={{ color: "red" }}>
                  ※ 2022年9月30日まで完全無料。カード登録は不要。
                </p>
              </>
            );
          },
        },
        ConfirmSignUp: {
          Header() {
            return (
              <>
                <Heading level={4}>認証コードをEmailに送信済</Heading>
                <Typography>
                  <br />
                  認証コードが数分以内に届かない場合、Emailアドレスをご確認の上、再登録をご検討下さい。
                </Typography>
              </>
            );
          },
        },
      }}
      services={{
        async validateCustomSignUp(formData) {
          if (!formData.acknowledgement) {
            return {
              acknowledgement: "You must agree to the Terms & Conditions",
            };
          }
        },
      }}
    >
      {({ signOut, user }) => {
        if (user && goBackflag) {
          // Handle login request from landing page. K.C.
          const queryString = window.location.search;
          // console.log(queryString);
          const urlParams = new URLSearchParams(queryString);
          if (user && goBackflag) {
            goBackflag = false;
            if(urlParams.has('bz') ){
              history.push("/");
            }  
            else {
              history.goBack();
            }
          }
        }
        return (
          <main>
            <BizApoLoading />
          </main>
        );
      }}
    </Authenticator>
  );
}
