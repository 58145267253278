import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { scrollModePlugin } from "@react-pdf-viewer/scroll-mode";
export default function PDFviewerDialog({ open, setOpen, pdfPath }) {
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;
  const zoomPluginInstance = zoomPlugin();
  const { ZoomInButton, ZoomOutButton, zoomTo } = zoomPluginInstance;
  const [isLoading, setIsLoading] = useState(true);
  const scrollModePluginInstance = scrollModePlugin({
    scrollMode: "Wrapped",
  });
  const [pdfUrlAvailable, setpdfUrlAvaiable] = useState(true);

  useEffect(() => {
    if (!pdfPath) return;
    var http = new XMLHttpRequest();
    http.open("GET", pdfPath, false);
    http.send();

    if (http.status === 404) setpdfUrlAvaiable(false);
    else setpdfUrlAvaiable(true);
  }, [pdfPath]);

  return (
    <Dialog maxWidth="xl" open={open} onClose={() => setOpen(false)} fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/*<Typography variant="h4">企業紹介</Typography>*/}

        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ height: "80vh", overflow: "hidden" }}>
        {pdfUrlAvailable ? (
          <embed
            src={pdfPath}
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
            onLoad={(e) => console.log("onLoad:", e)}
            onChange={(e) => console.log("onChange:", e)}
            onCompositionEnd={(e) => console.log("onCompositionEnd:", e)}
            onInvalid={(e) => console.log("onLoad:", e.target)}
            onLoadedMetadata={(e) => console.log("onLoadedMetadata:", e)}
          ></embed>
        ) : (
          <span>ファイルが存在しません!</span>
        )}

        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.11.338/build/pdf.worker.min.js">
          <div
            style={{
              // border: "1px solid rgba(0, 0, 0, 0.3)",
              height: 600,
              //   width: "100%",
              maxWidth: 1280,
              margin: "20px auto",
            }}
          >
            <Viewer
              plugins={[
                scrollModePluginInstance,
                zoomPluginInstance,
                thumbnailPluginInstance,
              ]}
              fileUrl={pdfPath}
            />
          </div>
        </Worker> */}
      </DialogContent>
    </Dialog>
  );
}
