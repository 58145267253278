import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAmplifyUserInfo, fetchUserData, userInfo } from "../redux/slice/userInfo.slice";

export const useGetUserInfo = () => {
  const {
    username,
    userToken,
    expTimestamp,
    managerId,
    corpId,
    role,
    isLoadingUserInfo,
    apoId,
    guideCompletedFlag,
    profileCompletedFlag, //判斷 会社情報 是否已完成填寫
    serviceCompletedFlag, //判斷 事業內容 是否已完成填寫
    accountCompletedFlag, //判斷 ユーザー情報 是否已完成填寫
    appInstanceUserArn, //from AWS Chime
    managerImgUrl,
  } = useSelector(userInfo);
  const dispatch = useDispatch();

  useEffect(() => {
    const currentTime = Date.now();
    console.log("☆");

    if (userToken === "" || currentTime >= expTimestamp * 1000) {
      console.log("☆☆");
      dispatch(fetchAmplifyUserInfo());
    } else if (managerId === "" || corpId === "") {
      console.log("☆☆☆");

      dispatch(
        fetchUserData({
          username: username,
          userToken: userToken,
        })
      );
    }
  }, [expTimestamp]);

  useEffect(() => {
    if (managerId !== "") {
      console.log("☆☆☆☆ ");
      // localStorage.setItem("userToken", userToken.toString());
      // localStorage.setItem("managerId", managerId);
    }
  }, [managerId]);

  return {
    username,
    userToken,
    managerId,
    corpId,
    isLoadingUserInfo,
    role,
    apoId,
    guideCompletedFlag,
    profileCompletedFlag,
    serviceCompletedFlag,
    accountCompletedFlag,
    appInstanceUserArn,
    managerImgUrl,
  };
};
