import {
  Typography,
  ListItemText,
  List,
  ListItem,
  styled,
  Divider,
  TextField,
  Button,
  Box,
  MenuItem
} from "@mui/material";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Paper,
  InputBase,
  IconButton,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";

import { differenceInMinutes, format } from "date-fns";
import React, { Fragment } from "react";
import { BizApoColors } from "../../../assets/Styles/BizApoColors";
import BizKnightDatetimeSelecting, {
  getScheduleList,
  initScheduleDatetimeList2,
  periodObjs,
} from "../../../components/biz-apo-datetime-selecting/BizKnightDatetimeSelecting";
import { BizApoPaper3 } from "../../../components/BizApoPaper3";

const CustomizedPaper = styled(BizApoPaper3)(({ theme }) => ({
  width: 770,
  [theme.breakpoints.down("md")]: {
    width: "auto",
  },
}));

export default function ContactInfo({
  contactReason,
  setContactReason,
  scheduleList,
  setScheduleList,
  companyProfileList,
  setCompanyProfileList,
  contactCorpData,
  isSelectedApoSchedule,
  setIsSelectedApoSchedule,
  contactMethod,
  setContactMethod,
  contactDetail,
  setContactDetail,
  receiverManager,
  setReceiverManager,

}) {
  return (
    <CustomizedPaper>
      <Typography variant="h3">コンタクト</Typography>
      <ListItem>
        <TextField
          label="コンタクトの目的"
          fullWidth
          value={contactReason}
          onChange={(e) => setContactReason(e.target.value)}
        />
      </ListItem>
      <List sx={{ padding: 0 }}>
        {companyProfileList.map((item) => (
          <ListItem key={item.id} sx={{ padding: 0 }}>
            <ListItemText
              primary={item.title}
              secondary={item.value}
              primaryTypographyProps={{
                fontSize: 16,
                lineHeight: "24px",
                color: "rgba(51, 51, 51, 0.5)",
              }}
              secondaryTypographyProps={{
                fontSize: 16,
                fontWeight: "600",
                lineHeight: "24px",
                color: "#333333",
              }}
            />
          </ListItem>
        ))}
      </List>
      <Divider
        variant="middle"
        sx={{ mt: 3, mb: 3, background: BizApoColors.secondary }}
      />

      <TextField
        label="面談対象"
        value={receiverManager} 
        onChange={(e) => {
          setReceiverManager(parseInt(e.target.value));
        }}
        select
        size="small"
        sx={{ width: 150,mt: 3, mb: 3 }}
      >
        {contactCorpData.manager.map((item, i) => (
          <MenuItem key={item.id} value={i}>
            {item.name}
          </MenuItem>
        ))}
      </TextField>

      <RenderScheduleSelection
        _contactCorpData={contactCorpData}
        scheduleList={scheduleList}
        setScheduleList={setScheduleList}
        isSelectedApoSchedule={isSelectedApoSchedule}
        setIsSelectedApoSchedule={setIsSelectedApoSchedule}
        contactMethod = {contactMethod}
        setContactMethod = {setContactMethod}
        contactDetail={contactDetail}
        setContactDetail={setContactDetail}
      />
    </CustomizedPaper>
  );
}

/* 如果被連絡的公司 已有指定apo schedule,就列出讓 user選擇
          如果 如果apo schedule的日期沒有符合user的項目， user 也能自行指定五組
      */
const RenderScheduleSelection = ({
  _contactCorpData,
  scheduleList,
  setScheduleList,
  isSelectedApoSchedule,
  setIsSelectedApoSchedule,
  contactMethod,
  setContactMethod,
  contactDetail,
  setContactDetail
}) => (
  <Fragment>
    {/*_contactCorpData.appointment.scheduleStart1 !== null && (
      <Box>
        <Typography variant="h4" sx={{ mb: 3 }}>
          コンタクトを申請する日時を選択してください。
        </Typography>
        <Typography variant="h6">{"面談希望日時"}</Typography>
        {getScheduleList(_contactCorpData.appointment).map(
          (schedule, index) => (
            <Button
              key={index}
              disabled={new Date() >= new Date(schedule.scheduleStart)} //過去的時間的項目不能選
              onClick={() => {
                //只能選一個時段，所以固定更改[0]
                let tempList = initScheduleDatetimeList2;

                if (
                  tempList[0]["startDatetime"] ===
                  new Date(schedule.scheduleStart).toISOString()
                ) {
                  //再次點選 進行取消選取
                  tempList[0]["startDatetime"] = "";
                  tempList[0]["endDatetime"] = "";
                  tempList[0]["enable"] = false;
                  tempList[0]["period"] = "";
                  setIsSelectedApoSchedule(false);
                } else {
                  tempList[0]["startDatetime"] = new Date(
                    schedule.scheduleStart
                  ).toISOString();
                  tempList[0]["endDatetime"] = new Date(
                    schedule.scheduleEnd
                  ).toISOString();
                  tempList[0]["enable"] = true;
                  tempList[0]["period"] =
                    periodObjs[
                      differenceInMinutes(
                        new Date(schedule.scheduleEnd),
                        new Date(schedule.scheduleStart)
                      )
                    ];

                  setIsSelectedApoSchedule(true);
                }

                setScheduleList([...tempList]);
              }}
              variant="green"
              className={
                scheduleList[0].startDatetime === schedule.scheduleStart
                  ? "active"
                  : null
              }
              sx={{ display: "block", margin: "6px 0px" }}
              disable={{}}
            >
              {`${format(
                new Date(schedule.scheduleStart),
                "yyyy/MM/dd HH:mm"
              )}~${format(new Date(schedule.scheduleEnd), "HH:mm")}`}
            </Button>
          )
        )}
        <Divider variant="middle" sx={{ mt: 3, mb: 3 }} />
        <Typography variant="h5" sx={{ mb: 3 }}>
          上記日付以外の日程を申請する場合は、面談希望日時を設定してください。
        </Typography>
      </Box>
    )*/}

    {/* Kevin remark, 不使用：選擇聯絡公司的apo schedule和自己指定時間只能 二選一 */}
    {/* Kevin add Box */}
    {!isSelectedApoSchedule && (
      <Box>
        <FormControl component="fieldset">
        <FormLabel component="legend">面談希望日時</FormLabel>
          <RadioGroup
            row
            value={contactMethod}
            onChange={(e) => {
              setContactMethod(parseInt(e.target.value));
              //變換apo method時、須清空輸入欄位
              //setContactWebMethod(0);
              //setContactDetail("");
            }}
            //disabled={isAskUrl}
          >
            <FormControlLabel //対面
              value={0}
              control={<Radio />}
              label={"面談日時を指定する"}
            />
            <FormControlLabel // WEB
              value={1}
              control={<Radio />}
              label={"他のアポイントツールを指定する"}
            />
          </RadioGroup>
        </FormControl>
        <br />
        {contactMethod === 0 && (
          <BizKnightDatetimeSelecting
            title={" "}
            scheduleList={scheduleList}
            setScheduleList={setScheduleList}
          />
        )}
         {contactMethod === 1 && (
          
          <Paper
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="https://www.biz-apo.com/"
              onChange={(e) => setContactDetail(e.target.value)}
              value={contactDetail}
            />
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              onClick={() => window.open(contactDetail)}
            >
              <LanguageIcon />
            </IconButton>
          </Paper>
        )}
       
      </Box>
    )}
  </Fragment>
);
