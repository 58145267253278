import React, { useState, useEffect } from "react";
import { Grid, TextField, Divider, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, ListSubheader, MenuItem, Button, styled, Typography, FormGroup,Checkbox,Autocomplete,Avatar } from "@mui/material";
import { IndustryAry, IndustryList } from "../../assets/Data/IndustryList";
import useAddress from "../../BizApoHooks/useAddress";
import CompanyTypeSection from "./CompanyTypeSection";
import { COMPANY_CATEGORY_CORPORATION, COMPANY_CATEGORY_NON_PROFIT, COMPANY_CATEGORY_SOLO_PROPRIETORSHIP } from "../../assets/constant";
import axios from "axios";
import _ from "lodash";
import CountrySelect from "../BizApoCountrySelect";
import SaveIcon from "@mui/icons-material/Save";
import SavedSnackbar from "../BizApoSnackbar/SavedSnackbar";
import { BizApoPaper3 } from "../BizApoPaper3";
import BizApoLoading from "../BizApoLoading";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import BizApoDialogRequiredFields from "../BizApoDialog/BizApoDialogRequiredFields";
import { BizApoColors } from "../../assets/Styles/BizApoColors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FirstTimeSaveConfirmDialog from "../../views/CompanyProfile/FirstTimeSaveConfirmDialog";
import { CorpGetAPI, CorpPutAPI } from "../../http/api";
//Kevin
import { Box,CircularProgress} from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import Dropzone from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import BizApoCheckboxDialog from "../../components/BizApoCheckboxDialog";
import { Datepicker, localeJa, setOptions } from "@mobiscroll/react";
import { useDropzone } from "react-dropzone";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { makeStyles } from "@mui/styles";
import { Storage } from "aws-amplify";
import PDFviewerDialog from "../../views/CompanyProfile/PDFviewerDialog";

setOptions({
  locale: localeJa,
  theme: "ios",
});


const HeaderBar = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  marginBottom: 16,
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
  },
}));

const PurposeList = [
  { id: 1, title: "シード", checked: false },
  { id: 2, title: "アーリー", checked: false },
  { id: 3, title: "ミドル", checked: false },
  { id: 4, title: "レイター", checked: false },
];

const AmountList = [
  { id: 1, title: "1億円未満", checked: false },
  { id: 2, title: "1〜3億円未満", checked: false },
  { id: 3, title: "3〜5億円未満", checked: false },
  { id: 4, title: "5〜10億円未満", checked: false },
  { id: 5, title: "10億以上", checked: false },
];

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1080,
    padding: "30px 24px",
    overflow: "hidden",
  },
  userAvatar: {
    margin: "0px auto",
    width: theme.spacing(8),
    height: theme.spacing(8),
    cursor: "pointer",
  },
  attachedImgWrap: {
    width: 220,
    height: 120,
    border: "solid 1px rgba(51, 51, 51, 0.12)",
    boxShadow:
      "0 1px 8px 0 rgba(30, 120, 230, 0.08), 0 1px 4px 0 rgba(51, 51, 51, 0.12)",
    position: "relative",
    cursor: "pointer",
    "& > img": {
      width: "100%",
      height: "100%",
      ObjectFit: "fill",
    },
    "& .MuiButtonBase-root": {
      position: "absolute",
      top: -9,
      right: -9,
    },
  },
  addPttBox: {
    width: 220,
    height: 120,
    border: "solid 1px rgba(51, 51, 51, 0.12)",
    boxShadow:
      "0 1px 8px 0 rgba(30, 120, 230, 0.08), 0 1px 4px 0 rgba(51, 51, 51, 0.12)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  },
  removeBtn: {
    position: "absolute",
    top: -12,
    right: -12,
  },
  attachedDialog: {
    width: 800,
    height: 600,
  },
  dialogImg: {
    width: 470,
    height: 300,
    objectFit: "cover",
  },
}));

export default function CompanyProfileForm({ formTitle, subTitle = false, readOnly = false }) {
  // * readOnly 說明：
  // 此頁是 「自社profile - 會社情報」、「apo 申請 - step1」、「聯絡 公開企業 (コンタクト) - step2」共用；
  // 1.管理者 才有權編輯，因此 如果 一般權限的使用者 在 「apo 申請 - step1」時 要設為只能讀取
  // 2.「聯絡 公開企業 (コンタクト) - step2」 コンタクトの目的　欄位，
  //  中 有個 管理者 才有權編輯，因此 如果 一般權限的使用者 在 「apo 申請 - step1」時 要設為只能讀取

  const [selectedIndustry, setSelectedIndustry] = useState({});
  const organizationNameTitle = {};
  organizationNameTitle[COMPANY_CATEGORY_CORPORATION] = "会社名";
  organizationNameTitle[COMPANY_CATEGORY_SOLO_PROPRIETORSHIP] =  "会社名";//"屋号または事業主名"; Kevin 
  organizationNameTitle[COMPANY_CATEGORY_NON_PROFIT] =  "会社名";//"法人・団体名"; Kevin
  const [isLoading, setIsLoading] = useState(true);
  const [companyName, setCompanyName] = useState(""); //会社名
  const [nation, setNation] = useState("");
  const [stock, setStock] = useState(""); //上場
  const [sales, setSales] = useState(""); //売上高
  const [capital, setCapital] = useState(""); //資本金
  const [employee, setEmployee] = useState(""); //從業員數
  const [establish, setEstablish] = useState(""); //設立年
  const [industryId, setIndustryId] = useState(0); //業種分類
  const [ceo, setCeo] = useState(""); //代表者
  const [postCode, setPostCode] = useState(""); //郵便番号
  const [address, setAddress] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [telephone, setTelephone] = useState("");
  const [hp, setHp] = useState("");
  const [domain, setDomain] = useState("");
  const [prefixAddress, errorMessage] = useAddress(postCode);
  const [openSavedSnackbar, setOpenSavedSnackbar] = useState(false);
  const { corpId, userToken, username } = useGetUserInfo();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const history = useHistory();
  const [openFirstTimeConfirm, setOpenFirstTimeConfirm] = useState(false);
  // Kevin add
  const [imgUrl, setImgUrl] = useState("");
  const [imgPath, setImgPath] = useState("");
  const [companyNameKana, setCompanyNameKana] = useState("カナ"); //会社名カナ
  const [companyNumber, setCompanyNumber] = useState("2394822049765"); //法人番号
  const [businessContent, setBusinessContent] = useState(""); //事業内容
  const [businessSummary, setBusinessSummary] = useState(""); //会社概要
  const [diffPoint1, setDiffPoint1] = useState("");
  const [diffPoint2, setDiffPoint2] = useState("");
  const [diffPoint3, setDiffPoint3] = useState("");
  const [contactOpenFlag, setContactOpenFlag] = useState(true);
  const [pptFile, setPptFile] = useState(null);
  const [isUploadPPT, setIsUploadPPT] = useState(false);
  const [previewPPT, setPreviewPPT] = useState(false);
  const [pptPath, setPptPath] = useState("");
  const [
    openFailedToUploadPptSnackbar,
    setOpenFailedToUploadPptSnackbar,
  ] = useState(false);
   //已選 「業種分類」 清單
   const [selectedIndustryAry, setSelectedIndustryAry] = useState([]);
   const [target, setTarget] = useState([]); //投資対象
   const [purposeList, setPurposeList] = useState(PurposeList);
   const [amount, setAmount] = useState([]); //投資規模
   const [amountList, setAmountList] = useState(AmountList);
   const [industry, setIndustry] = useState([]); //興味のある投資分野
   const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
   const classes = useStyles();

  useEffect(() => {
    if (userToken) fetchCorpData();
  }, [userToken]);

  useEffect(() => {
    //加此判斷可避免進入畫面填入原postcode時地址被更新
    if (!address.includes(prefixAddress)) setAddress(prefixAddress);
  }, [prefixAddress]);


  const handleZipCodeChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setPostCode(e.target.value);
    }
  };

  useEffect(() => {
    // CorpGetAPI 後, target 有變動, 就新 item check status 
     let tempList = purposeList;
    tempList = tempList.map((item) =>
      target?.includes(item.id) ? { ...item, checked: true } : item
    );
    setPurposeList([...tempList]);
  }, [target]);

  useEffect(() => {
    // CorpGetAPI 後, target 有變動, 就新 item check status 
     let tempList = amountList;
    tempList = tempList.map((item) =>
      amount?.includes(item.id) ? { ...item, checked: true } : item
    );
    setAmountList([...tempList]);
  }, [amount]);

  const fetchCorpData = async () => {
    setIsLoading(true);

    await CorpGetAPI(corpId).then((resCorp) => {
      if (resCorp) {
        setTimeout(() => {
          setCompanyName(resCorp.companyName || "");
          setCompanyNameKana(resCorp.companyNameKa || "");
          setCompanyNumber(resCorp.companyNumber || "");
          setNation(resCorp.nation || "");
          setCategoryId(String(resCorp.categoryId) || 0);
          setStock(resCorp.stock || 0);
          setSales(resCorp.sales || 0);
          setCapital(resCorp.capital || 0);
          setEmployee(resCorp.employee || 0);
          setEstablish(new Date(resCorp.establishedAt) || 0);
          setIndustryId(resCorp.industryId || 0);
          setCeo(resCorp.ceo || "");
          setPostCode(resCorp.postCode || "");
          setAddress(resCorp.address || "");
          setTelephone(resCorp.telephone || "");
          setHp(resCorp.hp || "");
          setDomain(resCorp.domain || "");
          // Kevin
          setBusinessContent(resCorp.businessContent || "");
          setDiffPoint1(resCorp.diffPoint1 || "");
          setDiffPoint2(resCorp.diffPoint2 || "");
          setDiffPoint3(resCorp.diffPoint3 || "");
          setContactOpenFlag(resCorp.contactOpenFlag || false);
          setTarget(resCorp.investmentStage || []); //投資対象
          setAmount(resCorp.investmentAmount || []); //投資規模
          setIndustry(resCorp.investmentIndustry || []); //興味のある投資分野
          setBusinessSummary(resCorp.businessSummary || "");
          if (resCorp.imgUrl) {
            setImgUrl(resCorp.imgUrl || "");
            getAvatarPath(resCorp.imgUrl || "");
          }
          setPptFile(resCorp.pdfFile);

         
          setIsLoading(false);
        }, 100);
      }
    });
  };

  const updateCorpData = async () => {
    if (companyName?.length <= 0 || errorMessage) {
      setOpenConfirmDialog(true);
      return;
    }
    setIsLoading(true);

    let data = {
      categoryId: parseInt(categoryId),
      companyName: companyName,
      companyNameKa: companyNameKana,
      companyNumber: companyNumber,
      nation: nation,
      stock: stock,
      sales: sales,
      capital: Number(capital),
      employee: Number(employee),
      establishedAt: establish,
      industryId: industryId,
      ceo: ceo,
      postCode: postCode,
      address: address,
      telephone: telephone,
      hp: hp,
      domain: domain,
      businessContent: businessContent,
      diffPoint1: diffPoint1,
      diffPoint2: diffPoint2,
      diffPoint3: diffPoint3,
      contactOpenFlag: contactOpenFlag,
      investmentStage: target,
      investmentAmount: amount,
      investmentIndustry: industry,
      businessSummary: businessSummary,
      
    };
    await CorpPutAPI({ corpId: corpId, data }).then(() => {
      setOpenSavedSnackbar(true);
      setIsLoading(false);

      // Kevin remark // 倘若 事業內容 尚未填寫 、而且在 自社prfile功能中 則引導至該頁面
      //if (history.location.pathname === "/company_profile/info" && !serviceCompletedFlag) setOpenFirstTimeConfirm(true);
    });
  };

  const updateFileData = async ({
    avatarImgUrl = imgUrl,
    pptFilename = pptFile,
  }) => {
    //  上傳ppt以 「pptFileStatus」 判別上傳狀況，共4種轉換的狀態。
    // 正常的轉換順序為：
    // 0 -> 上傳檔案後，觸發 ppt to pdf 轉換 -> 1 -> 轉換完成 -> 0
    // 超過四頁轉換順序為：
    // 0 -> 上傳檔案後，觸發 ppt to pdf 轉換 -> 1 -> 超過四頁 -> 3
    await axios({
      method: "put",
      url: `/corp/${corpId}`,
      headers: {
        Authorization: userToken,
      },
      data: {
        companyName: companyName,
        imgUrl: avatarImgUrl,
        pdfFile: pptFilename,
      },
    })
      .then((res) => {
        setPptFile(corpId + ".pdf");  // Kevin change from username
      })
      .catch((error) => console.log("Can't update file"))
      .finally(() => setIsUploadPPT(false));
  };

  // Kevin add
  const handleUploadPPT = async (file) => {
    setIsUploadPPT(true);
    // Kevin change from username

    await Storage.put(corpId + ".pdf", file[0],{
      level: 'public',
      contentType: 'application/pdf',
    })
      .then((res) => {
        setTimeout(() => {
          console.log("Storage res:", res);
          //checkUploadPptStatus();
          setIsUploadPPT(false);
          setOpenSavedSnackbar(true);
          updateFileData({ pptFilename: corpId + ".pdf" }); // Kevin change from username
        }, 5000);
      })
      .catch(() => console.log("Can't upload PDF!"));
      
  };

  const handlePreviewPPT = async () => {
    setIsUploadPPT(true);
    await Storage.get(pptFile).then((resPath) => {
      // window.open(resPath, "_blank").focus();
      setTimeout(() => {
        setIsUploadPPT(false);
        setPptPath(resPath);
        setPreviewPPT(true);
      }, 100);
    })
    .catch((e) => console.log("No such file!:", e));
  };

  const handleChange = (changedItemId) => {
    let tempList = purposeList;
    tempList = tempList.map((item) =>
      item.id === changedItemId ? { ...item, checked: !item.checked } : item
    );
    setPurposeList([...tempList]);
    setTarget(tempList.filter((item) => item.checked).map((item) => item.id));
  };

  const handleChangeAmount = (changedItemId) => {
    let tempList = amountList;
    tempList = tempList.map((item) =>
      item.id === changedItemId ? { ...item, checked: !item.checked } : item
    );
    setAmountList([...tempList]);
    setAmount(tempList.filter((item) => item.checked).map((item) => item.id));
  };

  const uploadAvatar = async (file) => {
    const imgName = `${corpId}-avatar.jpg`; // Kevin change from username
    await Storage.put(imgName, file[0]).then((res) => {
      setImgUrl(imgName);
      getAvatarPath(imgName);
      updateFileData({ avatarImgUrl: imgName });
    });
  };
  const getAvatarPath = async (imgName) => {
    if (imgName) {
      await Storage.get(imgName).then((imgPath) => {
        setImgPath(imgPath);
      });
    }
  };

 

  return (
    <div>
      {isLoading ? (
        <BizApoLoading />
      ) : (
        <BizApoPaper3>
          <HeaderBar>
            <Typography variant="h4">{formTitle}</Typography>
            {"　"}
            <Typography variant="h5" sx={{ color: BizApoColors.danger }}>
              ✳︎は必須入力
            </Typography>
          </HeaderBar>
          {subTitle && (
            <Typography variant="h6" sx={{ marginBottom: 2 }}>
              {subTitle}
            </Typography>
          )}

          <Grid container spacing={4} direction="row" justifyContent="center">
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">カテゴリ</FormLabel>
                <RadioGroup name="category" value={categoryId} onChange={(e) => setCategoryId(e.target.value)} row>
                  <FormControlLabel value={COMPANY_CATEGORY_CORPORATION} control={<Radio />} label="企業" disabled={readOnly} /> {/**Kevin 原來是 法人 */}
                  <FormControlLabel value={COMPANY_CATEGORY_SOLO_PROPRIETORSHIP} control={<Radio />} label="投資会社・投資家" disabled={readOnly} /> {/**Kevin 原來是 個人事業主 */}
                  <FormControlLabel value={COMPANY_CATEGORY_NON_PROFIT} control={<Radio />} label="支援企業" disabled={readOnly} /> {/**Kevin 原來是 的法人・非営利法人 */}
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Dropzone
                  accept={[".png", ".jpg", "jpeg"]}
                  maxSize={5000000}
                  onDropAccepted={(acceptedFiles) =>
                    uploadAvatar(acceptedFiles)
                  }
                  onDropRejected={() => setOpenFailedToUploadPptSnackbar(true)}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({ className: "dropzone" })}
                      style={{
                        width: 80,
                        margin: "0px auto",
                      }}
                    >
                      <input {...getInputProps()} />
                      <Avatar
                        alt=""
                        src={imgPath}
                        sx={{ width: 70, height: 70 }}
                      >
                        <AddAPhotoIcon />
                      </Avatar>
                    </div>
                  )}
                </Dropzone>
            </Grid>

            <Grid item xs={12} sm={7}>
              <TextField
                error={companyName.length <= 0}
                required
                label={organizationNameTitle[categoryId]}
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              {/* 企業国籍 */}
              <CountrySelect readOnly={readOnly} nation={nation} setNation={setNation} />
            </Grid>
            <Grid item xs={12} sm={7}>
              {/* 会社名カナ */}
              <TextField
                label="会社名カナ"
                value={companyNameKana}
                onChange={(e) => setCompanyNameKana(e.target.value)}
                variant="outlined"
                size="small"
                fullWidth
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              {/* 法人番号 */}
              <TextField label="法人番号" fullWidth variant="outlined" size="small" value={companyNumber} onChange={(e) => setCompanyNumber(e.target.value)} disabled={readOnly} />
            </Grid>
            <Grid item xs={12}>
              <TextField label="ホームページアドレス" fullWidth variant="outlined" size="small" value={hp} onChange={(e) => setHp(e.target.value)} disabled={readOnly} />
            </Grid>

            {/* 企業の分類 */}
            <CompanyTypeSection
              category={categoryId}
              stock={stock}
              setStock={setStock}
              sales={sales}
              setSales={setSales}
              capital={capital}
              setCapital={setCapital}
              employee={employee}
              setEmployee={setEmployee}
              establish={establish}
              setEstablish={setEstablish}
              readOnly={readOnly}
            />

            <Grid item xs={12} sm={6}>
              {/* 資本金 */}
              <TextField label="資本金" fullWidth variant="outlined" size="small" type = "number" value={capital} onChange={(e) => setCapital(e.target.value)} disabled={readOnly} />
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* 従業員数 */}
              <TextField label="従業員数" fullWidth variant="outlined" size="small" type = "number"  value={employee} onChange={(e) => setEmployee(e.target.value)} disabled={readOnly} />
            </Grid>

            <Grid item xs={12} sm={6}>
              {/* 設立年月日 */}
              {/*<TextField label="設立年月日" fullWidth variant="outlined" size="small" value={establish} onChange={(e) => setEstablish(e.target.value)} disabled={readOnly} />*/}
              <Datepicker
                theme="ios"
                themeVariant="light"
                returnFormat="jsdate"
                label="設立年月日"
                controls={["calendar"]}
                dateFormat="YYYY/MM/DD"
                touchUi={false}
                value={establish}
                onChange={(e) => setEstablish(e.value)}
              />
            </Grid>

          

            {/* Kevin  企業才有 "業種分類" "代表者" */}
            {categoryId === COMPANY_CATEGORY_CORPORATION && (
              <>
                <Grid item xs={12} sm={8}>
                  <TextField select variant="outlined" size="small" label="業種分類" value={industryId} onChange={(e) => setIndustryId(e.target.value)} fullWidth disabled={readOnly}>
                    {Object.entries(_.groupBy(IndustryList, "category")).map((item) =>
                      item[1].map((industry, index) =>
                        index === 0 ? (
                          <ListSubheader>{item[0]}</ListSubheader>
                        ) : (
                          <MenuItem key={industry.id} value={industry.id}>
                            {industry.title}
                          </MenuItem>
                        )
                      )
                    )}
                  </TextField>
                </Grid>
               <Grid item xs={12} sm={4}>
                <TextField label="代表者" variant="outlined" size="small" value={ceo} onChange={(e) => setCeo(e.target.value)} fullWidth disabled={readOnly} />
               </Grid>
              </>
            )}

            {selectedIndustry?.id === 118 && (
              <Grid item xs={12} sm={12}>
                <TextField label="その他" variant="outlined" size="small" inputProps={{ maxLength: 15 }} fullWidth />
              </Grid>
            )}

            <Grid item xs={12} sm={12}>
              <Divider variant="middle" />
            </Grid>

        
            <Grid item xs={12} sm={12}>
              <TextField
                disabled={readOnly}
                label="郵便番号"
                variant="outlined"
                size="small"
                value={postCode}
                onChange={(e) => handleZipCodeChange(e)}
                inputProps={{ maxLength: 7 }}
                style={{ maxWidth: 200 }}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField disabled={readOnly} label="住所" variant="outlined" size="small" value={address} onChange={(e) => setAddress(e.target.value)} fullWidth />
            </Grid>

            <Grid item xs={12}>
              <TextField
                disabled={readOnly}
                label="電話番号"
                value={telephone}
                onChange={(e) => setTelephone(e.target.value)}
                variant="outlined"
                type="tel"
                size="small"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={12}>
                <TextField
                  label="会社概要"
                  multiline
                  rows={2}
                  value={businessSummary}
                  onChange={(e) => setBusinessSummary(e.target.value)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
            </Grid>

            <Grid item xs={12} md={12}>
                <TextField
                  label="事業内容"
                  multiline
                  rows={2}
                  value={businessContent}
                  onChange={(e) => setBusinessContent(e.target.value)}
                  variant="outlined"
                  style={{ width: "100%" }}
                />
            </Grid>

             {/* Kevin  "投資会社・投資家" 才有"興味のある投資分野" */}
             {categoryId === COMPANY_CATEGORY_SOLO_PROPRIETORSHIP && (
              
              <Grid item xs={12} sm={12}>
                {/*<BizApoCheckboxDialog title="興味のある投資分野" dataList={IndustryList} selectedList={selectedIndustryAry} setSelectedList={setSelectedIndustryAry} />*/}
                <Autocomplete
                  multiple
                  options={IndustryList.filter((item) => item.title !== "")}
                  groupBy={(option) => option.category}
                  getOptionLabel={(option) => option.title}
                  onChange={(op, val) => {
                    setIndustry(val.map((item) => item.id));
                  }}
                  value={IndustryList.filter((item) => industry.includes(item.id))}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="興味のある投資分野"
                    />
                  )}
                  sx={{ mt: 3, mb: 3 }}
                />   
              </Grid>
              )}
              {/* Kevin  "支援企業" 才有"サポート分類" */}
             {categoryId === COMPANY_CATEGORY_NON_PROFIT && (
              
              <Grid item xs={12} sm={12}>
                {/*<BizApoCheckboxDialog title="興味のある投資分野" dataList={IndustryList} selectedList={selectedIndustryAry} setSelectedList={setSelectedIndustryAry} />*/}
                <Autocomplete
                  multiple
                  options={IndustryList.filter((item) => item.title !== "")}
                  groupBy={(option) => option.category}
                  getOptionLabel={(option) => option.title}
                  onChange={(op, val) => {
                    setIndustry(val.map((item) => item.id));
                  }}
                  value={IndustryList.filter((item) => industry.includes(item.id))}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      size="small"
                      label="サポート分類"
                    />
                  )}
                  sx={{ mt: 3, mb: 3 }}
                />   
              </Grid>
              )}
              {/* Kevin  "投資会社・投資家" or "支援企業" 才有"投資対象 投資規模" */}
              {(categoryId === COMPANY_CATEGORY_SOLO_PROPRIETORSHIP || categoryId === COMPANY_CATEGORY_NON_PROFIT) && (
                <>
              <Grid item xs={12} sm={12}>
              <Typography
                      variant="body1"
                      sx={{ fontWeight: 400, color: "black" }}
                    >
                      投資対象
                    </Typography>
              {/*<FormGroup  style={{ flexDirection: "row" }}>
                <FormControlLabel control={<Checkbox defaultChecked />} label="シード" />
                <FormControlLabel control={<Checkbox />} label="アーリー" />
                <FormControlLabel control={<Checkbox />} label="ミドル" />
                <FormControlLabel control={<Checkbox />} label="レイター" />
              </FormGroup>*/}
              <FormGroup row>
                {purposeList.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    control={
                      <Checkbox
                        checked={item.checked}
                        onChange={() => handleChange(item.id)}
                      />
                    }
                    label={item.title}
                  />
                ))}
              </FormGroup>

              </Grid>

              <Grid item xs={12} sm={12}>
              <Typography
                      variant="body1"
                      sx={{ fontWeight: 400, color: "black" }}
                    >
                      投資規模
                    </Typography>
              {/*<FormGroup  style={{ flexDirection: "row" }}>
                <FormControlLabel control={<Checkbox defaultChecked />} label="1億円未満" />
                <FormControlLabel control={<Checkbox />} label="1〜3億円未満" />
                <FormControlLabel control={<Checkbox />} label="3〜5億円未満" />
                <FormControlLabel control={<Checkbox />} label="5〜10億円未満" />
                <FormControlLabel control={<Checkbox />} label="10億以上" />
                  </FormGroup>*/}
              <FormGroup row>
                {amountList.map((item) => (
                  <FormControlLabel
                    key={item.id}
                    control={
                      <Checkbox
                        checked={item.checked}
                        onChange={() => handleChangeAmount(item.id)}
                      />
                    }
                    label={item.title}
                  />
                ))}
              </FormGroup>


              </Grid>
             
              </>
            )}

            <Grid item xs={12} md={4}>
              <TextField
                label="差別化ポイント 1"
                multiline
                value={diffPoint1}
                onChange={(e) => setDiffPoint1(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                rows={4}
                inputProps={{ maxLength: 140 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id=""
                label="差別化ポイント 2"
                multiline
                value={diffPoint2}
                onChange={(e) => setDiffPoint2(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
                rows={4}
                inputProps={{ maxLength: 140 }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                id=""
                label="差別化ポイント 3"
                multiline
                rows={4}
                inputProps={{ maxLength: 140 }}
                value={diffPoint3}
                onChange={(e) => setDiffPoint3(e.target.value)}
                variant="outlined"
                style={{ width: "100%" }}
              />
            </Grid>

            <Grid item sm={12} md={4}>
              <Typography variant="body1">企業紹介資料</Typography>
              <Typography variant="body2" style={{ color: "red" }}>
                ※公開してよい資料をアップロードしてください。
              </Typography>

              {isUploadPPT ? (
                <CircularProgress size={28} />
              ) : (
                <Box sx={{ display: "flex" }}>
                  {pptFile && (
                    <Button
                      startIcon={<PlayCircleFilledIcon />}
                      onClick={() => {
                        handlePreviewPPT();
                      }}
                      sx={{ marginRight: 2 }}
                    >
                      プレビュー
                    </Button>
                  )}{" "}
                  <Dropzone
                    accept={[".pdf"]}
                    maxSize={20000000}
                    onDropAccepted={(acceptedFiles) =>
                      handleUploadPPT(acceptedFiles)
                    }
                    onDropRejected={(e) =>
                      setOpenFailedToUploadPptSnackbar(true)
                    }
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()} />

                        <Button startIcon={<CloudUploadIcon />}>
                          Upload
                        </Button>
                      </div>
                    )}
                  </Dropzone>
                </Box>
              )}
            </Grid>

            <Grid item sm={12} md={8}>
                  <FormControl component="fieldset">
                    <Typography
                      variant="body1"
                      sx={{ fontWeight: 400, color: "black" }}
                    >
                      コンタクトの設定
                    </Typography>
                    <RadioGroup
                      row
                      value={contactOpenFlag}
                      onChange={(e) =>
                        setContactOpenFlag(
                          e.target.value === "true" ? true : false
                        )
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label="コンタクトを許可"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label="コンタクトを禁止"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Typography variant="body2" color="red">
                    ※許可すると、全会員に事業内容が公開され、コンタクトを受ける可能性があります。禁止の場合、アポ申請先にのみ開示され、それ以外には非公開となります。
                  </Typography>
                </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                disabled={readOnly}
                color="secondary"
                onClick={() => updateCorpData()}
                sx={{ fontSize: 20, fontWeight: 600, color: "white" }}
                fullWidth
              >
                保 存
              </Button>
            </Grid>
          </Grid>
        </BizApoPaper3>
      )}
      <SavedSnackbar open={openSavedSnackbar} setOpen={setOpenSavedSnackbar} />
      <BizApoDialogRequiredFields open={openConfirmDialog} setOpen={setOpenConfirmDialog} />
      <FirstTimeSaveConfirmDialog open={openFirstTimeConfirm} setOpen={setOpenFirstTimeConfirm} />
      <PDFviewerDialog
        open={previewPPT}
        setOpen={setPreviewPPT}
        pdfPath={pptPath}
      />
    </div>
  );
}
