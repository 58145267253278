import { AppBar, Badge, CircularProgress, Divider, IconButton, styled, Tab, Tabs, Toolbar } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { MainMenuList } from "./MenuList";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { BizApoColors } from "../../../assets/Styles/BizApoColors";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AccountMenu from "./AccountMenu";
import { BizApoMenu, BizApoMenuItem } from "../../../components/BizApoMenu";
import Auth from "@aws-amplify/auth";
import BizApoRequireLoginDailog from "../../../components/BizApoRequireLoginDailog";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import axios from "axios";
import { useDispatch } from "react-redux";
import { removeUserInfo } from "../../../redux/slice/userInfo.slice";
import FirstTimeGuide from "./FirstTimeGuide";
import NotificationFillOut from "./NotificationFillOut";
import { useGetUnreadChannels } from "../../../hooks/useGetUnreadChannels";

const LogoImg = styled("img")(({ theme }) => ({
  width: 142,
  height: "auto",
  transition: "0.3s",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    width: 120,
  },
}));

const ToggleMenuBtn = styled(IconButton)(({ theme }) => ({
  display: "none",
  marginRight: 0,

  [theme.breakpoints.down("1150")]: {
    display: "initial",
  },
}));

const HeaderMenu = styled("div")(({ theme }) => ({
  display: "flex",
}));

const HeaderAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: "white",
  height: 80,
}));

const HeaderToolBar = styled(Toolbar)(({ theme }) => ({
  width: "100%",
  height: "100%",
  maxWidth: 1440,
  justifyContent: "space-between",
  margin: "0 auto",
}));

const HeaderTabs = styled(Tabs)(({ theme }) => ({
  marginRight: 20,
  width: 630,
  display: "flex",
  justifyContent: "space-between",

  "&::after": {
    position: "block",
    content: "''",
    transform: "translateY(14px) ",
    width: 5,
    height: 24,
    backgroundColor: BizApoColors.primary,
  },
  [theme.breakpoints.down("1150")]: {
    display: "none",
  },

  "& .MuiTabs-indicator": {
    backgroundColor: BizApoColors.secondary,
    height: 3,
  },
}));

const HeaderTab = styled(Tab)(({ theme }) => ({
  minWidth: 80,
  color: BizApoColors.primary,
  fontSize: 18,
  fontWeight: 600,
}));

export default function BizApoHeader() {
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const [popMenuAnchor, setPopMenuAnchor] = useState(null);
  const [openRequireLoginDialog, setOpenRequireLoginDialog] = useState(false);
  const [openFirstTimeGuide, setOpenFirstTimeGuide] = useState(false);
  const [openNotificationFillOut, setOpenNotificationFillOut] = useState(false);
  const [fillOutPagePath, setFillOutPagePath] = useState("");
  const { username, userToken, isLoadingUserInfo, guideCompletedFlag, profileCompletedFlag, serviceCompletedFlag, accountCompletedFlag } = useGetUserInfo();
  const [managerData, setManagerData] = useState({});
  const location = useLocation();
  const isApoManagement = useRouteMatch("/apo_management");
  const isApoMessage = useRouteMatch("/apo_message");
  const isCalendar = useRouteMatch("/apo_management/calendar");
  const isCompanyProfile = useRouteMatch("/company_profile");
  const dispatch = useDispatch();
  const unreadChannels = useGetUnreadChannels();
  
  //Kevin 這個useEffect的用途是網頁重新整理時, header menu active under line 才會選到正確位置
  const isApoRelease = useRouteMatch("/apo_release");

  useEffect(() => {
    if (location.pathname === "/") setSelectedTab(0);
    if (isApoManagement) setSelectedTab(2); //Kevin 1 改成 3, header menu 在點選"案件管理"時, active under line 會選到 2
    if (isApoMessage) setSelectedTab(1);
    //if (isCalendar) setSelectedTab(3);
    if (isCompanyProfile) setSelectedTab(3);
    //if (isApoRelease) setSelectedTab(1);
  }, [location]);

  useEffect(() => {
    if (username) {
      fetchManagerData();
      if (guideCompletedFlag !== "") {
        // Kevin 先移除
        //handleFirstTimeGuide();
        //handleCheckCorpInfoGuide();
      }
    }
  }, [username, guideCompletedFlag]);

  const fetchManagerData = async () => {
    await axios({
      method: "get",
      url: `/manager/amplify/${username}`,
      headers: {
        Authorization: userToken,
      },
    })
      .then((res) => {
        setManagerData(res.data);
      })
      .catch(() => console.log("Can't get manager!"));
  };

  const handleToggleMenuClick = (event) => {
    setPopMenuAnchor(event.currentTarget);
  };
  const signOut = async () => {
    setPopMenuAnchor(null);
    localStorage.clear();
    await Auth.signOut();
    dispatch(removeUserInfo());
    history.push("/");
  };

  //首次登入 要顯示提示資訊
  const handleFirstTimeGuide = () => {
    if (!guideCompletedFlag) {
      setOpenFirstTimeGuide(true);
    }
  };
  //第二次登入後，如果尚未填寫完成 「会社情報」、「事業內容」、「ユーザー情報」
  const handleCheckCorpInfoGuide = () => {
    //尚未填寫完成 「会社情報」
    if (!profileCompletedFlag) {
      setOpenNotificationFillOut(true);
      setFillOutPagePath("/company_profile/info");
    } else if (!serviceCompletedFlag) {
      //尚未填寫完成 「事業內容」
      setOpenNotificationFillOut(true);
      setFillOutPagePath("/company_profile/service");
    } else if (!accountCompletedFlag) {
      //尚未填寫完成 「ユーザー情報」
      setOpenNotificationFillOut(true);
      setFillOutPagePath("/company_profile/account");
    }
  };

  return (
    <Fragment>
      <HeaderAppBar position="fixed">
        <HeaderToolBar>
          <LogoImg src="/assets/img/common/logo.png" alt="BizApo" onClick={() => window.open("https://biz-apo.net")} />

          {/* Header Menu for large screen */}
          <HeaderMenu sx={{ display: "flex" }}>
            <HeaderTabs value={selectedTab} onChange={(e, i) => setSelectedTab(i)}>
              {MainMenuList.map(
                (item) =>
                  item.common && (
                    <HeaderTab
                      key={item.id}
                      label={
                        /* item.id === 3 為 メッセージ  */
                        item.id === 3 && unreadChannels.length > 0 ? (
                          <Badge badgeContent={"!"} color="error">
                            {item.text}
                          </Badge>
                        ) : (
                          item.text
                        )
                      }
                      onClick={() => (item.id === 1 || username != null ? history.push(item.url) : setOpenRequireLoginDialog(true))}
                    />
                  )
              )}
            </HeaderTabs>
            {isLoadingUserInfo ? <CircularProgress /> : <AccountMenu signOut={signOut} managerData={managerData} />}

            <ToggleMenuBtn onClick={handleToggleMenuClick}>
              <MenuIcon color="primary" sx={{ fontSize: 38 }} />
            </ToggleMenuBtn>
          </HeaderMenu>
        </HeaderToolBar>
      </HeaderAppBar>

      {/* Header menu for small screen */}
      <BizApoMenu anchorEl={popMenuAnchor} keepMounted open={Boolean(popMenuAnchor)} onClose={() => setPopMenuAnchor(null)}>
        {MainMenuList.map(
          (item) =>
            item.common && (
              <BizApoMenuItem
                key={item.id}
                onClick={() => {
                  history.push(item.url);
                  setPopMenuAnchor(null);
                }}
              >
                {/* item.id === 3 為 メッセージ  */
                item.id === 3 && unreadChannels.length > 0 ? (
                  <Badge badgeContent={"!"} color="error">
                    {item.text}
                  </Badge>
                ) : (
                  item.text
                )}
              </BizApoMenuItem>
            )
        )}
        <Divider variant="middle" style={{ backgroundColor: BizApoColors.secondary }} />
        {userToken ? (
          <BizApoMenuItem>
            <AccountCircleOutlinedIcon /> BizApo株式會社
          </BizApoMenuItem>
        ) : null}

        {userToken ? (
          MainMenuList.map(
            (item, index) =>
              !item.common && (
                <BizApoMenuItem key={index} onClick={() => setPopMenuAnchor(null)}>
                  {item.text}
                </BizApoMenuItem>
              )
          )
        ) : (
          <BizApoMenuItem onClick={() => history.push("/login")}>ログイン</BizApoMenuItem>
        )}
        {userToken && <BizApoMenuItem onClick={signOut}>ログアウト</BizApoMenuItem>}
      </BizApoMenu>

      <BizApoRequireLoginDailog open={openRequireLoginDialog} setOpen={setOpenRequireLoginDialog} />
      <FirstTimeGuide open={openFirstTimeGuide} setOpen={setOpenFirstTimeGuide} />
      <NotificationFillOut open={openNotificationFillOut} setOpen={setOpenNotificationFillOut} pagePath={fillOutPagePath} />
    </Fragment>
  );
}
