import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomePage from "./views/common/HomePage/HomePage";
import IndexLayout from "./views/IndexLayout";
// import Apply from "./views/Apply/Apply";
// import CompanyIndex from "./views/Company/CompanyIndex";
// import CompanyInfo from "./views/Company/CompanyInfo";
import ContactIndex from "./views/Company/Contact/ContactIndex";
// import ApoReleaseIndex from "./views/ApoRelease/ApoReleaseIndex";

import Login from "./views/common/Login";
import Amplify from "aws-amplify";
import config from "./aws-exports";
import { InitAxios } from "./assets/utils/AxiosUtils";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import store from "./redux/store";
import { Provider } from "react-redux";
import { lazy, Suspense } from "react";
import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import { BizApoMuiTheme } from "./assets/Styles/BizApoMuiTheme";
import ApoInfoPage from "./views/ApoManagement/Common/Apply/ApoInfoPage";
import ChatIndex from "./chat/ChatIndex";
import CorpInfo from "./chat/channel/CorpInfo";
import { ApiInjectStore } from "./http";
import AuthCheck from "./views/AuthCheck";

Amplify.configure(config);
InitAxios();
ApiInjectStore(store);

/* For MUI X */
LicenseInfo.setLicenseKey("7e4668ecd997fd8bb72ebe080bdea639T1JERVI6MzQ4NTksRVhQSVJZPTE2NzE5NDUyMDEwMDAsS0VZVkVSU0lPTj0x");

const ViewApply = lazy(() => import("./views/Apply/Apply"));
const ViewApoRelease = lazy(() => import("./views/ApoRelease/ApoReleaseIndex"));
const ViewCompany = lazy(() => import("./views/Company/CompanyIndex"));

const ViewCompanyInfo = lazy(() => import("./views/Company/CompanyInfo"));

const ViewApplyForm = lazy(() => import("./views/Apply/ApplyForm/ApplyFormIndex"));
const ViewApoManagementIndex = lazy(() => import("./views/ApoManagement/ApoManagementIndex"));

const ViewCompanyProfileIndex = lazy(() => import("./views/CompanyProfile/CompanyProfileIndex"));

//Kevin add
const ViewFinance = lazy(() => import("./views/Finance/FinanceIndex"));
const ViewSupport = lazy(() => import("./views/Support/SupportIndex"));

function App() {
  return (
    <ThemeProvider theme={BizApoMuiTheme}>
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path="/login" exact>
              <Login />
            </Route>
            <Route path="/">
              <IndexLayout>
                <Suspense
                  fallback={
                    <Box sx={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <CircularProgress />
                    </Box>
                  }
                >
                  <Switch>
                    <Route path="/" exact>
                      <HomePage />
                    </Route>
                    <Route path="/apply" exact render={() => <ViewApply />}></Route>
                    <Route path="/apo_release" exact render={() => <ViewApoRelease />}></Route>

                    <Route path="/company" exact render={() => <ViewCompany />}></Route>
                    <Route path="/finance" exact render={() => <ViewFinance />}></Route>
                    <Route path="/support" exact render={() => <ViewSupport />}></Route>

                    <AuthCheck>
                      <Route path="/apo/:managerId" component={ApoInfoPage} exact />

                      <Route path="/apply_form" render={() => <ViewApplyForm />}></Route>

                      <Route path="/company/company_info" exact render={() => <ViewCompanyInfo />}></Route>
                      <Route path="/company/contact" component={ContactIndex} exact></Route>

                      <Route path="/company_profile" render={() => <ViewCompanyProfileIndex />}></Route>
                      <Route path="/apo_management" render={() => <ViewApoManagementIndex />}></Route>
                      <Route path="/apo_message" exact>
                        <ChatIndex />
                      </Route>
                      <Route path="/apo_message/corp_info" exact>
                        <CorpInfo />
                      </Route>
                    </AuthCheck>
                  </Switch>
                </Suspense>
              </IndexLayout>
            </Route>
          </Switch>
        </Router>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
