import React from "react";
import * as style from "./CommonFooter.module.scss";

function CommonFooter() {
  return (
    <div className={style.footerWrap} style={{ backgroundColor: "blue" }}>
      <div className={style.footerContent}>
        <div className={style.bizApoInfo}>
          <div className={style.bizApoTitle}>BizApo (株)</div>
          <a href="/">サイトマップ</a>
          <a
            href="https://www.biz-apo.net/bizapo_privacy_policy.html"
            rel="noreferrer"
            target={"_blank"}
          >
            個人情報保護方針
          </a>
          <a
            href="https://www.biz-apo.net/bizapo_terms.html"
            rel="noreferrer"
            target={"_blank"}
          >
            利用規約
          </a>
          <a
            href="https://www.biz-apo.net/bizapo_contact.html"
            rel="noreferrer"
            target={"_blank"}
          >
            お問合せ
          </a>
          <p>©2021 BizApo Service All Rights Reserved.</p>
        </div>
        <div className={style.infoBoxWrap}>
          <div className={style.infoBox}>
            <h2>アポを成立させるには？</h2>
            <a
              href="https://biz-apo.net/userguide_apo_public.html"
              rel="noreferrer"
              target={"_blank"}
            >アポ公開の流れ
            </a>
            <a
              href="https://biz-apo.net/userguide_apo_apply.html"
              rel="noreferrer"
              target={"_blank"}
            >アポ申請の流れ
            </a>
            <a
              href="https://biz-apo.net/userguide_present_corporate.html"
              rel="noreferrer"
              target={"_blank"}
            >プレゼン公開の流れ
            </a>
            <a
              href="https://biz-apo.net/userguide_present_corporate.html#corporate_contact_row"
              rel="noreferrer"
              target={"_blank"}
            >コンタクトの流れ
            </a>
            <a
              href="https://biz-apo.net/bizapo_qa.html"
              rel="noreferrer"
              target={"_blank"}
            >よくある質問
            </a>
          </div>

          <div className={style.infoBox}>
            <h2>ABOUT US</h2>
            <a
              href="https://www.biz-apo.net/about_bizapo.html"
              rel="noreferrer"
              target={"_blank"}
            >
              会社概要
            </a>
            <a
              href="https://www.biz-apo.net/bizapo_contact.html"
              rel="noreferrer"
              target={"_blank"}
            >
              取材・記事掲載のお問合せ
            </a>
            <a
              href="https://www.biz-apo.net/bizapo_contact.html"
              rel="noreferrer"
              target={"_blank"}
            >
              学術/研究機関、公的機関の方へ
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonFooter;
