import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React from "react";

export default function BizApoDialogRequiredFields({ open, setOpen }) {
  return (
    <Dialog open={open} onClick={() => setOpen(false)}>
      <DialogTitle>入力必須項目をご入力ください</DialogTitle>
      <DialogActions>
        <Button color="primary" onClick={() => setOpen(false)}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
