import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import React from "react";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";

export default function FirstTimeGuide({ open, setOpen }) {
  const { userToken, managerId } = useGetUserInfo();
  const handleUpdateFlag = () => {
    axios({
      method: "put",
      url: `/manager/${managerId}`,
      headers: {
        Authorization: userToken,
      },
      data: {
        guideCompletedFlag: true,
      },
    })
      .then((res) => console.log("update flag success!"))
      .catch((err) => console.log("Fail to upadate flag!"));
  };
  const handleColose = () => {
    handleUpdateFlag();
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleColose}>
      <DialogTitle> BizApoのご利用開始ありがとうございます。</DialogTitle>
      <DialogContent>
        まず、会社情報を登録してみましょう。
        <br />
        会社情報を入力の上、保存ボタンをクリックして下さい。
      </DialogContent>
      <DialogActions>
        <Button onClick={handleColose}>OK</Button>
      </DialogActions>
    </Dialog>
  );
}
