import { Button, CircularProgress } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import _ from "lodash";

export default function UnBlockButton({ channelId, blockedCorpId }) {
  const [isLoading, setIsLoading] = useState(false);
  const { userToken, managerId } = useGetUserInfo();
  const [blockList, setBlockList] = useState([]);

  useEffect(() => {
    getBlockList();
  }, []);

  const getBlockList = async () => {
    setIsLoading(true);
    await axios({
      method: "get",
      url: `/manager/${managerId}/blocklists`,
      headers: {
        Authorization: userToken,
      },
      params: { skip: 0, take: 10000 },
    })
      .then((res) => {
        setBlockList(res.data.blocklists);
      })
      .finally(() => setIsLoading(false));
  };

  const handleCancelBlock = async () => {
    setIsLoading(true);

    //   API 「delete /blocklist/{blockID} 」必須帶block清單中的 id,
    // 所以要先取得blocklist 再以 被封鎖的manager之id 取得 block ID
    const blockId = _.filter(blockList, { customerCorpId: blockedCorpId });

    //blockId.length === 0 表示已在案件管理中解除BLOCK而找不到block清單
    if (blockId.length !== 0) {
      await axios({
        method: "delete",
        url: `/blocklist/${blockId[0].id}`,
        headers: {
          Authorization: userToken,
        },
      }).catch(() => console.log("Can't cancel the block."));
    }
    await axios({
      method: "post",
      url: `/channel/${channelId}/unreject`,
      headers: {
        Authorization: userToken,
      },
    }).finally(() => window.location.reload());
  };
  return (
    <Button variant="secondary" size="small" title="ブロック解除" disabled={isLoading} onClick={handleCancelBlock} startIcon={isLoading ? <CircularProgress size={20} /> : null}>
      ブロック解除
    </Button>
  );
}
