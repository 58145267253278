import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

export default function BizApoDialog({
  title = false,
  content = false,
  openDialog = false,
  confirmButton = false,
  homeButton = true,
}) {
  const history = useHistory();
  return (
    <Dialog open={openDialog}>
      {title && <DialogTitle>{title}</DialogTitle>}
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {confirmButton && (
          <Button
            onClick={() => history.push(confirmButton.path)}
            color="primary"
          >
            {confirmButton.text}
          </Button>
        )}

        {homeButton && (
          <Button onClick={() => history.push("/")} color="secondary">
            ホームへ
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
