import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function NotificationFillOut({ open, setOpen, pagePath }) {
  const history = useHistory();
  const handleColose = () => {
    setOpen(false);
    history.push(pagePath);
  };
  return (
    <Dialog open={open} onClose={handleColose}>
      <DialogContent>
        「自社プロフィール」の会社情報・ユーザー情報の必須項目に未入力の欄があります。
        <br />
        該当箇所をご入力の上、 「保存」ボタンをクリックして下さい。
      </DialogContent>
      <DialogActions>
        <Button onClick={handleColose}>確認</Button>
        <Button onClick={() => setOpen(false)}>キャンセル</Button>
      </DialogActions>
    </Dialog>
  );
}
