import axios from "axios";
import { showAlert } from "../redux/slice/alertInfo.slice";

let store;

export const ApiInjectStore = (_store) => {
  store = _store;
};

export const $http = axios.create({
  baseURL: " https://api-bizknight-dev.biz-apo.com",
  timeout: 10000,
});

/* Intercept for request */
$http.interceptors.request.use(
  (config) => {
    config.headers.common["x-api-key"] = "DAhQoaTQ5vahDYfW0bYmv2C3jGhMjomu6zzVq2WD";
    config.headers.Authorization = localStorage.getItem("userToken");
    return config;
  },
  (err) => {
    console.log(err);
  }
);

/* Intercept for response */
$http.interceptors.response.use(
  (res) => {
    console.log("$http.interceptors.response res:", res);
    if (res.status === 200) return res.data;
    else return Promise.reject(res);
  },
  (err) => {
    /* 無網路連線 */
    if (err.toJSON().message === "Network Error") {
      store.dispatch(
        showAlert({
          alertType: "error",
          infoText: (
            <>
              <div>Webに接続できませんでした。</div>
              <div>ネットワーク接続をご確認ください。</div>
            </>
          ),
        })
      );
      return;
    }

    /* 連線超時(timeout) */
    if (err.code === "ECONNABORTED") {
      store.dispatch(
        showAlert({
          alertType: "error",
          infoText: (
            <>
              <div>接続がタイムアウトしました。サーバーが一時的に利用できなくなっています。</div>
              <div>しばらくしてから再度試してください。</div>
            </>
          ),
        })
      );
      return;
    }

    /*目前 contact、 apply apo api 會回傳 errorcod和message  */
    if (err.response.data.message) {
      store.dispatch(showAlert({ alertType: "error", infoText: err.response.data.message }));
      return;
    }

    switch (err.response.status) {
      case 400:
        store.dispatch(showAlert({ alertType: "error", infoText: "400 Bad Request  エラー" }));
        break;
      case 401:
      case 403:
        store.dispatch(showAlert({ alertType: "error", infoText: "403 Forbidden エラー" }));
        break;
      case 404:
        store.dispatch(showAlert({ alertType: "error", infoText: "404 Not Foound エラー" }));
        break;
      case 500:
        store.dispatch(showAlert({ alertType: "error", infoText: "500 Internal Server Error エラー" }));
        break;
      case 502:
        store.dispatch(showAlert({ alertType: "error", infoText: "502 Internal Server Error エラー" }));
        break;
      default:
        store.dispatch(showAlert({ alertType: "error", infoText: "エラーメッセージ Internal error" }));
        break;
    }
  }
);
