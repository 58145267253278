import { configureStore } from "@reduxjs/toolkit";
import AlertInfoReducer from "./slice/alertInfo.slice";
import MessageReducer from "./slice/message.slice";
import UserInfoReducer from "./slice/userInfo.slice";

export default configureStore({
  reducer: {
    userInfo: UserInfoReducer,
    message: MessageReducer,
    alertInfo: AlertInfoReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
