export const BizApoColors = {
  primary: "#1e78e6",
  primary010: "rgba(30, 120, 230, 0.08)",
  secondary: "#ff9e46",
  secondary2: "#ffc846",
  secondary3: "#2a9d8f",
  bg: "#fafafa",
  white: "#fff",
  dark: "#333",
  darkGray: "rgba(51, 51, 51, 0.75)",
  gray: "rgba(51, 51, 51, 0.5)",
  lightGray: "rgba(51, 51, 51, 0.33)",
  extraLightGray: "rgba(51, 51, 51, 0.1)",
  danger: "#e62828",
};
