import React, { useEffect, useState } from "react";
import * as style from "./Appointment.module.scss";
import "./Appointment.css";
import ListLayout from "../../../components/List/ListLayout";
import ApplyInfoDialog from "../../ApoManagement/Common/Apply/ApplyInfoDialog";
import axios from "axios";

function Appointment() {
  const [openApoInfoDialog, setOpenApoInfoDialog] = useState(false);

  // useEffect(() => {
  //   fetchData();
  // }, []);
  const fetchData = async () => {
    await axios({ get: "get", url: "/popular-apos" })
      .then((res) => console.log("popular-apos res:", res.data))
      .catch(() => console.log("Can't get popular apos."));
  };

  const apoList = [
    {
      id: 1,
      date: "2023/05/01",
      title: "株式会社 Clays",
      address: "東京都渋谷区渋谷2-19-15宮益坂ビルディング609",
      department: "一般営業",
    },
    {
      id: 2,
      date: "2023/05/01",
      title: "合同会社アウラント",
      address: "東京都中央区日本橋2-1-17",
      department: "事務全般",
    },
    {
      id: 3,
      date: "2023/05/01",
      title: "株式会社フィールド",
      address: "東京都渋谷区代々木1-25-5 BIZSMART代々木",
      department: "経営企画",
    },
    {
      id: 4,
      date: "2023/05/01",
      title: "住之江工芸株式会社",
      address: "大阪府大阪市中央区南船場1-10-17IKUビル",
      department: "大阪営業部",
    },
    {
      id: 5,
      date: "2023/05/01",
      title: "ＦＶクリエーション株式会社",
      address: "東京都中央区銀座1-22-11銀座大竹ビジデンス2F",
      department: "一般営業",
    },
    {
      id: 6,
      date: "2023/05/01",
      title: "住生活少額短期保険株式会社",
      address: "東京都墨田区両国2丁目10番14号",
      department: "一般営業",
    },
    {
      id: 7,
      date: "2023/05/01",
      title: "株式会社イーモア",
      address: "静岡県浜松市南区三和町360",
      department: "経営企画",
    },
    {
      id: 8,
      date: "2023/05/01",
      title: "楽天損害保険株式会社",
      address: "東京都新宿区新宿6-27-30 新宿イーストサイドスクエア16階",
      department: "ペット保険経営本部",
    },
  ];

  return (
    //☆ className 「apoBoxWrap」 is set for "Appointment.css" (.apoBoxWrap .slick-list{ height:250px})
    //☆ className 「apoBoxWrap」can't delete
    <div
      className="apoBoxWrap"
      style={{
        maxWidth: "calc(100vw - 15vw)",
        marginBottom: "12%",
      }}
    >
      <ListLayout title="推薦企業">
        {apoList.map((apo) => {
          return (
            <div key={apo.id} className={style.apoBox}>
              <p className={style.datetime}>{apo.date}</p>
              <p className={style.title}>{apo.title}</p>
              <p className={style.addressWrap}>
                <span className={style.addressLabel}>住所：</span>
                <span className={style.address}>{apo.address}</span>
              </p>
              <p className={style.departmentWrap}>
                <span className={style.departmentLabel}>部署：</span>
                <span className={style.department}>{apo.department}</span>
              </p>

              <button
                className={style.moreBtn}
                onClick={() => setOpenApoInfoDialog(true)}
              >
                詳細を見る
              </button>
            </div>
          );
        })}
      </ListLayout>
      <ApplyInfoDialog
        oepn={openApoInfoDialog}
        setOpen={setOpenApoInfoDialog}
      />
    </div>
  );
}

export default Appointment;
