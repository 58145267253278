import React, { useEffect, useState } from "react";
import ListLayout from "../../../components/List/ListLayout";
import * as style from "./Companies.module.scss";
import "./Companies.css";
import { useHistory } from "react-router-dom";
import BizApoRequireLoginDailog from "../../../components/BizApoRequireLoginDailog";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import axios from "axios";

function Companies() {
  const history = useHistory();
  const [openRequireLoginDialog, setOpenRequireLoginDialog] = useState(false);
  const { username } = useGetUserInfo();

  // useEffect(() => {
  //   fetchData();
  // }, []);
  const fetchData = async () => {
    await axios({ get: "get", url: "/popular-corps" })
      .then((res) => console.log("/popular-corps res:", res.data))
      .catch(() => console.log("Can't get popular corps."));
  };

  const imgUrl = "assets/img/company-logo/";
  const companyList = [
    {
      id: 1,
      industry: "IT ソフトウェア",
      logo: imgUrl + "1.jpg",
      name: "Techtouch",
    },
    {
      id: 2,
      industry: "IT ソフトウェア",
      logo: imgUrl + "2.jpg",
      name: "oVice",
    },
    {
      id: 3,
      industry: "HR",
      logo: imgUrl + "3.jpg",
      name: "PrAha",
    },
    {
      id: 4,
      industry: "医療",
      logo: imgUrl + "4.jpg",
      name: "B&W",
    },
    {
      id: 5,
      industry: "物流関連",
      logo: imgUrl + "5.jpg",
      name: "207",
    },
    {
      id: 6,
      industry: "不動産",
      logo: "",
      name: "TERASS",
    },
    {
      id: 7,
      industry: "不動産サービス",
      logo: imgUrl + "7.jpg",
      name: "BluAge",
    },
    {
      id: 8,
      industry: "製造",
      logo: imgUrl + "8.jpg",
      name: "Tokyo Factory",
    },
    {
      id: 9,
      industry: "生活関連サービス",
      logo: imgUrl + "9.jpg",
      name: "anyCarry",
    },
    {
      id: 10,
      industry: "IT ソフトウェア",
      logo: imgUrl + "1.jpg",
      name: "Techtouch",
    },
    {
      id: 12,
      industry: "IT ソフトウェア",
      logo: imgUrl + "2.jpg",
      name: "oVice",
    },
    {
      id: 13,
      industry: "HR",
      logo: imgUrl + "3.jpg",
      name: "PrAha",
    },
    {
      id: 14,
      industry: "医療",
      logo: imgUrl + "4.jpg",
      name: "B&W",
    },
    {
      id: 15,
      industry: "物流関連",
      logo: imgUrl + "5.jpg",
      name: "207",
    },
    {
      id: 16,
      industry: "不動産",
      logo: "",
      name: "TERASS",
    },
    {
      id: 17,
      industry: "不動産サービス",
      logo: imgUrl + "7.jpg",
      name: "BluAge",
    },
  ];
  return (
    //☆ className 「companyBoxWrap」 is set for "Recommendation.css" (.recBoxWrap .slick-list { height:auto})
    //☆ className 「companyBoxWrap」can't delete
    <div
      className="companyBoxWrap"
      style={{
        maxWidth: "calc(100vw - 15vw)",
        marginBottom: "10%",
      }}
    >
      <ListLayout
        title="プレゼン公開企業一覧"
        listSetting={{ rows: 2, slidesPerRow: 1 }}
      >
        {companyList.map((company) => {
          return (
            <div
              key={company.id}
              className={style.companyBox}
              onClick={() =>
                username == null
                  ? setOpenRequireLoginDialog(true)
                  : history.push("/company/company_info")
              }
            >
              <span className={style.industry}>{company.industry}</span>
              {company.logo ? (
                <figure>
                  <img src={company.logo} alt={company.name} />
                </figure>
              ) : (
                <p className={style.companyName}>{company.name}</p>
              )}
            </div>
          );
        })}
      </ListLayout>
      <BizApoRequireLoginDailog
        open={openRequireLoginDialog}
        setOpen={setOpenRequireLoginDialog}
      />
    </div>
  );
}

export default Companies;
