// 業種分類
export const IndustryList = [
  {
    category_id: 1,
    category: "製造業・メーカー",
    title: "",
    id: 0,
  },
  {
    category_id: 1,
    category: "製造業・メーカー",
    title: "電気製品/電子部品",
    id: 1,
  },
  {
    category_id: 1,
    category: "製造業・メーカー",
    title: "化学/素材",
    id: 2,
  },
  {
    category_id: 1,
    category: "製造業・メーカー",
    title: "自動車/輸送機器",
    id: 3,
  },
  {
    category_id: 1,
    category: "製造業・メーカー",
    title: "食品",
    id: 4,
  },
  {
    category_id: 1,
    category: "製造業・メーカー",
    title: "医薬品/化粧品",
    id: 5,
  },
  {
    category_id: 1,
    category: "製造業・メーカー",
    title: "医療機器",
    id: 6,
  },
  {
    category_id: 1,
    category: "製造業・メーカー",
    title: "その他",
    id: 7,
  },
  {
    category_id: 2,
    category: "流通・小売・専門店",
    title: "",
    id: 8,
  },
  {
    category_id: 2,
    category: "流通・小売・専門店",
    title: "商社",
    id: 9,
  },
  {
    category_id: 2,
    category: "流通・小売・専門店",
    title: "スーパー/コンビニ",
    id: 10,
  },
  {
    category_id: 2,
    category: "流通・小売・専門店",
    title: "百貨店/複合商業施設",
    id: 11,
  },
  {
    category_id: 2,
    category: "流通・小売・専門店",
    title: "自動車/輸送機器",
    id: 12,
  },
  {
    category_id: 2,
    category: "流通・小売・専門店",
    title: "ファッション",
    id: 13,
  },
  {
    category_id: 2,
    category: "流通・小売・専門店",
    title: "薬局/薬店",
    id: 14,
  },
  {
    category_id: 2,
    category: "流通・小売・専門店",
    title: "家電量販店",
    id: 15,
  },
  {
    category_id: 2,
    category: "流通・小売・専門店",
    title: "その他",
    id: 16,
  },
  {
    category_id: 3,
    category: "サービス",
    title: "",
    id: 17,
  },
  {
    category_id: 3,
    category: "サービス",
    title: "レジャー",
    id: 18,
  },
  {
    category_id: 3,
    category: "サービス",
    title: "スポーツ/運動",
    id: 19,
  },
  {
    category_id: 3,
    category: "サービス",
    title: "美容",
    id: 20,
  },
  {
    category_id: 3,
    category: "サービス",
    title: "外食",
    id: 21,
  },
  {
    category_id: 3,
    category: "サービス",
    title: "教育",
    id: 22,
  },
  {
    category_id: 3,
    category: "サービス",
    title: "人材紹介/派遣",
    id: 23,
  },
  {
    category_id: 3,
    category: "サービス",
    title: "士業/コンサル",
    id: 24,
  },
  {
    category_id: 3,
    category: "サービス",
    title: "その他",
    id: 25,
  },
  {
    category_id: 4,
    category: "IT・ソフトウェア・情報処理",
    title: "",
    id: 26,
  },
  {
    category_id: 4,
    category: "IT・ソフトウェア・情報処理",
    title: "インターネット/WEB/アプリ",
    id: 27,
  },
  {
    category_id: 4,
    category: "IT・ソフトウェア・情報処理",
    title: "ソフトウェア",
    id: 28,
  },
  {
    category_id: 4,
    category: "IT・ソフトウェア・情報処理",
    title: "情報処理(AI/ブロックチェーン等含む)",
    id: 29,
  },
  {
    category_id: 4,
    category: "IT・ソフトウェア・情報処理",
    title: "その他",
    id: 30,
  },
  {
    category_id: 5,
    category: "金融",
    title: "",
    id: 31,
  },
  {
    category_id: 5,
    category: "金融",
    title: "銀行/証券",
    id: 32,
  },
  {
    category_id: 5,
    category: "金融",
    title: "投資銀行/資産運用会社",
    id: 33,
  },
  {
    category_id: 5,
    category: "金融",
    title: "保険",
    id: 34,
  },
  {
    category_id: 5,
    category: "金融",
    title: "その他",
    id: 35,
  },
  {
    category_id: 6,
    category: "情報・通信",
    title: "",
    id: 36,
  },
  {
    category_id: 6,
    category: "情報・通信",
    title: "広告",
    id: 37,
  },
  {
    category_id: 6,
    category: "情報・通信",
    title: "新聞/出版",
    id: 38,
  },
  {
    category_id: 6,
    category: "情報・通信",
    title: "テレビ/放送/ラジオ",
    id: 39,
  },
  {
    category_id: 6,
    category: "情報・通信",
    title: "通信",
    id: 40,
  },
  {
    category_id: 6,
    category: "情報・通信",
    title: "その他",
    id: 41,
  },
  {
    category_id: 7,
    category: "インフラ",
    title: "",
    id: 42,
  },
  {
    category_id: 7,
    category: "インフラ",
    title: "不動産/建設",
    id: 43,
  },
  {
    category_id: 7,
    category: "インフラ",
    title: "運輸/交通",
    id: 44,
  },
  {
    category_id: 7,
    category: "インフラ",
    title: "電力/エネルギー",
    id: 45,
  },
  {
    category_id: 7,
    category: "インフラ",
    title: "医療/福祉機関",
    id: 46,
  },
  {
    category_id: 7,
    category: "インフラ",
    title: "公社/官庁",
    id: 47,
  },
  {
    category_id: 7,
    category: "インフラ",
    title: "農林水産",
    id: 48,
  },
  {
    category_id: 7,
    category: "インフラ",
    title: "教育/研究機関",
    id: 49,
  },
  {
    category_id: 7,
    category: "インフラ",
    title: "その他",
    id: 50,
  },
  {
    category_id: 8,
    category: "その他",
    title: "",
    id: 51,
  },
  {
    category_id: 8,
    category: "その他",
    title: "それ以外の業種",
    id: 52,
  },
];

export const IndustryAry = [
  "",
  "電気製品/電子部品",
  "化学/素材",
  "自動車/輸送機器",
  "食品",
  "医薬品/化粧品",
  "医療機器",
  "その他",
  "",
  "商社",
  "スーパー/コンビニ",
  "百貨店/複合商業施設",
  "自動車/輸送機器",
  "ファッション",
  "薬局/薬店",
  "家電量販店",
  "その他",
  "",
  "レジャー",
  "スポーツ/運動",
  "美容",
  "外食",
  "教育",
  "人材紹介/派遣",
  "士業/コンサル",
  "その他",
  "",
  "インターネット/WEB/アプリ",
  "ソフトウェア",
  "情報処理(AI/ブロックチェーン等含む)",
  "その他",
  "",
  "銀行/証券",
  "投資銀行/資産運用会社",
  "保険",
  "その他",
  "",
  "広告",
  "新聞/出版",
  "テレビ/放送/ラジオ",
  "通信",
  "その他",
  "",
  "不動産/建設",
  "運輸/交通",
  "電力/エネルギー",
  "医療/福祉機関",
  "公社/官庁",
  "農林水産",
  "教育/研究機関",
  "その他",
  "",
  "それ以外の業種",
];
