import { Badge, Chip, IconButton, List, ListItem, ListItemAvatar, ListItemText, SvgIcon } from "@mui/material";
import { styled } from "@mui/styles";
import { useContext } from "react";
import { CircularProgress } from "@mui/material";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import { ReactComponent as scriptSVG } from "../../assets/img/common/icon/script.svg";
import { BizApoColors } from "../../assets/Styles/BizApoColors";
import { useHistory } from "react-router";
import BlockButton from "../../components/common/BlockButton";
import { ChatContext } from "../ChatIndex";
import UnBlockButton from "../../components/common/UnBlockButton";
import { useDispatch } from "react-redux";
import { setUnReadchannels } from "../../redux/slice/message.slice";

const Root = styled("div")(({ theme }) => ({
  minWidth: 330,
  maxWidth: 500,
  maxHeight: 600,
  overflow: "scroll",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
}));

export default function ChannelIndex() {
  const {
    isChannelLoading,
    setCurrentChannel,
    currentChannel,
    channelList,
    avatarList,
    setCurrentManagerId,
    currentManagerId,
    unreadChannels,
    handleSetSelectedChannelArn,
    currentChaanelArnRef,
    setCurrentCorpId,
    currentCorpId,
  } = useContext(ChatContext);
  const { managerId,corpId } = useGetUserInfo();
  const history = useHistory();
  const dispatch = useDispatch();

  const renderActions = (channel) => {
    // const contactedManager = channel.channel.manager[0].managerId !== managerId ? channel.channel.manager[0] : channel.channel.manager[1];
    //user 本人資料
    let myManagerData = {};
    //聯絡對象資料
    
    let contactedManager = {};
    // Kevin
    /*
    if (channel.channel.manager[0].managerId === managerId) {
      myManagerData = channel.channel.manager[0];
      contactedManager = channel.channel.manager[1];
    } else {
      myManagerData = channel.channel.manager[1];
      contactedManager = channel.channel.manager[0];
    }

    //channel.status === 1 => 我方還沒 允許 contact
    if (myManagerData.status === 1) return <Chip label="承認待ち" color="error" />;
    //contactedManager.status === 1 => 對方還沒 允許 contact
    else if (contactedManager.status === 1) return <Chip label="許可待ち" color="error" />;
    else if (contactedManager.status === 3) return <Chip label="不承認" color="error" />;
    else if (myManagerData.status === 3) return <UnBlockButton channelId={channel.channelId} blockedManagerId={contactedManager.managerId} />;
    // 以上皆非時 channel.status === 1 => 雙方已開啟contact
    else if (channel.status === 2) {*/
    if (channel.channel.corp[0].CorpProfile.id === corpId) {
      myManagerData = channel.channel.corp[0];
      contactedManager = channel.channel.corp[1];
    } else {
      myManagerData = channel.channel.corp[1];
      contactedManager = channel.channel.corp[0];
    }

    //channel.status === 1 => 我方還沒 允許 contact
    if (myManagerData.status === 1) return <Chip label="承認待ち" color="error" />;
    //contactedManager.status === 1 => 對方還沒 允許 contact
    else if (contactedManager.status === 1) return <Chip label="許可待ち" color="error" />;
    else if (contactedManager.status === 3) return <Chip label="不承認" color="error" />;
    else if (myManagerData.status === 3) return <UnBlockButton channelId={channel.channelId} blockedCorpId={contactedManager.CorpProfile.id} />;
    // 以上皆非時 channel.status === 1 => 雙方已開啟contact
    else if (channel.status === 2) {
      return (
        <>
          <IconButton
            title="詳細情報"
            onClick={() =>
              history.push({
                pathname: "/apo_message/corp_info",
                state: {
                  managerId: contactedManager.CorpProfile.manager[0].id,
                  corpId: contactedManager.CorpProfile.id,
                },
              })
            }
          >
            <SvgIcon component={scriptSVG} title="詳細情報" sx={{ color: BizApoColors.secondary }}></SvgIcon>
          </IconButton>
          <BlockButton blockManagerId={contactedManager.managerId} channelId={channel.channelId} />
        </>
      );
    }
  };

  return (
    <Root>
      {isChannelLoading ? (
        <CircularProgress sx={{ mt: 10 }} />
      ) : channelList.length > 0 ? (
        <List sx={{ width: "100%" }}>
          {channelList.map((channel, index) => {
            /// Kevin 
            ///const contactedManager = channel.channel.manager[0].managerId !== managerId ? channel.channel.manager[0] : channel.channel.manager[1];
            const contactedManager = channel.managers.filter( (managers) => managers.id !== managerId)[0];
            const contactedCorp = channel.channel.corp.filter( (corps) => corps.CorpProfile.id !== corpId)[0];
            // const selected = contactedManager.managerId === currentManagerId || currentChannel?.channel?.channelArn === channel.channel.channelArn;
            const selected = currentChaanelArnRef.current === channel.channel.channelArn;

            //  contactedManager.managerId === currentManagerId => 為被動選取此聊天channel(其他頁面傳來managerId)
            //  channelArn === currentManagerId => 為主動選取此聊天channel
            if (currentChannel === null && contactedManager.managerId === currentManagerId && currentChannel !== channel) {
              setCurrentChannel(channel);
              handleSetSelectedChannelArn(channel.channel.channelArn);
            } else if (currentManagerId === null && index === 0) {
              // currentManagerId=== null =>在沒有指定 currentManagerId的情況下，
              // 顯示第一筆channel (index === 0)
              // setCurrentChannel(channel);
              // handleSetSelectedChannelArn(channel.channel.channelArn);
              /// Kevin Add  顯示第一筆channel
              setCurrentCorpId(contactedCorp.CorpProfile.id);
              handleSetSelectedChannelArn(channel.channel.channelArn);
              setCurrentChannel(channel);

            }

            return (
              <ListItem key={index} selected={selected} sx={{ borderBottom: "1px solid #ccc", cursor: "pointer" }} /*secondaryAction={renderActions(channel)}*/>
                <ListItemAvatar>
                  <Badge color="primary" variant="dot" invisible={!unreadChannels.includes(channel.channel.channelArn)}>
                    {/*avatarList[contactedManager.managerId] Kevin*/}
                    {avatarList[contactedCorp.CorpProfile.id]}
                  </Badge>
                </ListItemAvatar>
                <ListItemText
                  ///primary={contactedManager.manager.corpProfile.companyName}
                  ///secondary={contactedManager.manager.name}
                  primary={contactedCorp.CorpProfile.companyName}
                  //secondary={contactedManager.name} Kevin do not show manager's name
                  secondary={renderActions(channel)}
                  onClick={() => {
                    //主動選取時 把此設為null
                    // setChannelArn(channel.channel.channelArn);
                    // setChannelStatus(channel.status);
                    setTimeout(() => {
                      ///setCurrentManagerId(contactedManager.managerId);
                      setCurrentManagerId(contactedManager.id);
                      setCurrentCorpId(contactedCorp.CorpProfile.id);// Kevin for reading Avata img
                      dispatch(setUnReadchannels(unreadChannels.filter((c) => c !== channel.channel.channelArn)));

                      /// Kevin 把以下兩個function 對調, 解決channel 要點兩次才能讀message問題
                      handleSetSelectedChannelArn(channel.channel.channelArn);
                      setCurrentChannel({ ...channel });
                      
                    }, 200);
                  }}
                />
              </ListItem>
            );
          })}
        </List>
      ) : (
        <List></List>
      )}
    </Root>
  );
}
