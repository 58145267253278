//為了讓user可以分享apo的QR code、url，因此設此獨立頁面
// 此頁可 於url 中帶入 mangerId 後呈現app的內容（不須登入）
import { isEmpty } from "@aws-amplify/core";
import { Box, Button, Grid, Typography } from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { IndustryAry } from "../../../../assets/Data/IndustryList";
import { OccupationAry } from "../../../../assets/Data/OccupationList";
import FollowButton from "../../../../components/biz-apo-button/FollowButton";
import { getScheduleDatetimeList } from "../../../../components/biz-apo-datetime-selecting/BizApoDatetimeSelecting";
import BizApoInfoCard from "../../../../components/BizApoInfoCard";
import BizApoLoading from "../../../../components/BizApoLoading";
import BizApoRequireLoginDailog from "../../../../components/BizApoRequireLoginDailog";
import { useGetUserInfo } from "../../../../hooks/useGetUserInfo";
import ApoPublishInfo from "../../../ApoRelease/step3/ApoPublishInfo";

export default function ApoInfoPage() {
  const { userToken } = useGetUserInfo();
  const [selectedDateId, setselectedDateId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const history = useHistory();
  const [scheduleDatetimeList, setScheduleDatetimeList] = useState([]);
  const [apoData, setApoData] = useState({});
  const [apoInfo, setApoInfo] = useState([]);
  const [contactInfo, setContactInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openRequireLoginDailog, setOpenRequireLoginDailog] = useState(false);

  let { managerId } = useParams();

  useEffect(() => {
    setIsLoading(true);
    fetchData();
  }, [managerId]);
  const fetchData = () => {
    axios({
      method: "get",
      url: `/manager/${managerId}/public/apo`,
    })
      .then((res) =>
        setTimeout(() => {
          console.log("res:", res.data);
          const tempApoData = res.data;
          setApoData(tempApoData);
          const tempApoInfo = [
            { id: 1, title: "会社名", value: tempApoData.companyName || "" },
            {
              id: 3,
              title: "業種分類",
              value:
                IndustryAry[tempApoData.manager.corpProfile?.industryId || ""],
            },
            {
              id: 4,
              title: "職種分類",
              value: OccupationAry[tempApoData.manager.jobNameId || 0],
            },
          ];
          const tempContactInfo = [
            {
              id: 1,
              title: "ご担当者様",
              value: tempApoData.manager.name || "",
            },
            {
              id: 2,
              title: "部署名",
              value: tempApoData.manager.departmentName || "",
            },
          ];
          setApoInfo([...tempApoInfo]);
          setContactInfo([...tempContactInfo]);
          const tempSchedule = [];
          const scheduleStartList = [
            "scheduleStart1",
            "scheduleStart2",
            "scheduleStart3",
            "scheduleStart4",
            "scheduleStart5",
          ];
          const scheduleEndList = [
            "scheduleEnd1",
            "scheduleEnd2",
            "scheduleEnd3",
            "scheduleEnd4",
            "scheduleEnd5",
          ];
          scheduleStartList.map(
            (item, index) =>
              tempApoData[scheduleStartList[index]] &&
              tempSchedule.push({
                id: index,
                scheduleStart: tempApoData[scheduleStartList[index]],
                scheduleEnd: tempApoData[scheduleEndList[index]],
                text: `${format(
                  new Date(tempApoData[scheduleStartList[index]]),
                  "yyyy/MM/dd HH:mm"
                )} ~ ${format(
                  new Date(tempApoData[scheduleEndList[index]]),
                  "HH:mm"
                )}`,
                expired:
                  new Date() > new Date(tempApoData[scheduleStartList[index]]),
              })
          );
          setScheduleDatetimeList([...tempSchedule]);

          setIsLoading(false);
        }, 100)
      )
      .catch(() => console.log("Can't get Data"));
  };
  const selectDate = (date, id) => {
    setselectedDateId(id);
    setSelectedDate(date);
  };
  const handleApply = () => {
    if (!userToken)
      //尚未登入，先請求登入或註冊
      setOpenRequireLoginDailog(true);
    //
    else if (!selectedDate) alert("Please chose the datetime for apo!");
    else
      history.push("/apply_form", {
        selectedApoData: apoData,
        selectedDate: selectedDate,
      }); //傳送要申請的這筆apo資料
  };

  return isLoading ? (
    <BizApoLoading />
  ) : (
    <Box>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
        alignContent="center"
      >
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ paddingLeft: 6 }}>
            アポ情報
          </Typography>
          <Typography variant="h6" sx={{ paddingLeft: 6 }}>
            {
              " 申請先の要望をご確認の上、を一つ選択し、アポ申請をお願いします。"
            }
          </Typography>
        </Grid>

        <Grid item xs={12} md={6}>
          <BizApoInfoCard
            title={"アポ申請先"}
            infoList={apoInfo}
            // img={avatarUrl}
          />
          <br />

          <BizApoInfoCard title={"担当者"} infoList={contactInfo} />

          <br />
          <BizApoInfoCard title={"日付時間"}>
            {scheduleDatetimeList.map((schedule, index) => (
              <Button
                key={index}
                onClick={() => selectDate(schedule, index)}
                variant="green"
                className={index === selectedDateId ? "active" : null}
                sx={{ display: "block", margin: "6px 0px" }}
                disabled={schedule.expired}
              >
                {schedule.text}
              </Button>
            ))}
          </BizApoInfoCard>
        </Grid>
        <Grid item xs={12} md={6}>
          {/* 因為此ui 與APO公開 共用 ，以傳值的方式呈現內容 */}
          <ApoPublishInfo
            industry={apoData.industry || []}
            jobName={apoData.jobName || []}
            contactMethod={apoData.contactMethod || 0}
            contactDetail={apoData.contactDetail || ""}
            preferStock={apoData.preferStock || []}
            preferSales={apoData.preferSales || []}
            preferCapital={apoData.preferCapital || []}
            preferEmployee={apoData.preferEmployee || []}
            preferEstablish={apoData.preferEstablish || []}
            preferCategory={apoData.preferCategory || []}
            target={apoData.target || []}
            targetOther={apoData.targetOther || ""}
            keyword={apoData.keyword || ""}
            title={"アポ申請先の要望"}
          />
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "center", margin: "16px auto" }}>
        {/* <FollowButton
          managerId={managerId}
          userToken={userToken}
          customerManagerId={apoData.managerId}
          followId={apoData.follow || ""}
        /> */}

        <Button variant="primary" onClick={() => handleApply()}>
          アポ申請
        </Button>
      </Box>
      <BizApoRequireLoginDailog
        open={openRequireLoginDailog}
        setOpen={setOpenRequireLoginDailog}
      />
    </Box>
  );
}
