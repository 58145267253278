import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
import axios from "axios";

export const fetchAmplifyUserInfo = createAsyncThunk("user/info", () => {
  return Auth.currentUserPoolUser((userPool) => userPool).catch((error) => Promise.reject(error));
});
export const fetchUserData = createAsyncThunk("/user/data", (payload) => {
  return axios({
    method: "get",
    url: `/manager/amplify/${payload.username}`,
    headers: {
      Authorization: payload.userToken,
    },
  }).then((res) => res);
});

const initialState = {
  isLoadingUserInfo: false,
  userToken: "",
  username: "",
  expTimestamp: "",
  managerId: "",
  corpId: "",
  apoId: "",
  role: "", //user 角色; 1 => 管理者 ; 2 => 一般
  guideCompletedFlag: "", // 首次登入時為true，彈出引導訊息
  profileCompletedFlag: "", //判斷 会社情報 是否已完成填寫
  serviceCompletedFlag: "", //判斷 会社情報 是否已完成填寫
  accountCompletedFlag: "", //判斷 ユーザー情報 是否已完成填寫
  appInstanceUserArn: "", //from AWS Chime
  managerImgUrl: "",
};
const { actions, reducer: UserInfoReducer } = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    removeUserInfo(state) {
      state.userToken = "";
      state.username = "";
      state.expTimestamp = "";
      state.managerId = "";
      state.corpId = "";
      state.apoId = "";
      state.role = "";
      state.guideCompletedFlag = "";
      state.profileCompletedFlag = "";
      state.serviceCompletedFlag = "";
      state.accountCompletedFlag = "";
      state.appInstanceUserArn = "";
      state.managerImgUrl = "";
    },
    setServiceCompletedFlag(state) {
      state.serviceCompletedFlag = true;
    },
  },

  extraReducers: {
    [fetchAmplifyUserInfo.pedding](state) {
      state.isLoadingUserInfo = true;
    },
    [fetchAmplifyUserInfo.fulfilled](state, action) {
      state.username = action.payload.username;
      state.userToken = action.payload.signInUserSession.idToken.jwtToken;
      state.expTimestamp = action.payload.signInUserSession.idToken.payload.exp;
      state.isLoadingUserInfo = false;
      localStorage.setItem("userToken", action.payload.signInUserSession.idToken.jwtToken);
      localStorage.setItem("username", action.payload.username);
    },
    [fetchAmplifyUserInfo.rejected](state, action) {
      console.log("fetchAmplifyUserInfo rejected...error:", action.error.message);
      state.userToken = "";
      state.username = "";
      state.expTimestamp = "";
      state.managerId = "";
      state.corpId = "";
      state.apoId = "";
    },
    [fetchUserData.pedding](state) {
      state.isLoadingUserInfo = true;
    },
    [fetchUserData.fulfilled](state, action) {
      state.managerId = action.payload.data.id;
      state.corpId = action.payload.data.corpId;
      state.apoId = action.payload.data.appointment[0]?.id || "";
      state.role = action.payload.data.role;
      state.guideCompletedFlag = action.payload.data.guideCompletedFlag;
      state.profileCompletedFlag = action.payload.data.corpProfile.profileCompletedFlag;
      state.serviceCompletedFlag = action.payload.data.serviceCompletedFlag;
      state.accountCompletedFlag = action.payload.data.accountCompletedFlag;
      state.isLoadingUserInfo = false;
      state.appInstanceUserArn = action.payload.data.appInstanceUserArn;
      state.managerImgUrl = action.payload.data.imgUrl;
      localStorage.setItem("managerId", action.payload.data.id);
    },
    [fetchUserData.rejected](state) {},
  },
});

export const { removeUserInfo, setServiceCompletedFlag } = actions;
export const userInfo = (state) => state.userInfo;
export default UserInfoReducer;
