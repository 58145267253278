import { Button, CircularProgress, IconButton } from "@mui/material";
import React, { Fragment, useState } from "react";
import AppBlockingOutlinedIcon from "@mui/icons-material/AppBlockingOutlined";
import { BizApoColors } from "../../assets/Styles/BizApoColors";
import axios from "axios";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import { useHistory } from "react-router";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export default function BlockButton({ blockCorpId, channelId = null, isIconButton = true }) {
  const [isLoading, setIsLoading] = useState(false);
  const { userToken, managerId,corpId } = useGetUserInfo();
  const history = useHistory();
  const [openApplyDialog, setOpenApplyDialog] = useState(false);//Kevin 

  const handleBlock = async () => {
    setIsLoading(true);
    await axios({ method: "POST", url: `/channel/${channelId}/reject`, headers: { Authorization: userToken } }).then((res) => {
      // history.push("/apo_management/blocked_company");
    });
    await axios({ method: "POST", url: "/blocklist", headers: { Authorization: userToken }, data: { corpId: corpId, customerCorpId: blockCorpId } })
      .then((res) => {
        setIsLoading(false);
        history.push("/apo_management/blocked_company");
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Fragment>
      {isIconButton ? (
        <IconButton onClick={() =>setOpenApplyDialog(true)} disabled={isLoading} title="ブロック">
          {isLoading ? <CircularProgress size={20} /> : <AppBlockingOutlinedIcon sx={{ color: BizApoColors.secondary }} />}
        </IconButton>
      ) : (
        <Button variant="secondary" onClick={() =>setOpenApplyDialog(true)} disabled={isLoading} startIcon={isLoading ? <CircularProgress size={20} /> : <AppBlockingOutlinedIcon />}>
          ブロック
        </Button>
      )}

      <Dialog open={openApplyDialog}>
        {/* <DialogTitle> Kevin </DialogTitle> */}
        <DialogContent>
          <DialogContentText>
            {"選択された企業をブロックしますがよろしいですか？"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
              setOpenApplyDialog(false);
              handleBlock();
              //setOpenDialog(true);
            }} color="primary">
            実行
          </Button>
          <Button onClick={() => setOpenApplyDialog(false)} color="info">
            戻る
          </Button>
        </DialogActions>
      </Dialog>

    </Fragment>
  );
}
