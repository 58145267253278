import React, { useEffect, useState } from "react";
import { Grid, Divider, Typography } from "@mui/material";
import { CompanyTypeAry } from "../../../assets/Data/CompanyTypeList";
import { IndustryAry } from "../../../assets/Data/IndustryList";
import axios from "axios";
import BizApoLoading from "../../../components/BizApoLoading";
import { BizApoPaper3 } from "../../../components/BizApoPaper3";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";
import { useHistory, useLocation } from "react-router-dom";

//此頁為 apo 申請-詳細情報、apo公開-Step3、案件管理-詳細情報 共用
//有些頁面 可抓自己的id呈現資料(corpId)，有的頁面是前頁面帶過來的資料(selectedCorpData)
export default function CompanyProfile({
  title = "会社プロフィール",
  selectedCorpData = false,
}) {
  const { userToken, corpId } = useGetUserInfo();
  const [isLoading, setIsLoading] = useState(true);
  const [corpData, setCorpData] = useState([]);
  const history = useHistory();

  useEffect(() => {
    // Kevin 避免logout 會造成Unhandled Rejection (TypeError): Cannot read properties of undefined (reading 'byteLength')
    if (userToken === "") {
      history.push("/login");
    } 
    else
    if (selectedCorpData) {
      //從 案件管理 帶來的資料
      setTimeout(() => {
        setCorpData(selectedCorpData);
        setIsLoading(false);
      }, 100);
    } else if (userToken) fetchCorpsData();
  }, [userToken, selectedCorpData]);
  const fetchCorpsData = async () => {
    setIsLoading(true);
    await axios({
      method: "get",
      url: `/corp/${corpId}`,
      headers: {
        Authorization: userToken,
      },
    })
      .then((resCorp) => {
        setTimeout(() => {
          setCorpData(resCorp.data);
          setIsLoading(false);
        }, 100);
      })
      .catch((error) => console.log("Can't get corp"));
  };
  return (
    <>
      {isLoading ? (
        <BizApoLoading />
      ) : (
        <BizApoPaper3>
          <Typography variant="h3" style={{ marginBottom: 10 }}>
            {title}
          </Typography>
          <Grid
            container
            spacing={1}
            direction="row"
            justify="center"
            alignItems="flex-start"
            alignContent="center"
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">カテゴリ</Typography>
              <Typography variant="h6">
                {CompanyTypeAry[corpData.categoryId]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">企業国籍</Typography>
              <Typography variant="h6">{corpData.nation}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">会社名</Typography>
              <Typography variant="h6">{corpData.companyName}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">会社名カナ</Typography>
              <Typography variant="h6">{corpData.companyNameKa}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">ホームページアドレス</Typography>
              <Typography variant="h6">{corpData.hp}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">法人番号</Typography>
              <Typography variant="h6">{corpData.companyNumber}</Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider variant="middle" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <p style={{ fontSize: 22 }}>企業の分類</p>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">上場</Typography>
              <Typography variant="h6">
                {CompanyTypeAry[corpData.stock]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">売上高</Typography>
              <Typography variant="h6">
                {CompanyTypeAry[corpData.sales]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">資本金</Typography>
              <Typography variant="h6">
                {corpData.capital}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">従業員数</Typography>
              <Typography variant="h6">
                {corpData.employee}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">設立日</Typography>
              <Typography variant="h6">
                {new Date(corpData.establishedAt).toLocaleDateString("ja-JP")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider variant="middle" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">業種分類</Typography>
              <Typography variant="h6">
                {IndustryAry[corpData.industryId]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5">代表者</Typography>
              <Typography variant="h6">{corpData.ceo}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Divider variant="middle" />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">郵便番号</Typography>
              <Typography variant="h6">{corpData.postCode}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Typography variant="h5">住所</Typography>
              <Typography variant="h6">{corpData.address}</Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h5">電話番号</Typography>
              <Typography variant="h6">{corpData.telephone}</Typography>
            </Grid>
          </Grid>
        </BizApoPaper3>
      )}
    </>
  );
}
