import React, { useEffect, useState } from "react";
import { Box, Button, Grid } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import BizApoLoading from "../../components/BizApoLoading";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import axios from "axios";
import CompanyProfile from "../../views/ApoRelease/step3/CompanyProfile";
import ContactInfo from "../../views/ApoManagement/Acceptance/ContactInfo";
import _ from "lodash";

export default function CorpInfo() {
  const history = useHistory();
  const location = useLocation();
  const [corpData, setCorpData] = useState({});
  const [managerData, setManagerData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { userToken } = useGetUserInfo();

  useEffect(() => {
    if (location.state?.corpId) {
      window.scrollTo(0, 0);
      handleGetData();
      console.log("location.state.corpId:", location.state.corpId);
    } else history.goBack();
  }, [location]);

  const handleGetData = async () => {
    await axios({
      method: "GET",
      url: `/corp/${location.state.corpId}`,
      headers: {
        Authorization: userToken,
      },
    })
      .then((res) => {
        setCorpData(res.data);
        const tempManagerData = _.filter(res.data.manager, { id: location.state.managerId })[0];
        setManagerData(tempManagerData);
        setIsLoading(false);
      })
      .catch((err) => console.log("Can't get data!"));
  };

  return (
    <div>
      {isLoading ? (
        <BizApoLoading />
      ) : (
        <Box>
          <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start" alignContent="center">
            <Grid item xs={12} md={7}>
              <CompanyProfile selectedCorpData={corpData} />
            </Grid>
            <Grid item xs={12} md={5}>
              <ContactInfo selectedManagerData={managerData} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button onClick={() => history.goBack()} variant="gray" style={{ margin: "20px auto" }}>
                キャンセル
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
