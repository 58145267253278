import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { OccupationAry } from "../../../assets/Data/OccupationList";
import BizApoList from "../../../components/biz-apo-list/BizApoList";
import { BizApoPaper3 } from "../../../components/BizApoPaper3";

export default function ContactInfo({ selectedManagerData, title = "ユーザー情報" }) {
  const [infoList, setInfoList] = useState([]);
  useEffect(() => {
    let tempList = [
      { title: "氏名", value: selectedManagerData.name || "" },
      { title: "部署", value: selectedManagerData.departmentName || "" },
      {
        title: "職種分類",
        value: OccupationAry[selectedManagerData.jobNameId],
      },
      { title: "役職", value: selectedManagerData.position || "" },
      {
        title: "提案内容評価",
        value: selectedManagerData.corpProfile?.contentRating,
      },
      {
        title: "個人評価",
        value: selectedManagerData.personRating,
      },
    ];
    setInfoList(tempList);
  }, [selectedManagerData]);
  return (
    <BizApoPaper3>
      <Typography variant="h3" style={{ marginBottom: 10 }}>
        {title}
      </Typography>
      <BizApoList dataList={infoList} />
    </BizApoPaper3>
  );
}
