export const ApoPurposeList = [
  { id: 1, title: "情報交換", checked: false },
  { id: 2, title: "販売パートナーの獲得", checked: false },
  { id: 3, title: "自社製品．技術・ライセンスの提供", checked: false },
  { id: 4, title: "有望技術・製品の発掘", checked: false },
  { id: 5, title: "資金調達", checked: false },
  { id: 6, title: "出資・買収 ", checked: false },
  { id: 7, title: "共同開発・業務提携", checked: false },
  { id: 8, title: "外注・業務委託", checked: false },
  { id: 9, title: "その他", checked: false },
];

export const ApoPurposeAry = [
  "",
  "情報交換",
  "販売パートナーの獲得",
  "自社製品．技術・ライセンスの提供",
  "有望技術・製品の発掘",
  "資金調達",
  "出資・買収 ",
  "共同開発・業務提携",
  "外注・業務委託",
  "その他",
];
