import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  unreadChannels: [],
};

const { actions, reducer: MessageReducer } = createSlice({
  name: "message",
  initialState,
  reducers: {
    setUnReadchannels(state, action) {
      state.unreadChannels = action.payload;
    },
  },
});

export const { setUnReadchannels } = actions;
export const messageInfo = (state) => state.message;
export default MessageReducer;
