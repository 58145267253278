import React, { useState } from "react";
import * as style from "./MainNav.module.scss";
import { ReactComponent as calendarSVG } from "../../../assets/img/common/icon/calendar-week.svg";
import { ReactComponent as personaSVG } from "../../../assets/img/common/icon/persona.svg";
import { ReactComponent as scriptSVG } from "../../../assets/img/common/icon/script.svg";
import { ReactComponent as standUpSVG } from "../../../assets/img/common/icon/stand-up.svg";
import { SvgIcon } from "@mui/material";
import { useHistory } from "react-router-dom";
import cx from "classnames";
import BizApoRequireLoginDailog from "../../../components/BizApoRequireLoginDailog";
import { useGetUserInfo } from "../../../hooks/useGetUserInfo";

function MainNav() {
  const history = useHistory();
  const { username } = useGetUserInfo();

  const [openRequireLoginDialog, setOpenRequireLoginDialog] = useState(false);

  return (
    <div className={style.MainNavWrap}>
      <div className={style.navRow}>
        <div
          className={style.navItemWrap}
          style={{ borderBottomRightRadius: 0 }}
          onClick={() =>
            username === ""
              ? setOpenRequireLoginDialog(true)
              : history.push("/company")
          }
        >
          <div className={style.backgroundImg}>
            <SvgIcon
              component={calendarSVG}
              style={{ fontSize: 200, color: "#2a9d8f" }}
            />
          </div>
          <SvgIcon
            component={calendarSVG}
            style={{ fontSize: "4rem", color: "#2a9d8f", margin: "0 auto" }}
          />
          <p className={style.title}>企業を探す</p>
          <p className={style.content}>
            アポイント取得のため、他社に依頼を出す
          </p>
        </div>

        <div
          className={style.navItemWrap}
          style={{ borderBottomLeftRadius: 0 }}
          onClick={() =>
            username === ""
              ? setOpenRequireLoginDialog(true)
              : history.push("/finance")
          }
        >
          <div className={style.backgroundImg}>
            <SvgIcon
              component={personaSVG}
              style={{ fontSize: 200, color: "#ff9e46" }}
            />
          </div>
          <SvgIcon
            component={personaSVG}
            style={{ fontSize: "4rem", color: "#ff9e46", margin: "0 auto" }}
          />
          <p className={style.title}>ファイナンスを探す</p>
          <p className={style.content}>
            自社のアポイントが可能な日時を公開する
          </p>
        </div>

        <div
          className={style.navItemWrap}
          style={{ borderTopRightRadius: 0 }}
          onClick={() =>
            username === ""
              ? setOpenRequireLoginDialog(true)
              : history.push("/support")
          }
        >
          <div className={style.backgroundImg}>
            <SvgIcon
              component={scriptSVG}
              style={{ fontSize: 200, color: "#ff9e46" }}
            />
          </div>
          <SvgIcon
            component={scriptSVG}
            style={{ fontSize: "4rem", color: "#ff9e46", margin: "0 auto" }}
          />
          <p className={cx(style.title, style.company)}>支援企業を探す</p>
          <p className={style.content}>PR情報を公開中の企業を探す</p>
        </div>
        <div className={style.navItemWrap} style={{ borderTopLeftRadius: 0 }}>
          <div className={style.backgroundImg}>
            <SvgIcon
              component={standUpSVG}
              style={{ fontSize: 200, color: "#2a9d8f" }}
            />
          </div>
          <SvgIcon
            component={standUpSVG}
            style={{ fontSize: "4rem", color: "#2a9d8f", margin: "0 auto" }}
          />
          <p className={style.title}>新着情報</p>
          <p className={style.content}>当サイトの新着・更新情報など</p>
        </div>
      </div>
      
      <BizApoRequireLoginDailog
        open={openRequireLoginDialog}
        setOpen={setOpenRequireLoginDialog}
      />
    </div>
  );
}

export default MainNav;
