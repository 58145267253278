import { Snackbar } from "@mui/material";
import React from "react";
import MuiAlert from "@mui/material/Alert";

export default function BizApoSnackbar({ type = "success", text, open, setOpen = () => {} }) {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      severity={type}
    >
      <MuiAlert elevation={6} variant="filled" severity={type} onClose={handleClose}>
        {text}
      </MuiAlert>
    </Snackbar>
  );
}
