import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  IconButton,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useHistory } from "react-router";
import ImgGotoLogin from "../assets/img/img_gotologin.png";

export const CustomizedDialog = styled(Dialog)(({ theme }) => ({
  padding: 0,
  boxShadow: "",
  "& .MuiPaper-root": {
    borderRadius: 16,
  },
}));
export const CustomizedCard = styled(Card)(({ theme }) => ({
  padding: 0,
}));

export const CustomizedCardMedia = styled(CardMedia)(({ theme }) => ({
  height: 260,
  transition: "0.3s",
  [theme.breakpoints.down("xs")]: {
    height: 140,
  },
}));

export default function BizApoRequireLoginDailog({ open, setOpen }) {
  const history = useHistory();
  return (
    <CustomizedDialog onClose={() => setOpen(false)} open={open}>
      <IconButton
        onClick={() => setOpen(false)}
        style={{
          position: "absolute",
          top: 24,
          right: 24,
          backgroundColor: "white",
          width: 30,
          height: 30,
        }}
      >
        <CloseIcon style={{ fontSize: 22 }} />
      </IconButton>
      <CustomizedCard>
        {/* <CustomizedCardMedia image="assets/img/img_gotologin.png" /> */}
        <CardContent sx={{ padding: 0 }}>
          <figure>
            <img
              src={ImgGotoLogin}
              alt="bizapo"
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
          </figure>
        </CardContent>
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            アポイント申請・コンタクトをはじめ、各種機能を利用するには、ログインが必要です。まだ登録がお済みでない場合は、新規会員登録をお願い致します。
          </Typography>
        </CardContent>
        <CardActions style={{ justifyContent: "center", padding: 20 }}>
          <Button
            variant="primary"
            onClick={() => history.push("/login")}
            style={{ width: 160 }}
          >
            新規会員登録
          </Button>

          <Button
            variant="secondary"
            onClick={() => history.push("/login")}
            style={{ width: 160 }}
          >
            ログイン
          </Button>
        </CardActions>
      </CustomizedCard>
    </CustomizedDialog>
  );
}
