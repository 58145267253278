import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

export default function ConfrimToLeaveDialog({ open, setOpen }) {
  const history = useHistory();

  return (
    <Dialog open={open}>
      {/* <DialogTitle>確認</DialogTitle> */}
      <DialogContent>
        <DialogContentText>
          {"キャンセルします。入力したデータは保存されません。"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => history.push("/")} color="primary">
          実行
        </Button>
        <Button onClick={() => setOpen(false)} color="info">
          戻る
        </Button>
      </DialogActions>
    </Dialog>
  );
}
