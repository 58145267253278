import { Button, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import axios from "axios";
export default function FollowButton({
  userToken,
  managerId,
  customerManagerId,
  followId,
}) {
  const [id, setId] = useState(followId);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveFollow = async () => {
    setIsLoading(true);
    await axios({
      method: "delete",
      url: `/followlist/${id}`,
      headers: { Authorization: userToken },
    })
      .then(() => setId(""))
      .finally(() => setIsLoading(false));
  };
  const handleAddFollow = async () => {
    setIsLoading(true);

    await axios({
      method: "post",
      url: "/followlist",
      data: {
        managerId,
        customerManagerId,
      },
      headers: { Authorization: userToken },
    })
      .then((res) => setId(res.data.id))
      .finally(() => setIsLoading(false));
  };
  return (
    <Button
      variant="secondary"
      onClick={id ? handleRemoveFollow : handleAddFollow}
      startIcon={id ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      disabled={isLoading}
      endIcon={isLoading ? <CircularProgress size={16} /> : null}
    >
      お気に入り
    </Button>
  );
}
