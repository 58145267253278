import {
  Typography,
  ListItemText,
  List,
  ListItem,
  Divider,
  TextField,
} from "@mui/material";
import { format } from "date-fns";
import React from "react";
import { BizApoPaper3 } from "../../../components/BizApoPaper3";

export default function ContactInfoConfirm({
  contactReason,
  scheduleList,
  companyProfileList,
}) {
  return (
    <BizApoPaper3>
      <Typography variant="h3">コンタクト</Typography>

      <List sx={{ padding: 0 }}>
        <ListItem>
          <TextField
            label="コンタクトの目的"
            fullWidth
            value={contactReason}
            disabled
          />
        </ListItem>
        {companyProfileList.map((item) => (
          <ListItem key={item.id} sx={{ padding: 0 }}>
            <ListItemText
              primary={item.title}
              secondary={item.value}
              primaryTypographyProps={{
                fontSize: 16,
                lineHeight: "24px",
                color: "rgba(51, 51, 51, 0.5)",
              }}
              secondaryTypographyProps={{
                fontSize: 16,
                fontWeight: "600",
                lineHeight: "24px",
                color: "#333333",
              }}
            />
          </ListItem>
        ))}
      </List>
      <Divider variant="middle" sx={{ margin: 3 }} />

      <Typography variant="h5">日付時間：</Typography>
      <List>
        {scheduleList.map(
          (schedule, index) =>
            schedule.enable && (
              <ListItem key={index}>
                <ListItemText
                  primary={schedule["title"]}
                  secondary={`${format(
                    new Date(schedule["startDatetime"]),
                    "yyyy/MM/dd HH:mm"
                  )} ~ ${format(new Date(schedule["endDatetime"]), "HH:mm")}`}
                  primaryTypographyProps={{
                    fontSize: 16,
                    lineHeight: "24px",
                    color: "rgba(51, 51, 51, 0.5)",
                  }}
                  secondaryTypographyProps={{
                    fontSize: 16,
                    fontWeight: "600",
                    lineHeight: "24px",
                    color: "#333333",
                  }}
                />
              </ListItem>
            )
        )}
      </List>
    </BizApoPaper3>
  );
}
