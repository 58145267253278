import React, { useEffect } from "react";
import ListLayout from "../../../components/List/ListLayout";
import * as style from "./Recommendation.module.scss";
import "./Recommendation.css";
import axios from "axios";

function Recommendation() {
  // useEffect(() => {
  //   fetchData();
  // }, []);
  const fetchData = async () => {
    await axios({ get: "get", url: "/posts" })
      .then((res) => console.log("posts res:", res.data))
      .catch(() => console.log("Can't get posts ."));
  };
  const recList = [
    {
      id: 1,
      img: "assets/img/common/card-img_5.jpg",
      title: "新しいサービスの提供開始",
      content:
        "新しいサービスの提供開始...",
    },
    {
      id: 2,
      img: "assets/img/common/card-img_7.jpg",
      title: "キャンぺーンのお知らせ",
      content:
        "キャンぺーンのお知らせ...",
    },
    {
      id: 3,
      img: "assets/img/common/card-img_10.jpg",
      title: "関連技術のニュース紹介",
      content:
        "関連技術のニュース紹介...",
    },
    {
      id: 4,
      img: "assets/img/common/card-img_12.jpg",
      title: "セミナー開催のお知らせ",
      content:
        "セミナー開催のお知らせ...",
    },
    {
      id: 5,
      img: "assets/img/common/card-img_11.jpg",
      title: "採用情報ページ更新案内",
      content:
        "採用情報ページ更新案内...",
    },
    {
      id: 6,
      img: "assets/img/common/card-img_13.jpg",
      title: "イベントのお知らせ",
      content:
        "イベントのお知らせ...",
    },
    {
      id: 7,
      img: "assets/img/common/card-img_9.jpg",
      title: "業務提携のお知らせ",
      content:
        "業務提携のお知らせ...",
    },
    {
      id: 8,
      img: "assets/img/common/card-img_14.jpg",
      title: "SNS開設のお知らせ",
      content:
        "SNS開設のお知らせ...",
    },
  ];
  return (
    //☆ className 「recBoxWrap」 is set for "Recommendation.css" (.recBoxWrap .slick-list { height:auto})
    //☆ className 「recBoxWrap」can't delete
    <div
      className="recBoxWrap"
      style={{ maxWidth: "calc(100vw - 15vw)", marginBottom: "12%" }}
    >
      <ListLayout title="新着情報">
        {recList.map((rec) => {
          return (
            <div key={rec.id} className={style.recBox}>
              <figure>
                <img src={rec.img} alt="BizApo" />
              </figure>
              <div className={style.contentWrap}>
                <p className={style.title}>{rec.title}</p>

                <p className={style.content}>{rec.content}</p>

                <p className={style.moreBtn}>
                  <span onClick={() => alert("more info...")}>
                    詳細 &#62; &#62;
                  </span>
                </p>
              </div>
            </div>
          );
        })}
      </ListLayout>
    </div>
  );
}

export default Recommendation;
