import React, { useEffect } from "react";
import Appointment from "../Appointment/Appointment";
import Recommendation from "../Recommendation/Recommendation";
import Companies from "../Companies/Companies";
import MainNav from "../MainNav/MainNav";
import SideMenu from "../SideMenu/SideMenu";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { styled } from "@mui/styles";

const Root = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const ContentWrap = styled("div")({
  width: "100%",
  maxWidth: 1200,
  padding: "0 1%",
  overflow: "hidden",
});

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Root>
      <SideMenu />
      <ContentWrap>
        <MainNav />
        <Recommendation />
        <Appointment />
      </ContentWrap>
    </Root>
  );
}

export default HomePage;
