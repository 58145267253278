import { Paper, styled } from "@mui/material";

export const BizApoPaper3 = styled(Paper)({
  maxWidth: 1000,
  width: "100%",
  padding: "3%",
  margin: "0px auto",
  borderRadius: 8,
  boxShadow:
    "0 1px 16px 0 rgba(30, 120, 230, 0.08), 0 2px 4px 0 rgba(51, 51, 51, 0.12)",
});
