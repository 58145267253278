import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
// Added by Kevin
//import Amplify from 'aws-amplify'
//import awsExports from './aws-exports';
//Amplify.configure(awsExports);

ReactDOM.render(<App />, document.getElementById("root"));
