import { Box, IconButton, InputAdornment, styled, TextField, Typography, Button } from "@mui/material";
import { useContext, useEffect, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import _ from "lodash";
import { useGetUserInfo } from "../../hooks/useGetUserInfo";
import { format } from "date-fns";
import CheckIcon from "@mui/icons-material/Check";

import axios from "axios";
import BizApoLoading from "../../components/BizApoLoading";
import { ChatContext } from "../ChatIndex";
import BlockButton from "../../components/common/BlockButton";
const Root = styled("div")(({ theme }) => ({
  width: "100%",
  height: 600,
}));
const MessagesBox = styled("div")({
  padding: 26,
  borderLeft: "1px solid #ccc",
  height: "calc(100% - 69px)",
  overflow: "scroll",
});
const SenderMsg = styled("div")({
  display: "flex",
  alignItems: "flex-end",
  marginBottom: 10,
  "& .MuiAvatar-root": {
    marginRight: 10,
  },
  "& .msgBox": {
    backgroundColor: "#FFEBAF",
    padding: "7px 15px",
    borderRadius: "12px 8px 8px 0",
    display: "inline-block",
    marginRight: 6,
    position: "relative",
    whiteSpace: "pre-line",
  },
  span: {
    fontSize: 11,
    color: "#ccc",
  },
});

const ReceiverMsg = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  marginBottom: 10,

  "& .MuiAvatar-root": {
    marginLeft: 10,
  },
  "& .msgBox": {
    backgroundColor: "#BADFFC",
    padding: "7px 15px",
    borderRadius: "8px 12px 0px 8px",
    display: "inline-block",
    marginLeft: 6,
    position: "relative",
    whiteSpace: "pre-line",
  },
  span: {
    fontSize: 11,
    color: "#ccc",
  },
});
export default function MessagesIndex() {
  const { isMessageLoading, setIsMessageLoading, messageList, handleSendMsg, currentChannel, avatarList, currentManagerId,currentCorpId,userArn } = useContext(ChatContext);
  const msgBoxRef = useRef();
  const newMsgRef = useRef("");
  const { appInstanceUserArn, userToken, managerId,corpId } = useGetUserInfo();

  useEffect(() => {
    //讓聊天頁至於最底處（可看見最新訊息）
    setTimeout(() => {
      msgBoxRef.current?.scrollTo({ top: msgBoxRef?.current.scrollHeight });
    }, 200);
  }, [messageList]);

  const handleAccept = async () => {
    setIsMessageLoading(true);
    await axios({
      method: "POST",
      url: `/channel/${currentChannel.channelId}/accept`,
      headers: {
        Authorization: userToken,
      },
    })
      .then(() => window.location.reload())
      .finally(() => setIsMessageLoading(false));
  };

  return (
    <Root>
      <MessagesBox ref={msgBoxRef}>
        {isMessageLoading ? (
          <BizApoLoading />
        ) : currentChannel?.status === 1 ? ( //currentChannel.status === 1 => 我方接收到 contact 申請 但尚未accept
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", height: 360 }}>
            <Typography variant="h3">メッセージの送信を許可しますか？</Typography>
            <Box sx={{ paddingTop: 2 }}>
              <Button variant="primary" startIcon={<CheckIcon />} onClick={handleAccept}>
                承　認
              </Button>
              {"　"}

              {/*<BlockButton blockManagerId={currentManagerId} channelId={currentChannel.channelId} isIconButton={false} />*/}
              <BlockButton blockCorpId={currentCorpId} channelId={currentChannel.channelId} isIconButton={false} />
            </Box>
          </Box>
        ) : messageList.length > 0 ? (
          _.orderBy(messageList, ["CreatedTimestamp"], ["asc"]).map((msg, index) =>
            // Kevin msg.Sender.Arn === appInstanceUserArn ? (
            userArn.includes(msg.Sender.Arn) ? (
              //使用者自己公司所有的manager
              <ReceiverMsg key={index}>
                <span>{msg.Sender.Name}<br/>{`${format(new Date(msg.CreatedTimestamp), "yyyy/MM/dd HH:mm")}`}</span>
                <div className="msgBox">{msg.Content}</div>
                {avatarList[corpId]} 
              </ReceiverMsg>
            ) : (
              //目前聊天對象
              <SenderMsg key={index}>
                {avatarList[currentCorpId]}
                <div className="msgBox">{msg.Content}</div>
                <span>{msg.Sender.Name}<br/>{`${format(new Date(msg.CreatedTimestamp), "yyyy/MM/dd HH:mm")}`}</span>
              </SenderMsg>
            )
          )
        ) : (
          //尚無訊息
          <Box></Box>
        )}
      </MessagesBox>

      <TextField
        disabled={
          // Kevin
          //typeof currentChannel?.channel?.channelArn === "undefined" || currentChannel?.channel?.manager[0].status !== 2 || currentChannel?.channel?.manager[1].status !== 2
          typeof currentChannel?.channel?.channelArn === "undefined" || currentChannel?.status !== 2
        }
        placeholder="Sending message"
        fullWidth
        size="small"
        multiline
        rows={2}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={
                  // Kevin
                  //typeof currentChannel?.channel?.channelArn === "undefined" || currentChannel?.channel?.manager[0].status !== 2 || currentChannel?.channel?.manager[1].status !== 2
                  typeof currentChannel?.channel?.channelArn === "undefined" || currentChannel?.status !== 2
                }
                onClick={() => {
                  handleSendMsg(newMsgRef.current.value);
                  newMsgRef.current.value = "";
                }}
              >
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
        inputRef={newMsgRef}
      ></TextField>
    </Root>
  );
}
