import React from "react";
import CommonFooter from "./common/Footer/CommonFooter";
import BizApoHeader from "./common/Header/BizApoHeader";
import { styled } from "@mui/styles";
import { Box } from "@material-ui/core";
import BizApoSnackbar from "../components/BizApoSnackbar/BizApoSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { alertInfo, setOpenSnackbar } from "../redux/slice/alertInfo.slice";

const Main = styled("div")({
  margin: "0 auto",
  marginTop: 100,
  width: "100%",
  maxWidth: 1440,
  minHeight: "calc( 100vh - 240px)",
});

function IndexLayout({ children }) {
  const { openSnackbar, infoText, alertType } = useSelector(alertInfo);
  const dispatch = useDispatch();
  return (
    <Box sx={{ width: "100%" }}>
      <BizApoHeader />
      <Main>{children}</Main>
      <CommonFooter />
      <BizApoSnackbar open={openSnackbar} text={infoText} type={alertType} setOpen={(val) => dispatch(setOpenSnackbar(val))} />
    </Box>
  );
}

export default IndexLayout;
